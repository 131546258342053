import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import reportHelper from '../../../../shared/reportHelper';
import staticData from '../../../../shared/staticData';
import Button from '../../../../inputs/Button';

ReportButtons.propTypes = {
    reportType: PropTypes.number.isRequired,
    includeCopy: PropTypes.bool,
    initPrint: PropTypes.bool,
    disableInitPrint: PropTypes.func,
    toast: PropTypes.any
}

ReportButtons.defaultProps = {
    includeCopy: true,
    initPrint: false,
    disableInitPrint: () => true
}

export default function ReportButtons(props) {

  const navigate = useNavigate();
  const params = useParams();
  const { route: reportRoute } = staticData.getReport(props.reportType);

  useEffect(() => {
    if(props.initPrint === true) {
      reportHelper.openPdf(params.id, toast);
      props.disableInitPrint();
    }
  }, [props.initPrint])

  return (
    <div className="flex justify-end mt-2 mb-4">
        {props.includeCopy && (
            <Button text="Copy Report" clickHandler={() => reportHelper.copyReport(params.id, toast, navigate, reportRoute)} classNames="bg-core-orange-300 hover:bg-core-orange-400 mx-1"/>
        )}
        <Button text="View as PDF" clickHandler={() => reportHelper.openPdf(params.id, toast)} className="bg-core-orange-500" />
        <ToastContainer />
    </div>
  )
}
