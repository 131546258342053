import React from 'react';
import PropTypes from 'prop-types';
import utils from '../../../shared/utils';
import staticData from '../../../shared/staticData';

PreviewHeader.propTypes = {
    reportName: PropTypes.string.isRequired,
    clientNames: PropTypes.string.isRequired,
    reportDate: PropTypes.any.isRequired,
    advisorName: PropTypes.string.isRequired,
    reportType: PropTypes.number.isRequired
}
  
PreviewHeader.defaultProps = {

};

export default function PreviewHeader(props) {

    const { imgSrc, title } = staticData.getReport(props.reportType);

    return (
        <div className="px-6 pt-6">
            <div className="flex justify-between">
                <div className="flex">
                    <div className="flex items-center mr-4">
                        <img alt="Report Logo" src={imgSrc} className="w-8"></img>
                    </div>
                    <div className="block">
                        <h3 className="font-bold leading-6">{props.reportName}</h3>
                        <h4 className="mt-2 leading-6">Prepared for: {props.clientNames} on {utils.getFormattedDate(props.reportDate)}</h4>
                    </div>
                </div>
                <div className="block">
                    <h4 className="text-slate-500 text-right leading-8">{title}</h4>
                    <h4 className="leading-6">Prepared by: {props.advisorName}</h4>
                </div>
            </div>
        </div>
    )
}
