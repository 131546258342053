class utils {

    static calculateAge = (birthDate) => {
        var dateOfBirth = new Date(birthDate);
        var currentDate = new Date();
        var yearDifference = currentDate.getFullYear() - dateOfBirth.getFullYear();

        var currentDateInBirthYear = new Date(currentDate.getFullYear() - yearDifference, currentDate.getMonth(), currentDate.getDate());
        if(currentDateInBirthYear >= dateOfBirth) {
            return yearDifference;
        }
        return yearDifference - 1;
    }

    static getFormattedDate = (dateString) => {
        if(!dateString) {
            return "";
        }
        
        var date = new Date(dateString);
        var year = date.getFullYear();
        var month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;
        var day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;
        return month + '/' + day + '/' + year;
    }

    static formatDecimalAsPercentage = (number, decimalPlaces = 2) => {
        return (number * 100).toFixed(decimalPlaces) + "%";
    }

    static formatAsCurrency = (number, numberOfDecimals = 2, excludeDollarSign = false) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: numberOfDecimals,         
            maximumFractionDigits: numberOfDecimals
        });        

        if(excludeDollarSign) {
            return formatter.format(number).replace('$', '');
        }
        return formatter.format(number);
    }

    static isEmpty = (obj) => {
        return Object.keys(obj).length === 0;
    }

    static base64ToArrayBuffer = (base64) => {
        const binaryString = window.atob(base64);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
            const ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    static generateUniqueKey = () => {
        return Math.random().toString(16).slice(2);
    }

    static getWindowDimensions = () => {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    }
    
    static chunkArray = (array, chunkSize) => {
        if(chunkSize < 0) {
            return array;
        }
        let arrayOfChunks = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            const chunk = array.slice(i, i + chunkSize);
            arrayOfChunks.push(chunk);
        }
        return arrayOfChunks;
    }

    static toggleElement = (element, toggle, displayStyle = "block") => {
        if(toggle) {
            element.style.display = displayStyle;
        } else {
            element.style.display = "none";
        }
    }

    static removeUrlParameter = (paramKey) => {
        const url = window.location.href
        var r = new URL(url)
        r.searchParams.delete(paramKey)
        const newUrl = r.href
        console.log("r.href", newUrl)
        window.history.pushState({ path: newUrl }, '', newUrl)
    }

    static timeSince = (date) => {
      if (typeof date !== 'object') {
          date = new Date(date);
        }
      
        const seconds = Math.floor((new Date() - date) / 1000);
        const intervalData = [{
          seconds: 31536000,
          type: 'year'
        },
        {
          seconds: 2592000,
          type: 'month'
        },
        {
          seconds: 86400,
          type: 'day'
        },
        {
          seconds: 3600,
          type: 'hour'
        },
        {
          seconds: 60,
          type: 'minute'
        },
        {
          seconds: 1,
          type: 'second'
        }];

        let intervalType;
        let interval = 0;
        for (let i = 0; i < intervalData.length; i++) {
          interval = Math.floor(seconds / intervalData[i].seconds);
          if(interval >= 1) {
            intervalType = intervalData[i].type;
            break;
          }
        }
        
        if (interval > 1 || interval === 0) {
          intervalType += 's';
        }
        return interval + ' ' + intervalType;
    }

    static onIframeLoad = (event, measureElementId = null, heightFactor = 1) => {
        if (measureElementId) {
            let scrollHeightElement = event.target.contentWindow.document.getElementById(measureElementId);
            let scrollHeight = scrollHeightElement == null || scrollHeightElement.scrollHeight == null
                ? 2000
                : event.target.contentWindow.document.getElementById(measureElementId).scrollHeight;
            event.target.style.height = (heightFactor * scrollHeight) + "px";
            let htmlElement = event.target.contentWindow.document.getElementsByTagName("html")[0];
            htmlElement.style.overflowY = "hidden";
        } else {
            event.target.style.height = (event.target.contentWindow.document.body.scrollHeight + 20) + "px";
        }
        return true;
    }

    static roundUpToNearest = (num, roundAmount) => {
        return Math.ceil(num / roundAmount) * roundAmount;
    }

    static setDecimalPlaces = (e, decimalPlaces = 2) => {

        if (e.nativeEvent.data === null) return; // backspace key is null
        let currentCharacterIsPeriod = e.nativeEvent.data === ".";
        let isZeroHandled = true;
        if (e.target.value.length > 1) {
            let value = e.target.value.slice(-2);
            if (value === ".0") isZeroHandled = false;
        }

        if (!currentCharacterIsPeriod && isZeroHandled) {
            let valueString = e.target.value;
            let hasDecimalSeparator = valueString.indexOf('.') !== -1;
            if (!hasDecimalSeparator) {
                e.target.value = parseFloat(valueString);
                return;
            }
            let valueSplit = valueString.split('.');
            let validDecimalPlaces = [...Array(decimalPlaces).keys()];
            if (validDecimalPlaces.includes(valueSplit[1].length - 1)) {
                e.target.value = parseFloat(valueString);
                return;
            }
            let subString = valueSplit[1].substring(0, decimalPlaces);
            e.target.value = parseFloat(`${valueSplit[0]}.${subString}`);
        }
        }
}

export default utils;