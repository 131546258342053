import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Menu, Transition } from '@headlessui/react'
import { DocumentReportIcon, DocumentDuplicateIcon, PencilAltIcon, TrashIcon } from'@heroicons/react/outline';
import { ChevronDownIcon } from '@heroicons/react/solid'
import reportHelper from '../shared/reportHelper'
import staticData from '../shared/staticData'
import IconWrapper from './IconWrapper';
import utils from '../shared/utils';


ReportActionDropdown.propTypes = {
    report: PropTypes.object.isRequired,
    refetchReports: PropTypes.func.isRequired
}
  
ReportActionDropdown.defaultProps = {

};
  

export default function ReportActionDropdown(props) {

    const navigate = useNavigate();

    const handleReportClick = async (report) => {
        const reportRoute = staticData.getReport(report.reportType).route;
        const reportId = report.id;
        const action = report.isDraft ? "edit" : "report";
        const link = `/v1/reports/${reportRoute}/${action}/${reportId}`;
        navigate(link)
    }

    const handleReportCopyClick = async (report) => {
        const reportRoute = staticData.getReport(report.reportType).route;
        await reportHelper.copyReport(report.id, toast, navigate, reportRoute);
    }
    
    const handleDraftDelete = async ({target}, report) => {
        const success = await reportHelper.deleteDraft(report.id, toast);
        if(success) {
            props.refetchReports();
        }
    }

    return (
      <Menu key={utils.generateUniqueKey()} as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button key={utils.generateUniqueKey()} className="bg-gray hover:bg-white focus:ring-core-orange-300 inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 text-sm font-bold focus:outline-none">
            Actions
            <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>
  
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div className="origin-top-right absolute right-0 mt-2 w-28 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
                    <div className="py-1 flex align-middle hover:bg-selection-color-200 cursor-pointer" onClick={() => handleReportClick(props.report)}>
                {props.report.isDraft 
                    ? <IconWrapper 
                        title="Edit" 
                        icon={<PencilAltIcon className="ml-2 w-6 text-slate-400"/>} 
                        /> 
                    : <IconWrapper 
                        title="View" 
                        icon={<DocumentReportIcon className="ml-2 w-6 text-slate-400"/>} 
                        /> 
                }
                <div className="text-sm ml-2">{props.report.isDraft ? "Edit" : "View"}</div>
            </div>
            {props.report.reportType !== 1 && 
                        <div className="py-1 flex align-middle hover:bg-selection-color-200 cursor-pointer" onClick={() => handleReportCopyClick(props.report)}>
                    <IconWrapper 
                        title="Copy" 
                        icon={<DocumentDuplicateIcon className="ml-2 w-6 text-slate-400" />} 
                    />
                    <div className="text-sm ml-2">Copy</div>
                </div>
            }
                    <div className="py-1 flex align-middle hover:bg-selection-color-200 cursor-pointer" onClick={(e) => handleDraftDelete(e, props.report)}>
                  <IconWrapper
                      title="Delete"
                      icon={<TrashIcon title="Delete" className="ml-2 w-6 text-slate-400" />}
                  />
                  <div className="text-sm ml-2">Delete</div>
              </div>
          </div>
        </Transition>
      </Menu>
    )
  }
