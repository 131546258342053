import PropTypes from 'prop-types'
import utils from '../shared/utils';
import { ExclamationCircleIcon } from '@heroicons/react/outline';

FormlessInput.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  conditionalLabel: PropTypes.string,
  showConditionalLabel: PropTypes.bool,
  placeHolder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.any,
  containerClassNames: PropTypes.string,
  inputClassNames: PropTypes.string,
  slot1: PropTypes.element,
  validationObject: PropTypes.object,
  symbol: PropTypes.string,
  subText: PropTypes.string,
  optional: PropTypes.bool,
  hasValidationError: PropTypes.bool,
  index: PropTypes.number,
  autoFocus: PropTypes.bool,
  onKeyUp: PropTypes.func
}

FormlessInput.defaultProps = {
  id: "",
  name: "",
  label: "",
  conditionalLabel: "",
  showConditionalLabel: false,
  placeHolder: "",
  onChange: () => true,
  onBlur: () => true,
  value: undefined,
  containerClassNames: "",
  inputClassNames: "",
  slot1: <div></div>,
  validationObject: {},
  symbol: undefined,
  subText: "",
  optional: false,
  hasValidationError: false,
  index: -1,
  autoFocus: false,
  onKeyUp: () => true
};


export default function FormlessInput(props) {

  return (
    <div className={`${props.containerClassNames}`}>
        <div className="flex justify-between">
            <label htmlFor={props.name} className="font-bold text-sm text-gray-700 justify-start">
                {props.label}
                {props.showConditionalLabel && (
                    <span className="font-normal ml-1">{props.conditionalLabel}</span>
                )}
            </label>
            {props.optional && (<span className="text-sm">Optional</span>)}
        </div>
        <div className="mb-1 relative rounded-md shadow-sm">
            <div className="flex items-center">
                <div className="absolute left-0 pl-3 flex pointer-events-none">
                    <span className="text-gray-500 sm:text-sm" style={{paddingTop: "5px"}}>{props.symbol}</span>
                </div>
                <input
                    type={props.type}
                    name={props.name}
                    id={props.id}
                    className={`${props.inputClassNames} ${props.symbol ? "pl-6" : "pl-2"} ${props.hasValidationError ? "border-red-500 border-[3px]" : "border-slate-500 border"} h-10 mt-1 w-full rounded-md shadow-sm focus:border-core-orange-400 focus:outline-none focus:ring-1 focus:ring-core-orange-400`}
                    placeholder={props.placeHolder}
                    value={props.value}
                    data-index={props.index}
                    autoFocus={props.autoFocus}
                    onKeyUp={props.onKeyUp}
                />
                {props.hasValidationError && (
                    <div className="absolute right-0 pr-3 flex pointer-events-none">
                        <ExclamationCircleIcon className="w-6 h-6 text-red-500 pt-1"></ExclamationCircleIcon>
                    </div>
                )}
            </div>
        </div>
        {props.subText && (
            <div className="text-sm text-gray-500 pt-2">
                {props.subText}
            </div>
        )}
    </div>
  )
}
