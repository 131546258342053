import PropTypes from 'prop-types'
import coreColors from '../shared/coreColors';

CircleWithWedge.propTypes = {
    wedgeStartingAngle: PropTypes.number.isRequired,
    text: PropTypes.any,
    labelText: PropTypes.string,
    useAlternateColors: PropTypes.bool,
    useTertiaryColors: PropTypes.bool,
    customPrimaryColor: PropTypes.string,
    customSecondaryColor: PropTypes.string,
    customPrimaryColorHex: PropTypes.string,
    heightStyle: PropTypes.string,
    widthStyle: PropTypes.string,
    containerClassName: PropTypes.string
}

CircleWithWedge.defaultProps = {
    text: "",
    labelText: "",
    useAlternateColors: false,
    useTertiaryColors: false,
    customPrimaryColor: undefined,
    customSecondaryColor: undefined,
    heightStyle: "40px",
    widthStyle: "40px",
    containerClassName: ""
}

export default function CircleWithWedge(props) {
    
    const defaultColorSet = [coreColors.coreOrange200, coreColors.coreOrange100];
    const alternateColorSet = [coreColors.coreBlue200, coreColors.coreBlue100];
    const tertiaryColorSet = [coreColors.corePurple200, coreColors.corePurple100];
    let primaryColor = props.useAlternateColors ? alternateColorSet[0] : defaultColorSet[0];
    let secondaryColor = props.useAlternateColors ? alternateColorSet[1] : defaultColorSet[1];

    if(props.useTertiaryColors) {
        primaryColor = tertiaryColorSet[0];
        secondaryColor = tertiaryColorSet[1];
    }

    if(props.customPrimaryColor) {
        primaryColor = props.customPrimaryColor;
    }
    if(props.customSecondaryColor) {
        secondaryColor = props.customSecondaryColor;
    }

    const dimensionsStyle = { "height": props.heightStyle, "width": props.widthStyle };
    const primarySegmentStyle = { "--offset": 0, "--value": props.wedgeStartingAngle, "--bg": secondaryColor, "--over50": props.wedgeStartingAngle > 50 ? 1 : 0 };
    const secondarySegmentStyle = { "--offset": props.wedgeStartingAngle, "--value": 100 - props.wedgeStartingAngle, "--bg": primaryColor, "--over50": 100 - props.wedgeStartingAngle > 50 ? 1 : 0 };

    return (
        <div className={props.containerClassName}>
            <div className="pie" style={dimensionsStyle}>
                <div className="pie-segment" style={primarySegmentStyle}></div>
                <div className="pie-segment" style={secondarySegmentStyle}></div>
                <span className="pie-text">{props.text}</span>
            </div>
            <div className="text-center">
                <span className="text-xs">{props.labelText}</span>
            </div>
        </div>
    );
}