import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { DropdownButtonOption } from "../shared/models/inputClasses.js"

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

DropdownButton.propTypes = {
    text: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.instanceOf(DropdownButtonOption))
  }
  
DropdownButton.defaultProps = {
    text: "",
    options: []
};
  

export default function DropdownButton(props) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-core-orange-400 hover:bg-core-orange-500  text-sm font-bold text-white focus:ring-core-orange-400 focus:outline-none focus:ring-2 focus:ring-offset-2">
          {props.text}
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
          <div className="py-1">
            {props.options?.map((option, i) => (
                <Menu.Item key={i}>
                    {({ active }) => (
                    <a
                        href={option.link}
                        className={classNames(
                        active ? 'bg-selection-color-200 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm'
                        )}
                    >
                        {option.text}
                    </a>
                    )}
                </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}