import React, { useEffect, useState } from "react";
import { useQuery } from 'react-query';
import { useTheme } from '../../../contexts/ThemeContext';
import BackButton from "../../../components/BackButton";
import Skeleton from 'react-loading-skeleton';
import staticData from '../../../shared/staticData';
import Button from "../../../inputs/Button";
import GuidedEdit from "./GuidedEdit";
import CustomEdit from "./CustomEdit";
import dataCalls from "../../../shared/dataCalls";
import { useNavigate, useParams } from "react-router-dom";

export default function Edit() {

    const [ showGuidedEntry, setShowGuidedEntry ] = useState(false);
    const [ showCustomEntry, setShowCustomEntry ] = useState(false);
    const [ isGuidedImageLoaded, setIsGuidedImageLoaded ] = useState(false);
    const [ isCustomImageLoaded, setIsCustomImageLoaded ] = useState(false);
    const guidedImageStyle = isGuidedImageLoaded ? {} : {display: 'none'};
    const customImageStyle = isCustomImageLoaded ? {} : {display: 'none'};

    const settings = {
        isEdit: window.location.pathname.includes("edit")
    };

    const navigate = useNavigate();
    const params = useParams();
    const fetchMethod = settings.isEdit
        ? () => dataCalls.fetchInternalRateOfReturnViewModelEdit(params.id)
        : () => dataCalls.fetchInternalRateOfReturnViewModelCreate(params.id, params.spouseid);

    const { data: viewModel, isLoading, refetch } = useQuery('vm', fetchMethod, {
        refetchOnMount: true,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: Infinity
    });

    useEffect(() => {
        if(settings.isEdit) {
            refetch();
        }
    }, [params.id])

    const { setBackgroundClass, setJustifyClass, resetTheme } = useTheme();
    useEffect(() => {
        setBackgroundClass("bg-gray");
        setJustifyClass("");
        setIsCustomImageLoaded(false);
        setIsGuidedImageLoaded(false);
        return () => resetTheme(); 
    }, [showGuidedEntry, showCustomEntry]);

    useEffect(() => {
        if(viewModel && settings.isEdit) {
            if(viewModel.report) {
                if(viewModel.report?.reportData?.isGuidedEntry) {
                    setShowGuidedEntry(true);
                } else {
                    setShowCustomEntry(true);
                }     
            }
        }
    }, [viewModel]);

    const [backButtonText, setBackButtonText ] = useState("Back");
    
    const backButtonHandler = () => {
        if(settings.isEdit) {
            navigate(`/v1/consumers/${settings.isEdit ? viewModel?.client?.id : params.id}`);
            return;
        }

        const showEditForm = (showGuidedEntry || showCustomEntry);
        if(showEditForm) {
            setShowGuidedEntry(false);
            setShowCustomEntry(false);
        } else if(!showEditForm) {
            navigate(`/v1/consumers/${settings.isEdit ? viewModel?.client?.id : params.id}`);
        } else {
            navigate(`/v1/consumers/${params.id}`);
        }
    }

    useEffect(() => {
        if(settings.isEdit) {
            setBackButtonText("Back to Client Profile");
            return;
        }

        const showEditForm = (showGuidedEntry || showCustomEntry);
        if(showEditForm) {
            setBackButtonText("Back to Form Selection");
        } else if(!showEditForm) {
            setBackButtonText("Back to Client Profile");
        } else {
            setBackButtonText("Back");
        }
    }, [showGuidedEntry, showCustomEntry]);

    return (
        <div className="justify-center place-content-center w-3/4 mt-6 min-h-full">
            <div className="grid grid-cols-4">
                <div className="block col-span-3">
                    <BackButton onClick={backButtonHandler} text={backButtonText} />
                    {isLoading
                        ? <h3><Skeleton /></h3>
                        : !showGuidedEntry && !showCustomEntry
                        ? <h3>Select How You Want to Build Your Report</h3>
                        : <h3>Internal Rate of Return Report for {viewModel?.names}</h3>
                    }
                </div>
                <div className="w-full flex justify-end items-center pr-4">
                    <img alt="Internal Rate of Return Logo" src={staticData.getReport(2).imgSrc} className="w-24 h-16"></img>
                </div>
            </div>

            {!isLoading && !showGuidedEntry && !showCustomEntry && (
                <div>
                    <div className="mt-4">
                        <div className="grid grid-cols-2">
                            <div className="mr-3 bg-white rounded">
                                <div className="m-6">
                                    <h3 className="text-bold">Quick View</h3>
                                    <span className="text-sm">Simple, guided view with a real-time report preview</span>
                                    {!isGuidedImageLoaded && <div><Skeleton className="h-[30rem] mt-2"/></div>}
                                    <img 
                                        alt="Guided View Skeleton"
                                        className="mt-2 hover:border-2 hover:border-solid hover:rounded-md hover:border-core-orange-300 hover:cursor-pointer" 
                                        style={guidedImageStyle} 
                                        src="/images/reports/internalrateofreturn/guidedview.png" 
                                        onLoad={() => setIsGuidedImageLoaded(true)}
                                        onClick={() => setShowGuidedEntry(true)}
                                    />
                                    <div className="mt-6 flex justify-center">
                                        <Button clickHandler={() => setShowGuidedEntry(true)} text="Select Quick View" classNames="bg-core-orange-400 hover:bg-core-orange-500 mx-1"/>
                                    </div>
                                </div>
                            </div>
                            <div className="ml-3 bg-white rounded">
                                <div className="m-6">
                                    <h3 className="text-bold">Detailed View</h3>
                                    <span className="text-sm mb-2">Make changes year-by-year or copy/paste data from a spreadsheet</span>
                                    {!isCustomImageLoaded && <div><Skeleton className="h-[30rem]"/></div>}
                                    <img 
                                        alt="Custom View Skeleton"
                                        className="mt-2 hover:border-2 hover:border-solid hover:rounded-md hover:border-orange-300 hover:cursor-pointer" 
                                        style={customImageStyle} 
                                        src="/images/reports/internalrateofreturn/customview.png" 
                                        onLoad={() => setIsCustomImageLoaded(true)}
                                        onClick={() => setShowCustomEntry(true)}
                                    />
                                    <div className="mt-6 flex justify-center">
                                        <Button clickHandler={() => setShowCustomEntry(true)} text="Select Custom View" classNames="bg-core-orange-400 hover:bg-core-orange-500 mx-1"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {showGuidedEntry && (
                <GuidedEdit viewModel={viewModel} isLoading={isLoading} isEdit={settings.isEdit} />
            )}
            {showCustomEntry && (
                <CustomEdit viewModel={viewModel} isLoading={isLoading} isEdit={settings.isEdit} />
            )}
        </div>
    )
}
