import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useForm, FormProvider } from "react-hook-form";
import PropTypes from 'prop-types';
import Input from '../../../../inputs/Input';
import Button from '../../../../inputs/Button';
import Switch from '../../../../inputs/Switch';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import utils from "../../../../shared/utils";

CustomEditSlideOver.propTypes = {
  isIncomeForm: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  applyIncomes: PropTypes.func,
  applyBalances: PropTypes.func,
  numberOfYears: PropTypes.number
}

CustomEditSlideOver.defaultProps = {
  applyIncomes: undefined,
  applyBalances: undefined,
  numberOfYears: 100
};

export default function CustomEditSlideOver(props) {

  const [ errorMessages, setErrorMessages ] = useState([]);
  const [ hideOptionalRemainingBalanceInputs, setHideOptionalRemainingBalanceInputs ] = useState(true);
  const incomeFormMethods = useForm();
  const balanceFormMethods = useForm();

  useEffect(() => {
    if(props.isIncomeForm) {
      incomeFormMethods.reset();
    }  
    if(!props.isIncomeForm) {
      balanceFormMethods.reset();
      setHideOptionalRemainingBalanceInputs(true);
    }
    setErrorMessages([]);
  }, [props.open])


  const onSubmit = (data) => {
    if(props.isIncomeForm) {
      if(!customValidate(data)) {
        return false;
      }
      props.applyIncomes(data);
      incomeFormMethods.reset({});
    } 
    
    if(!props.isIncomeForm) {
      if(!customValidate(data)) {
        return false;
      }
      props.applyBalances(data);
      balanceFormMethods.reset({});
    }
    props.onClose();
  }

  const onCancel = () => {
    props.onClose();
  }

  const customValidate = (object) => {
    let errorMessages = [];
    if(Number(object.startYear) > Number(object.endYear)) {
      errorMessages.push("Start Year cannot be greater than End Year.")
    }
    setErrorMessages(errorMessages);
    return !(errorMessages.length > 0);
  }

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={props.onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10" style={{height: "94vh", top: "6vh"}}>
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">   
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div>
                      <Dialog.Title className="text-lg text-gray-900 bg-slate-200">
                        <div className="p-4">
                          <h4>{props.isIncomeForm ? "Annual Income" : "Remaining Balance"}</h4>
                          <span className="text-sm">{props.isIncomeForm 
                            ? "Enter the income received each year, and if a COLA is applied for one or more periods of time." 
                            : "Enter the remaining balance for each year, and if the balance is growing for one or more periods of time."
                          }
                          </span>
                        </div>
                      </Dialog.Title>
                    </div>
                    <div className="relative mt-6 flex-1 px-4 sm:px-6">      
                      {props.isIncomeForm && (
                        <FormProvider {...incomeFormMethods}>
                          <form autoComplete="off">
                            <div className="grid grid-cols-2 bg-slate-200 rounded-lg p-3">
                              <div className="col-span-2 flex">
                                  <Input
                                      type="number"
                                      name={`startYear`}
                                                                  label={"Starting Year"}

                                                                  onInput={(e) => {
                                                                      e.target.value = Math.round(e.target.value);
                                                                  }
                                                                  }
                                      containerClassNames="pr-2 py-3"
                                      hasValidationError={false}
                                      autoFocus={props.open && props.isIncomeForm}
                                      validationObject={{ required: true, min: 1, max: props.numberOfYears }}
                                  />
                                  <Input
                                      type="number"
                                      name={`endYear`}
                                                                  label={"Ending Year"}

                                                                  onInput={(e) => {
                                                                      e.target.value = Math.round(e.target.value);
                                                                  }
                                                                  }
                                      containerClassNames="pl-2 py-3"
                                      hasValidationError={false}
                                      validationObject={{ required: true, min: 1, max: props.numberOfYears }}
                                  />
                              </div>
                              <div className="col-span-2">
                                  <Input
                                      type="number"
                                      name={`annualIncome`}
                                      label={"Annual Income Amount"}
                                      symbol="$"
                                      containerClassNames="py-3"
                                                                  onInput={(e) => {
                                                                      e.target.value = Math.round(e.target.value);
                                                                  }
                                                                  }
                                      hasValidationError={false}
                                      validationObject={{ required: true, min: -1000000000, max: 1000000000 }}
                                  />
                              </div>
                              <div className="col-span-2">
                                  <Input
                                      type="number"
                                      name={`costOfLivingAdjustment`}
                                      label={"Cost of Living Adjustment"}
                                      symbol="%"
                                      subText="Apply COLA to your annual income"
                                      optional={true}
                                      containerClassNames="py-3"   
                                                                  onInput={(e) => {
                                                                      utils.setDecimalPlaces(e);
                                                                  }}
                                      hasValidationError={false}
                                      validationObject={{ required: false, min: -100, max: 100 }}
                                  />
                              </div>
                          </div>
                        <div className="flex justify-end mt-3 mx-1">
                            <Button clickHandler={onCancel} text="Cancel" classNames="bg-slate-200 mx-1"/>
                            <Button clickHandler={incomeFormMethods.handleSubmit((data) => onSubmit(data))} text="Apply" classNames="bg-core-orange-500 mx-1"/>
                        </div>
                        </form>
                        </FormProvider>
                      )}

                      {!props.isIncomeForm && (
                        <FormProvider {...balanceFormMethods}>
                          <form autoComplete="off">
                            <div className="grid grid-cols-2 bg-slate-200 rounded-lg p-3">
                                <div className="col-span-2 flex">
                                  <Switch 
                                      name={`reduceByAnnualIncome`} 
                                      label="Reduce by Annual Income" 
                                      onChange={(e) => setHideOptionalRemainingBalanceInputs(e.target.checked)}
                                      defaultChecked={true}
                                  />
                                </div>
                                <div className="col-span-2 flex">
                                    <Input
                                        type="number"
                                        name={`startYear`}
                                                                  label={"Starting Year"}

                                                                  onInput={(e) => {
                                                                      e.target.value = Math.round(e.target.value);
                                                                  }
                                                                  }
                                        containerClassNames="pr-2 py-3"
                                        hasValidationError={false}
                                        autoFocus={props.open && !props.isIncomeForm}
                                        validationObject={{ required: true, min: 1, max: props.numberOfYears }}
                                    />
                                    <Input
                                        type="number"
                                        name={`endYear`}
                                                                  label={"Ending Year"}

                                                                  onInput={(e) => {
                                                                      e.target.value = Math.round(e.target.value);
                                                                  }
                                                                  }
                                        containerClassNames="pl-2 py-3"
                                        hasValidationError={false}
                                        validationObject={{ required: true, min: 1, max: props.numberOfYears }}
                                    />
                                </div>
                                <div className={`col-span-1 ${hideOptionalRemainingBalanceInputs ? "hidden" : "block"}`}>
                                    <Input
                                        type="number"
                                        name={`remainingBalance`}
                                        label={"Remaining Balance Amount"}
                                        symbol="$"

                                                                  onInput={(e) => {
                                                                      e.target.value = Math.round(e.target.value);
                                                                  }
                                                                  }


                                        containerClassNames="py-3"
                                        hasValidationError={false}
                                        validationObject={{ required: false, min: -1000000000, max: 1000000000 }}
                                    />
                                </div>
                                <div className="col-span-1">
                                    <Input
                                        type="number"
                                        name={`growthRate`}
                                        label={"Growth Rate"}
                                        symbol="%"
                                                                  onInput={(e) => {
                                                                      utils.setDecimalPlaces(e);
                                                                  }}
                                        subText="Apply a percentage of annual growth in your remaining balance"
                                        optional={true}
                                        containerClassNames="py-3"                            
                                        hasValidationError={false}
                                        validationObject={{ required: false, min: -100, max: 100 }}
                                    />
                                </div>
                            </div>
                            <div className="flex justify-end mt-3 mx-1">
                                <Button clickHandler={onCancel} text="Cancel" classNames="bg-slate-200 mx-1"/>
                                <Button clickHandler={balanceFormMethods.handleSubmit((data) => onSubmit(data))} text="Apply" classNames="bg-core-orange-500 mx-1"/>
                            </div>
                        </form>
                        </FormProvider>
                      )}

                      {errorMessages.length > 0 && (
                          <div className="block mt-4">
                              <div>Resolve the errors to apply {props.isIncomeForm ? "Annual Incomes" : "Remaining Balances"}</div>
                              <ul className="ml-4">
                                  {errorMessages.map((error, i) => (
                                      <li key={i} className="text-sm list-disc">{error}</li>
                                  ))}
                              </ul>
                          </div>
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}