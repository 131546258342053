import PropTypes from 'prop-types'
import { useFormContext } from "react-hook-form";
import utils from '../shared/utils';

InputInline.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  placeHolder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  inputClassNames: PropTypes.string,
  slot1: PropTypes.element,
  validationObject: PropTypes.object,
  autoFocus: PropTypes.bool
}

InputInline.defaultProps = {
  id: "",
  label: "",
  placeHolder: "",
  onChange: undefined,
  value: undefined,
  inputClassNames: "",
  slot1: <div></div>,
  validationObject: {},
  autoFocus: false
};

export default function InputInline(props) {
    const { register, formState: { errors } } = useFormContext();

    const validationMessages = {
      required: `${props.label} is required.`,
      minLength: `${props.label} has a minimum length of ${props.validationObject['minLength']}`,
      maxLength: `${props.label} has a maximum length of ${props.validationObject['maxLength']}`,
      min: `${props.label} must be at least ${props.validationObject['min']}`,
      max: `${props.label} must be at most ${props.validationObject['max']}`,
      maxDate: errors[`${props.name}`]?.message,
      minDate: errors[`${props.name}`]?.message,
      validate: errors[`${props.name}`]?.message,
    }

    //react-hook-from overrides the default onChange handler, so add additional functionality in the register
    const fieldRegister = register(props.name, props.validationObject);
    const defaultOnChange = fieldRegister.onChange;
    fieldRegister.onChange = (e) => {
      const res = defaultOnChange(e);
      //Additional functionality
      props.onChange(e);
      return res;
    }


    return (
      <div className="flex items-center py-3">
        <label htmlFor={props.name} className="text-sm font-bold w-1/4">{props.label}</label>
        <div className="w-1/2 max-w-md sm:text-sm">
          <input
            type={props.type}
            name={props.name}
            id={props.id}
            className={`${props.inputClassNames} pl-2 h-10 mt-1 w-full border-slate-500 rounded-md border shadow-sm focus:border-core-orange-400 focus:outline-none focus:ring-1 focus:ring-core-orange-400`}
            placeholder={props.placeHolder}
            value={props.value}
            autoFocus={props.autoFocus}
            {...fieldRegister}
          />
          {!utils.isEmpty(errors) && (
            <ul className="list-disc ml-6 mt-2">
              {validationMessages[errors[props.name]?.type] && <li className="text-core-orange-500">{validationMessages[errors[props.name]?.type]}</li>}
            </ul>
          )}
        </div>
        <div>
          {props.slot1}
        </div>
      </div>
    )
  }