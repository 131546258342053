class staticData {
    static reports = [
        {
            reportType: 1,
            isActive: true,
            allowAdminAccess: true,
            title: "Longevity",
            route: "longevity",
            upperCamelCaseName: "Longevity",
            description: "Evaluate your client's potential longevity based on their age and gender", 
            imgSrc: "/images/reports/LongevityReport.svg",
        },
        {
            reportType: 2,
            isActive: true,
            allowAdminAccess: true,
            title: "Internal Rate of Return",
            route: "internalrateofreturn",
            upperCamelCaseName: "InternalRateOfReturn",
            description: "Determine the internal rate of return (IRR) of a client's scenario", 
            imgSrc: "/images/reports/IrrReport.svg",       
        },
        {
            reportType: 3,
            isActive: true,
            allowAdminAccess: true,
            title: "Inflation",
            route: "inflation",
            upperCamelCaseName: "Inflation",
            description: "Review how inflation will influence your client's income over time", 
            imgSrc: "/images/reports/InflationReport.svg",       
        },
        {
            reportType: 4,
            isActive: false,
            allowAdminAccess: false,
            title: "Lifetime Benefit",
            route: "lifetimebenefit",
            upperCamelCaseName: "LifetimeBenefit",
            description: "How will your client's accounts perform over their entire lifetime?", 
            imgSrc: "/images/reports/laptop2.png",       
        },
        {
            reportType: 5,
            isActive: false,
            allowAdminAccess: false,
            title: "Retirement Goal",
            route: "retirementgoal",
            upperCamelCaseName: "RetirementGoal",
            description: "Determine how well your client's accounts perform over time", 
            imgSrc: "/images/reports/clipboard.png",       
        },
        {
            reportType: 6,
            isActive: true,
            allowAdminAccess: true,
            title: "Sequence of Returns",
            route: "sequenceofreturns",
            upperCamelCaseName: "SequenceOfReturns",
            description: "Compare how differing scenarios affect your client's retirement income", 
            imgSrc: "/images/reports/SequenceOfReturnReport.svg",       
        },
    ];

    static getReport = (reportType) => staticData.reports.filter(r => r.reportType === reportType)[0];
    static getActiveReports = () => staticData.reports.filter(r => r.isActive);
}

export default staticData;   