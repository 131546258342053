import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useParams, useNavigate } from 'react-router-dom';
import { useTheme } from "../../contexts/ThemeContext";
import { PlusCircleIcon } from'@heroicons/react/outline';
import BackButton from '../../components/BackButton';
import ConsumerCard from '../../components/ConsumerCard';
import ReportCard from '../../components/ReportCard';
import SearchBar from '../../inputs/SearchBar';
import dataCalls from "../../shared/dataCalls";
import staticData from '../../shared/staticData';
import Button from '../../inputs/Button';
import { ToastContainer } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import PastReportsTable from './Components/PastReportsTable';
import { useUser } from '../../contexts/UserContext';

export default function Profile() {

    const { userModel } = useUser();

    const [addSpouseClicked, setAddSpouseClicked] = useState(false);
    const [isUpdatingSpouseLink, setIsUpdatingSpouseLink ] = useState(false);

    const navigate = useNavigate();
    const params = useParams();

    const { data, isLoading, refetch, remove } = useQuery('consumers', () => dataCalls.fetchConsumer(params.id), {
        refetchOnMount: true,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false
    });

    const { data: reports, isLoading: isLoadingReports, refetch: refetchReports, remove: removeReports } = useQuery('reports', () => dataCalls.getConsumerReports(params.id), {
        refetchOnMount: true,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false
    });

    useEffect(() => {
        return () => {
            remove();
            removeReports();
        }
    }, [])

    const { setBackgroundClass, setJustifyClass, resetTheme } = useTheme();
    useEffect(() => {
        setBackgroundClass("bg-gray");
        setJustifyClass("");
        return () => {
            resetTheme();
        }
    }, []);

    const handleUnlink = async () => {
        setIsUpdatingSpouseLink(true);
        await dataCalls.updateRelationship(data?.client?.id, data?.spouse?.id, true);
        refetchReports();
        await refetch();
        setAddSpouseClicked(false);
        setIsUpdatingSpouseLink(false);
    }

    const handleNewSpouseClick = () => {
        navigate(`/v1/consumers/create?clientid=${data?.client?.id}`);
    }

    const handleSpouseSelect = async (selectedId) => {
        setIsUpdatingSpouseLink(true);
        await dataCalls.updateRelationship(data?.client?.id, selectedId);
        refetchReports();
        await refetch();
        setIsUpdatingSpouseLink(false);
    }

    return (
      <div className="justify-center place-content-center w-3/4 mt-6 min-h-full">
        <div className="justify-start">
                <BackButton redirectUrl={"/v1/consumers"}/>
            <h3 className="font-bold">Client Profile</h3>
        </div>
        <div className="grid grid-cols-2 mt-4">
            <div className="mr-3">
                {!isLoading && data?.client 
                ? (<ConsumerCard consumer={data.client}></ConsumerCard>) 
                : (<Skeleton className="h-32" />)
                }
            </div>
            <div className="ml-3">
                {isLoading || isUpdatingSpouseLink
                    ? (<Skeleton className="h-32" />)
                    : data?.spouse && 
                    (<div>
                        <ConsumerCard consumer={data?.spouse} isSpouse={true} primaryId={data?.client?.id}></ConsumerCard>
                        <div tabIndex={0} onKeyUp={(e) => e.key === "Enter" && handleUnlink()} className="text-core-orange-400 font-bold text-center hover:underline cursor-pointer mt-2">
                            <span onClick={handleUnlink}>Unlink Spouse</span>
                        </div>
                    </div>)
                }
                {!isLoading && !data?.spouse && !addSpouseClicked && (
                    <div onClick={() => setAddSpouseClicked(true)} className="w-full h-[8.5rem] flex justify-center items-center border-core-orange-400 rounded-sm border-dashed border-2 cursor-pointer">
                        <div tabIndex={0} onKeyUp={(e) => e.key === "Enter" && setAddSpouseClicked(true)} className="flex">
                            <PlusCircleIcon className="w-5 text-core-orange-400"/>
                            <span className="text-core-orange-400 ml-1">Link Spouse</span>
                        </div>
                    </div>               
                )}
                {addSpouseClicked && !isUpdatingSpouseLink && !data?.spouse && (
                    <div className="w-full h-[8.5rem] flex justify-center items-center border-orange-400 rounded-sm border-dashed border-2">
                        <div className="w-3/4">
                            <div className="w-full">
                                <SearchBar searchDataCall={dataCalls.consumerSingleSearch} skipId={data?.client?.id} asyncSelectionFunction={handleSpouseSelect} placeholder="Search for existing spouse"/>
                            </div>
                            <div className="font-bold mt-4 flex justify-center items-center">
                                <span className="font-bold mr-4">Or</span>
                                <Button text="Create New Client" classNames="bg-core-orange-400" clickHandler={handleNewSpouseClick}></Button>
                            </div>
                        </div>
                    </div> 
                )}
            </div>
        </div>

        {/* Reports Container */}
        <div className={`justify-start ${data?.spouse ? 'mt-4' : 'mt-10'}`}>
            <h4 className="font-bold">Select a report to run</h4>
        </div>
        <div className="grid grid-cols-2 gap-y-6 gap-x-6">
            {staticData.reports.filter(r => r.isActive || r.allowAdminAccess && userModel && userModel.permissions.includes(3)).map((report, i) =>
                <ReportCard key={i} route={report.route} title={report.title} description={report.description} imageSrc={report.imgSrc} client={data?.client} spouse={data?.spouse} />
            )}
        </div>

        {/* Past Reports Container */}
        <PastReportsTable reports={reports} isLoading={isLoadingReports} refetchReports={refetchReports}/>
        <ToastContainer />
      </div>
    );
}