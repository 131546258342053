import { useFormContext } from "react-hook-form";
Select.defaultProps = {
    name: "defaultName",
    labelText: "",
    options: ["name", "value"],
    handleOnChange: () => true
};

export default function Select(props) {
    const { register } = useFormContext();

    return (
        <>
            <label className="top-[4%] font-bold text-sm text-gray-700 justify-start relative">
                {props.labelText}
            </label>
            <select {...register(props.name)}
                name={props.name}
                className="top-[-3%] relative text-sm font-normal inline mb-1 border border-slate-500 h-10 mt-1 w-full rounded-md shadow-sm focus:border-core-orange-400 focus:outline-none focus:ring-1 focus:ring-core-orange-400"
                onChange={props.handleOnChange}
            >
                {props.options.map(element => {
                    let value = element[0];
                    let optionText = element?.[1] ?? element[0];
                    return (
                        <option
                            key={value}
                            value={value}
                            dangerouslySetInnerHTML={{ __html: optionText }}
                        />
                        )
                })}
            </select>
        </>
    )

}