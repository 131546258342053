import dataCalls from "./dataCalls";
import utils from "./utils";

class reportHelper {
    
    static extractHtml = () => {
        let clonedHtmlNode = document.getElementsByTagName("html")[0].cloneNode(true);
        const navElement = clonedHtmlNode.querySelector("nav");
        const appElement = clonedHtmlNode.querySelector(".App");
        const previewElement = clonedHtmlNode.querySelector(".report-content");
        previewElement.style.zoom = "90%";
        navElement.remove();
        appElement.replaceWith(previewElement);      

        const pageHtml = clonedHtmlNode.outerHTML;
        return pageHtml;
    }

    static openPdf = async (reportId, toast) => {
        const toastId = toast.loading("Creating PDF...", {
            position: toast.POSITION.BOTTOM_CENTER
        });

        const pdfByteArray = await dataCalls.getReportPdf(reportId);

        try {
            const arrayBuffer = utils.base64ToArrayBuffer(pdfByteArray);
            const blob = new Blob([arrayBuffer], {type: "application/pdf"});
            const link = window.URL.createObjectURL(blob);
            const pdfTab = window.open(link); 
            if(!pdfTab) {
                toast.error("Popups must be allowed to print this report. Please enable popups and try again.", {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            }
            
            toast.update(toastId, { render: "Success!", type: "success", isLoading: false });
            setTimeout(() => toast.dismiss(toastId.current), 1500);    
        } catch(e) { 
            toast.update(toastId, { render: "An error occurred...", type: "error", isLoading: false });
            setTimeout(() => toast.dismiss(toastId.current), 1500);
        }
    }  

    static copyReport = async (reportId, toast, navigate, reportRoute) => {
        // copy report comment for testing purposes
        const toastId = toast.loading("Copying Report...", {
            position: toast.POSITION.BOTTOM_CENTER
        });

        const response = await dataCalls.copyReport(reportId);
        if(response.success) {
            toast.update(toastId, { render: "Success!", type: "success", isLoading: false });
            setTimeout(() => toast.dismiss(toastId.current), 1000);
            setTimeout(() => navigate(`/v1/reports/${reportRoute}/edit/${response.data}`), 1000);
            return;
        }
        toast.update(toastId, { render: "An error occurred...", type: "error", isLoading: false });
        setTimeout(() => toast.dismiss(toastId.current), 1500);
    }

    static finalizeLongevity = async (report, toast, navigate, reportRoute, initPrint = false) => {
        const toastId = toast.loading("Creating report...", {
            position: toast.POSITION.BOTTOM_CENTER
        });

        const data = JSON.parse(JSON.stringify(report));
        const finalizeResponse = await dataCalls.finalizeLongevity(data);
        this.handleFinalizeResponse(finalizeResponse, toast, toastId, navigate, reportRoute, initPrint)
    }

    static finalizeReport = async (id, toast, navigate, reportRoute, initPrint = false) => {
        
        const toastId = toast.loading("Creating report...", {
            position: toast.POSITION.BOTTOM_CENTER
        });

        const finalizeResponse = await dataCalls.finalizeReport(id, reportRoute);
        this.handleFinalizeResponse(finalizeResponse, toast, toastId, navigate, reportRoute, initPrint)
    }

    static handleFinalizeResponse = (finalizeResponse, toast, toastId, navigate, reportRoute, initPrint = false) => {
        if(finalizeResponse.success) {
            toast.update(toastId, { render: "Save successful", type: "success", isLoading: false });
        } else {
            toast.update(toastId, { render: "An error occurred...", type: "error", isLoading: false });
            return false;
        }
        setTimeout(() => toast.dismiss(toastId.current), 1000);
        
        setTimeout(() => {
            if(finalizeResponse.success) {
                let reportLink = `/v1/reports/${reportRoute}/report/${finalizeResponse.data}`;
                if(initPrint) {
                    reportLink += "?print=true";
                }
                navigate(reportLink)
            } else {
                return false;
            }
        }, 1000);
    }

    static deleteDraft = async (reportId, toast) => {
        const toastId = toast.loading("Deleting report...", {
            position: toast.POSITION.BOTTOM_CENTER
        });

        const deleteResponse = await dataCalls.deleteReport(reportId);

        if(deleteResponse.success) {
            toast.update(toastId, { render: "Delete successful", type: "success", isLoading: false });
        } else {
            toast.update(toastId, { render: "An error occurred...", type: "error", isLoading: false });
            return false;
        }
        setTimeout(() => toast.dismiss(toastId.current), 1000);
        return true;
    }
}

export default reportHelper;