import { useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify';
import { useTheme } from '../../../contexts/ThemeContext'
import utils from '../../../shared/utils';
import "../../../styles/reports/internalrateofreturn/preview.css";
import PreviewButtons from '../Shared/Buttons/PreviewButtons';
import Skeleton from 'react-loading-skeleton';
import { useNavigate, useParams } from 'react-router-dom';
import staticData from '../../../shared/staticData';
import BackButton from '../../../components/BackButton';
import dataCalls from '../../../shared/dataCalls';
import { useQuery } from 'react-query';

  
export default function Preview() {

    const params = useParams();

    const { data, isLoading } = useQuery('internalRateOfReturnPreview', () => dataCalls.fetchReportViewModel(params.id), {
        refetchOnMount: true,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false
    });

    const [ isLoadingIframe, setIsLoadingIframe ] = useState(true);
    const iFrameStyle = !isLoadingIframe ? {} : {visibility: 'hidden'};
    const onIFrameLoad = (e) => {
        setIsLoadingIframe(false);
        utils.onIframeLoad(e, "IRRPreviewContent", 1.01);
    }

    const { setBackgroundClass, setJustifyClass, resetTheme } = useTheme();
    useEffect(() => {
        setBackgroundClass("bg-gray");
        setJustifyClass("");
        return () => resetTheme(); 
    }, []);

    useEffect(() => {
        window.scrollTo({top: 0, left: 0 });
      }, []);

    const navigate = useNavigate();
    const backToEditMethod = () => {
        navigate(`/v1/reports/internalrateofreturn/edit/${params.id}`);
    }

    return (
        <div className="justify-center place-content-center w-3/4 mt-6 min-h-full h-full">
            <div className="grid grid-cols-2">
                <div className="block">
                    <BackButton text="Back to Report Builder" onClick={backToEditMethod} />
                    {isLoading
                        ? <h2><Skeleton /></h2>
                        : <h2>Internal Rate of Return Report for {data?.names}</h2>
                    }
                </div>
                <div className="w-full flex justify-end items-center pr-4">
                    <img alt="Internal Rate Of Return Logo" src={staticData.getReport(2).imgSrc} className="w-24 h-16"></img>
                </div>
            </div>
            <div className="flex justify-start mt-6 mb-2">
                <h3>Report Preview</h3>
            </div>

            {isLoadingIframe && <Skeleton height={utils.getWindowDimensions().height * 0.7} />} 
            <div className="flex w-full flex-col">
                <iframe 
                    title="Internal Rate of Return Report Preview"
                    style={iFrameStyle}
                    src={`/api/reports/internalrateofreturn/preview?id=${params.id}`}
                    onLoad={(onIFrameLoad)}
                />
            </div>

            <PreviewButtons reportId={Number(params.id)} reportType={2} backToEditMethod={backToEditMethod} showBackToEditButton={false} />
            <ToastContainer />
        </div>
    )
}
