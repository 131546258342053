import React from 'react'
import PropTypes from 'prop-types';
import utils from '../../../../shared/utils';
import "../../../../styles/reports/inflation/edit.css";
import shared from '../../../../scripts/reports/internalrateofreturn/shared';

ProjectionTable.propTypes = {
  ledger: PropTypes.array.isRequired,
  viewModel: PropTypes.object.isRequired,
  isPreview: PropTypes.bool,
}

ProjectionTable.defaultProps = {
    isPreview: false
};

export default function ProjectionTable(props) {
  return (
    <div>
          <table className="w-full inflation-table-edit mr-2">
            <thead className="bg-slate-200">
                <tr className="text-left">
                    <th className="text-center">Year</th>
                    <th className="text-center">{props.viewModel.client.firstName}'s Age</th>
                    {props.viewModel?.spouse && (<th className="text-center">{props.viewModel.spouse.firstName}'s Age</th>)}
                    {!props.isPreview && <th className="text-right">Annual Income</th>}
                      {!props.isPreview && <th className="text-right">Assumed Inflation</th>}
                      {!props.isPreview && <th className="text-right">Inflation Impact</th>}
                      {!props.isPreview && <th className="text-right pr-2">Spending Power</th>}
                </tr>
            </thead>
            <tbody className="bg-white">
                {props.ledger?.length > 0 && (
                    props.ledger?.map((entry) => (
                        <tr key={entry.year} className={shared.getLedgerRowBackgroundClass(entry, props.viewModel, "")}>
                            <td className="text-center">{entry.year}</td>
                            <td className="text-center">{entry.clientAge}</td>
                            {props.viewModel.spouse && (<td className="text-center">{entry.spouseAge}</td>)}
                            {!props.isPreview && <td className="text-right">{utils.formatAsCurrency(entry.annualIncome, 0)}</td>}
                            {!props.isPreview && <td className="text-right">{entry.assumedInflationPercentageString}</td>}
                            {!props.isPreview && <td className="text-right">{entry.inflationImpactPercentageString}</td>}
                            {!props.isPreview && <td className="text-right pr-2">{utils.formatAsCurrency(entry.spendingPower, 0)}</td>}
                        </tr>
                )))}
                {(!props.ledger || props.ledger.length === 0) && (
                    <tr className="text-center">
                        <td colSpan={8} className="pt-1">Not yet calculated</td>
                    </tr>
                )}
            </tbody>
        </table>
    </div>
  )
}
