import { useQuery } from 'react-query'
import { useNavigate } from "react-router-dom"
import SearchBar from "../../inputs/SearchBar"
import Button from "../../inputs/Button"
import dataCalls from "../../shared/dataCalls";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useEffect } from 'react';

function Index() {
  
  const navigate = useNavigate();

  const handleCreateButtonClick = () => {
      navigate("/v1/consumers/create");
  }

  const handleRecentConsumerClick = ({target}) => {
    let element = target.tagName === "LI"
      ? target
      : target.parentNode;
    const id = element.dataset.id;
    dataCalls.createRecentlyViewedConsumer(id);
      navigate(`/v1/consumers/${id}`);
  }

  const searchSelectionHandler = async (id) => {
    dataCalls.createRecentlyViewedConsumer(id);
      navigate(`/v1/consumers/${id}`);
  }

  const { 
    data: recentlyViewedData, 
    isLoading: isFetchingRecentlyViewed,
    remove: resetRecentlyViewed
  } = useQuery("recentlyviewed", dataCalls.fetchRecentlyViewedConsumers, {
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false
  });

  const {
    data: currentAdvisor,
    remove: resetCurrentAdvisor
  } = useQuery("currentAdvisor", dataCalls.fetchCurrentAdvisor, {
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    return () => {
      resetRecentlyViewed();
      resetCurrentAdvisor();
    }
  }, [])

    const listItemClasses = "relative cursor-pointer select-none py-2 pl-3 pr-9 text-gray-900 hover:bg-selection-color-200 grid focus:bg-selection-color-200 focus:outline-none";
  const recentlyViewedResults = isFetchingRecentlyViewed
  ? (<Skeleton className="h-12" count={4} />)
  : (<ul 
      className="mt-1 max-h-96 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm"
    >
    {recentlyViewedData.length > 0 ? (
        recentlyViewedData.map((consumer) => (
          <li 
            key={consumer.id} 
            data-id={consumer.id} 
            onClick={(e) => handleRecentConsumerClick(e)} 
            tabIndex={0}
            onKeyUp={(e) => e.key === "Enter" && handleRecentConsumerClick(e)} 
            className={listItemClasses}>
            <span className="font-medium">{consumer.name}</span>
            <span className="text-gray-400">{consumer.spouseName}</span>
          </li>
        ))
    ) : (
      <li className={listItemClasses}>No Results</li>
    )}
  </ul>)

  return (
    <div className="justify-center grid grid-cols-3 place-content-center h-48">
      <div className="col-start-2">
        <h2 className="mb-5 core-h2 text-center w-3/4 m-auto">
          <span>Search {currentAdvisor?.name}'s existing clients or add a new one</span>
        </h2>
        <div className="flex justify-center my-5">
                  <Button classNames="core-button-gradient bg-core-orange-400 hover:bg-core-orange-600 focus:ring-core-orange-400 focus:outline-none focus:ring-2 focus:ring-offset-2" text="Add New Client" clickHandler={handleCreateButtonClick} />
        </div>
        <SearchBar searchDataCall={dataCalls.consumerSearch} asyncSelectionFunction={searchSelectionHandler}/>
        <div className="my-5">
          <b>Recently Viewed</b>
          {recentlyViewedResults}
        </div>
      </div>
    </div>
  );
}
  
export default Index;