import React, { useEffect, useState, useCallback } from "react";
import { useQuery } from 'react-query';
import { useForm, FormProvider } from "react-hook-form";
import { useTheme } from '../../../contexts/ThemeContext';
import BackButton from "../../../components/BackButton";
import Skeleton from 'react-loading-skeleton';
import staticData from '../../../shared/staticData';
import dataCalls from "../../../shared/dataCalls";
import remoteCalculations from "../../../shared/remoteCalculations";
import globalSettings from "../../../shared/settings";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { ExclamationCircleIcon, PlusCircleIcon } from "@heroicons/react/outline";
import ProjectionTable from "./Components/ProjectionTable";
import Input from "../../../inputs/Input";
import Button from "../../../inputs/Button";
import "../../../styles/reports/shared/shared.css";
import "../../../styles/reports/inflation/edit.css";
import "../../../styles/reports/inflation/table.css";
import AnnualIncome from "../../Reports/InternalRateOfReturn/Components/AnnualIncome"
import InflationPeriod from "./Components/InflationPeriod";

export default function Edit() {
    const settings = {
        isEdit: window.location.pathname.includes("edit")
    };

    const [calculationResults, setCalculationResults] = useState({});
    const [triggerCalculationUpdate, setTriggerCalculationUpdate] = useState(false);
    const [formErrors, setFormErrors] = useState({
        errors: [],
        incomeOverlapErrorIndices: [],
        inflationPeriodErrorIndices: []
    });

    const getAnnualIncomeErrors = () => {
        return formErrors.errors.filter(error => error.name.includes("annualIncomes"));
    }

    const getInflationPeriodErrors = () => {
        return formErrors.errors.filter(error => error.name.includes("inflationPeriods"));
    }

    const formMethods = useForm();

    const params = useParams();
    const navigate = useNavigate();

    const fetchMethod = settings.isEdit
        ? () => dataCalls.fetchInflationViewModelEdit(params.id)
        : () => dataCalls.fetchInflationViewModelCreate(params.id, params.spouseid);

    const { data: viewModel, isLoading, refetch } = useQuery('vm', fetchMethod, {
        refetchOnMount: true,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: Infinity
    });

    useEffect(() => {
        if (settings.isEdit) {
            refetch();
        }
    }, [params.id])

    const { setBackgroundClass, setJustifyClass, resetTheme } = useTheme();
    useEffect(() => {
        setBackgroundClass("bg-gray");
        setJustifyClass("");
        return () => resetTheme();
    }, []);

    //Set initial form state on viewmodel load
    useEffect(() => {
        if (viewModel && (settings.isEdit && viewModel.report || !settings.isEdit)) {
            formMethods.setValue("reportName", viewModel.report.reportName);
            formMethods.reset(settings.isEdit
                ? viewModel.report.reportData
                : viewModel.defaultReportData);
            if (isValid()) setTriggerCalculationUpdate(true);
        }
    }, [viewModel])

    const updateCalculations = useCallback(async () => {
        if (triggerCalculationUpdate && viewModel) {
            const data = formMethods.getValues();
            const scenario = {
                clientAge: viewModel.client.age,
                spouseAge: viewModel.spouse?.age ?? 0,
                reportData: JSON.parse(JSON.stringify(data))
            };
            const results = await remoteCalculations.getInflationLedger(scenario);
            setCalculationResults(results);
        }
        setTriggerCalculationUpdate(false);
    }, [triggerCalculationUpdate]);

    useEffect(() => {
        updateCalculations();
    }, [updateCalculations]);

    const updateFormDataItem = ({ name, value }, updateCalculations = true, validateBasicInputs = false) => {
        formMethods.setValue(name, value);
        if (isValid(validateBasicInputs) && updateCalculations) setTriggerCalculationUpdate(true);
    }

    const onSubmit = async (data) => {
        await handleEditSubmit(viewModel, data, toast, navigate);
    }

    const handleEditSubmit = async (viewModel, data, toast, navigate) => {
        if (!isValid(true)) {
            toast.error("Correct the form errors before continuing.", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            return false;
        }
        let report = viewModel.report;
        report.reportName = data.reportName;
        delete data.reportName;
        report.reportData = data;

        //Save and Preview
        const toastId = toast.loading("Saving report...", {
            position: toast.POSITION.BOTTOM_CENTER
        });
        const response = await dataCalls.saveDraft(report);
        if (response.success) {
            toast.update(toastId, { render: "Save successful", type: "success", isLoading: false });
            setTimeout(() => navigate(`/v1/reports/inflation/preview/${response.data}`), 1000);
        } else {
            toast.update(toastId, { render: "An error occurred...", type: "error", isLoading: false });
        }
        setTimeout(() => toast.dismiss(toastId.current), 1000);
        return response.success;
    }

    const isValid = (includeBasicInputs = false) => {
        const data = formMethods.getValues();
        const validationErrors = buildValidationErrors(data, includeBasicInputs);
        setFormErrors(validationErrors);
        return validationErrors.errors.length === 0 && isScenarioComplete(data);
    }

    const hasFieldError = (fieldName) => {
        return formErrors.errors.filter(e => e.name === fieldName).length > 0;
    }

    const isScenarioComplete = (data) => {
        return data.numberOfYears > 0;
    }

    const addIncome = () => {
        let formData = formMethods.getValues();
        let formDataClone = JSON.parse(JSON.stringify(formData));
        let lastEntryEndYear = Number(formDataClone.annualIncomes[formDataClone.annualIncomes.length - 1].endYear);
        let startYear = lastEntryEndYear === formDataClone.numberOfYears ? formDataClone.numberOfYears : lastEntryEndYear + 1;
        let endYear = formDataClone.numberOfYears;
        formDataClone.annualIncomes = [...formDataClone.annualIncomes, {
            startYear: startYear,
            endYear: endYear,
            annualIncome: 0,
            costOfLivingAdjustment: 0
        }];
        formMethods.reset(formDataClone);
        if (isValid()) setTriggerCalculationUpdate(true);

        const annualIncomesLength = formDataClone.annualIncomes.length;
        setTimeout(() => formMethods.setFocus(`annualIncomes[${annualIncomesLength - 1}].startYear`), 1);
    }

    const removeIncome = (index) => {
        let formData = formMethods.getValues();
        let formDataClone = JSON.parse(JSON.stringify(formData));
        formDataClone.annualIncomes = formDataClone.annualIncomes.filter((item, i) => i !== index);
        formMethods.reset(formDataClone);
        if (isValid()) setTriggerCalculationUpdate(true);
    }

    const addInflationPeriod = () => {
        let formData = formMethods.getValues();
        let formDataClone = JSON.parse(JSON.stringify(formData));
        let lastEntryEndYear = Number(formDataClone.inflationPeriods[formDataClone.inflationPeriods.length - 1].endYear);
        let startYear = lastEntryEndYear === formDataClone.numberOfYears ? formDataClone.numberOfYears : lastEntryEndYear + 1;
        let endYear = formDataClone.numberOfYears;
        formDataClone.inflationPeriods = [...formDataClone.inflationPeriods, {
            startYear: startYear,
            endYear: endYear,
            assumedInflation: 3
        }];
        formMethods.reset(formDataClone);
        if (isValid()) setTriggerCalculationUpdate(true);

        const inflationPeriodsLength = formDataClone.inflationPeriods.length;
        setTimeout(() => formMethods.setFocus(`inflationPeriods[${inflationPeriodsLength - 1}].startYear`), 1);
    }

    const removeInflationPeriod = (index) => {
        let formData = formMethods.getValues();
        let formDataClone = JSON.parse(JSON.stringify(formData));
        formDataClone.inflationPeriods = formDataClone.inflationPeriods.filter((item, i) => i !== index);
        formMethods.reset(formDataClone);
        if(isValid()) setTriggerCalculationUpdate(true);
    }

    const buildValidationErrors = (form, includeBasicInputs = false) => {
        let errorObjects = [];

        if (includeBasicInputs) {
            if (form.reportName?.length === 0) {
                errorObjects.push({
                    name: "reportName",
                    message: "Report must be named."
                });
            }
        }

        if (form.numberOfYears < 1) {
                errorObjects.push({
                    name: "numberOfYears",
                    message: "Number of years must be greater than or equal to 1."
                });
            }
        if (form.numberOfYears > 100) {
                errorObjects.push({
                    name: "numberOfYears",
                    message: "Number of years must be less than or equal to 100."
                });
        }

        if (form.assumedInflation > 100) {
            errorObjects.push({
                name: "expectedGrowthRate",
                message: "The assumed inflation rate must be less than 100%."
            });
        }
        if (form.assumedInflation < -100) {
            errorObjects.push({
                name: "expectedGrowthRate",
                message: "The assumed inflation rate must be greater must than -100%."
            });
        }

        let incomeOverlapErrorIndices = checkForOverlap(form.annualIncomes);
        if (incomeOverlapErrorIndices.length > 0) {
            errorObjects.push({
                name: `annualIncomes`,
                message: "Annual Income years cannot overlap."
            });
        }
        
        form.annualIncomes.forEach((income, i) => {
            income.startYear = Number(income.startYear);
            income.endYear = Number(income.endYear);

                if (income.startYear > income.endYear) {
                    errorObjects.push({
                        name: `annualIncomes[${i}].years`,
                        message: `Annual Income (Years ${income.startYear} - ${income.endYear}): Start Year must be less than or equal to End Year.`
                    });
                }
                if (income.startYear <= 0) {
                    errorObjects.push({
                        name: `annualIncomes[${i}].startYear`,
                        message: `Annual Income (Years ${income.startYear} - ${income.endYear}): Start Year must be positive.`
                    });
                }
                if (income.endYear <= 0) {
                    errorObjects.push({
                        name: `annualIncomes[${i}].endYear`,
                        message: `Annual Income (Years ${income.startYear} - ${income.endYear}): End Year must be positive.`
                    });
                }
                if (income.costOfLivingAdjustment < -100) {
                    errorObjects.push({
                        name: `annualIncomes[${i}].costOfLivingAdjustment`,
                        message: `Annual Income (Years ${income.startYear} - ${income.endYear}): Cost of Living Adjustment must be greater than -100%.`
                    });
                }
                if (income.costOfLivingAdjustment > 100) {
                    errorObjects.push({
                        name: `annualIncomes[${i}].costOfLivingAdjustment`,
                        message: `Annual Income (Years ${income.startYear} - ${income.endYear}): Cost of Living Adjustment must be less than 100%.`
                    });
                }
                if (income.startYear > form.numberOfYears) {
                    errorObjects.push({
                        name: `annualIncomes[${i}].startYear`,
                        message: `Annual Income (Years ${income.startYear} - ${income.endYear}): Start Year cannot be outside of the report year range.`
                    });
                }
                if (income.endYear > form.numberOfYears) {
                    errorObjects.push({
                        name: `annualIncomes[${i}].endYear`,
                        message: `Annual Income (Years ${income.startYear} - ${income.endYear}): End Year cannot be outside of the report year range.`
                    });
            }
        })

        let inflationPeriodOverlapErrorIndices = checkForOverlap(form.inflationPeriods);
        if (inflationPeriodOverlapErrorIndices.length > 0) {
            errorObjects.push({
                name: `inflationPeriods`,
                message: "Inflation years cannot overlap."
            });
        }

        form.inflationPeriods.forEach((inflationPeriod, i) => {
            inflationPeriod.startYear = Number(inflationPeriod.startYear);
            inflationPeriod.endYear = Number(inflationPeriod.endYear);

            if (inflationPeriod.startYear > inflationPeriod.endYear) {
                errorObjects.push({
                    name: `inflationPeriods[${i}].years`,
                    message: `Inflation (Years ${inflationPeriod.startYear} - ${inflationPeriod.endYear}): Start Year must be less than or equal to End Year.`
                });
            }
            if (inflationPeriod.startYear <= 0) {
                errorObjects.push({
                    name: `inflationPeriods[${i}].startYear`,
                    message: `Inflation (Years ${inflationPeriod.startYear} - ${inflationPeriod.endYear}): Start Year must be positive.`
                });
            }
            if (inflationPeriod.endYear <= 0) {
                errorObjects.push({
                    name: `inflationPeriods[${i}].endYear`,
                    message: `Inflation (Years ${inflationPeriod.startYear} - ${inflationPeriod.endYear}): End Year must be positive.`
                });
            }
            if (inflationPeriod.assumedInflation < -100) {
                errorObjects.push({
                    name: `inflationPeriods[${i}].costOfLivingAdjustment`,
                    message: `Inflation (Years ${inflationPeriod.startYear} - ${inflationPeriod.endYear}): Assumed Inflation must be greater than -100%.`
                });
            }
            if (inflationPeriod.costOfLivingAdjustment > 100) {
                errorObjects.push({
                    name: `inflationPeriods[${i}].costOfLivingAdjustment`,
                    message: `Inflation (Years ${inflationPeriod.startYear} - ${inflationPeriod.endYear}): Assumed Inflation must be less than 100%.`
                });
            }
            if (inflationPeriod.startYear > form.numberOfYears) {
                errorObjects.push({
                    name: `inflationPeriods[${i}].startYear`,
                    message: `Inflation (Years ${inflationPeriod.startYear} - ${inflationPeriod.endYear}): Start Year cannot be outside of the report year range.`
                });
            }
            if (inflationPeriod.endYear > form.numberOfYears) {
                errorObjects.push({
                    name: `inflationPeriods[${i}].endYear`,
                    message: `Inflation (Years ${inflationPeriod.startYear} - ${inflationPeriod.endYear}): End Year cannot be outside of the report year range.`
                });
            }
        })

        return {
            errors: errorObjects,
            incomeOverlapErrorIndices: incomeOverlapErrorIndices,
            inflationPeriodOverlapErrorIndices: inflationPeriodOverlapErrorIndices
        }
    }

    const range = (start, end) => {
        start = Number(start);
        end = Number(end);
        if (start > end) {
            let temp = start;
            start = end;
            end = temp;
        }
        return Array(end - start + 1).fill().map((_, idx) => start + idx)
    }

    const intersection = (array1, array2) => {
        return array1.filter(value => array2.includes(value));
    }

    const checkForOverlap = (items) => {
        let errorIndices = [];
        items.forEach((item, i) => {
            const itemYearRange = range(item.startYear, item.endYear);
            for (let j = i + 1; j < items.length; j++) {
                const otherItemYearRange = range(items[j].startYear, items[j].endYear);
                if (intersection(itemYearRange, otherItemYearRange).length > 0) {
                    errorIndices.push(i);
                    errorIndices.push(j);
                }
            }
        });
        return errorIndices;
    }
    const backToEditMethod = () => {
        if (settings.isEdit && !viewModel) navigate(-1);
        settings.isEdit
            ? navigate(`/v1/consumers/${viewModel.client.id}`)
            : navigate(`/v1/consumers/${params.id}`);
    }
        return (
            <div className="justify-center place-content-center w-3/4 mt-6 min-h-full">
                <div className="grid grid-cols-4">
                    <div className="block col-span-3">
                        <BackButton text="Back to Client Profile" onClick={backToEditMethod} />
                        {isLoading
                            ? <h3><Skeleton /></h3>
                            : <h3>Inflation Report for {viewModel?.names}</h3>
                        }
                    </div>
                    <div className="w-full flex justify-end items-center pr-4">
                        <img alt="Inflation Logo" src={staticData.getReport(3).imgSrc} className="w-16 h-16"></img>
                    </div>
                </div>
                    <div className="grid grid-cols-2">
                        <div className="mr-2" style={{ marginBottom: '5%' }}>
                            <FormProvider {...formMethods}>
                                <form autoComplete="off">
                                    <div className="">
                                        <Input
                                            type="text"
                                            name="reportName"
                                            label={"Report Name"}
                                            onChange={(e) => updateFormDataItem(e.target, false, true)}
                                            containerClassNames={"py-3"}
                                            hasValidationError={hasFieldError("reportName")}
                                            autoFocus={true}
                                        />

                                        <div className="bg-slate-200 mt-6 p-4">
                                            <div className="grid">
                                                <div className="flex justify-between">
                                                    <span className="font-bold">Scenario</span>
                                                </div>
                                                <span className="text-sm">Enter the basic details of the plan</span>
                                                <Input
                                                    type="number"
                                                    step="1"
                                                    name="numberOfYears"
                                                label={"Number Of Years"}
                                                onInput={(e) => {
                                                    e.target.value = Math.round(e.target.value);
                                                }
                                                }
                                                    onChange={(e) => updateFormDataItem(e.target)}
                                                    conditionalLabel={`(Maximum of ${globalSettings.inflationMaxLedgerLength} years per page)`}
                                                    showConditionalLabel={formMethods.getValues("numberOfYears") > globalSettings.inflationMaxLedgerLength}
                                                    containerClassNames={"py-3"}
                                                    hasValidationError={hasFieldError("numberOfYears")}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {(formMethods.getValues("inflationPeriods") ?? []).map((_, i) => (
                                        <InflationPeriod
                                            key={i}
                                            index={i}
                                            onUpdate={updateFormDataItem}
                                            onRemove={removeInflationPeriod}
                                            hasAgeOverlapError={formErrors.inflationPeriodOverlapErrorIndices.includes(i)}
                                            fieldErrors={getInflationPeriodErrors()}
                                        />
                                    ))}

                                    <span tabIndex={0} onClick={addInflationPeriod} onKeyUp={(e) => e.key === "Enter" && addInflationPeriod()} className="flex justify-center items-center text-core-orange-400 font-bold hover:underline cursor-pointer mt-2">
                                        <PlusCircleIcon className="h-6 mb-1 mr-1" />
                                        <span>Add Another Inflation Period</span>
                                    </span>

                                    {(formMethods.getValues("annualIncomes") ?? []).map((_, i) => (
                                        <AnnualIncome
                                            key={i}
                                            index={i}
                                            onUpdate={updateFormDataItem}
                                            onRemove={removeIncome}
                                            hasAgeOverlapError={formErrors.incomeOverlapErrorIndices.includes(i)}
                                            fieldErrors={getAnnualIncomeErrors()}
                                        />
                                    ))}

                                    <span tabIndex={0} onClick={addIncome} onKeyUp={(e) => e.key === "Enter" && addIncome()} className="flex justify-center items-center text-core-orange-400 font-bold hover:underline cursor-pointer mt-2">
                                        <PlusCircleIcon className="h-6 mb-1 mr-1" />
                                        <span>Add Another Annual Income Period</span>
                                    </span>

                                    <div className="flex justify-end mt-4">
                                        <Button clickHandler={formMethods.handleSubmit((data) => onSubmit(data))} text="Save and Preview" classNames="bg-core-orange-400 ml-1" />
                                    </div>
                                </form>
                            </FormProvider>
                        </div>

                        <div className="ml-2">
                            <div className="flex justify-center">
                                <span className="font-bold">Report Preview</span>
                            </div>
                            <div className={`${formErrors.errors.length > 0 ? "blur-children" : ""} bg-white min-h-max ml-2 mb-4 relative`}>
                                {formErrors.errors.length > 0 && (
                                    <div className="shadow-md rounded-lg preview-error-container unblur">
                                        <div className="p-4">
                                            <div className="flex items-center">
                                                <ExclamationCircleIcon className="w-7 h-7 text-red-500"></ExclamationCircleIcon>
                                                <span className="ml-2 font-bold">Form Errors</span>
                                            </div>
                                            <div className="block">
                                                <div>Resolve the errors to generate a preview</div>
                                                <ul className="ml-4">
                                                    {formErrors.errors.map((error, i) => (
                                                        <li key={i} className="text-sm list-disc">{error.message}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="p-4">
                                    <div className="flex justify-between header-container">
                                        <div className='preview-title'>
                                            <span className="font-bold">Inflation</span>
                                            <br />
                                        </div>
                                    </div>
                                    {viewModel && calculationResults?.ledger && <ProjectionTable ledger={calculationResults.ledger} viewModel={viewModel} />}
                                </div>
                            </div>
                        </div>
                    </div>

                <ToastContainer />
            </div>
        )
    }
