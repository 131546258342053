import React from 'react'
import PropTypes from 'prop-types';
import Input from '../../../../inputs/Input';
import { XIcon } from'@heroicons/react/outline';
import utils from "../../../../shared/utils";

AnnualIncome.propTypes = {
    onUpdate: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    hasAgeOverlapError: PropTypes.bool.isRequired,
    fieldErrors: PropTypes.array.isRequired,
    autoFocusStartYear: PropTypes.bool
}
  
AnnualIncome.defaultProps = {

};

export default function AnnualIncome(props) {

    const hasFieldError = (name) => {
        return props.fieldErrors.filter(error => error.name === name).length > 0;
    }

  return (
    <div className="bg-slate-200 mt-6 p-4">
        <div className="grid">
            <div className="flex justify-between">
                <span className="font-bold">Annual Income</span>
                {props.index > 0 && (
                    <XIcon onClick={() => props.onRemove(props.index)} className="h-6 cursor-pointer" />
                )}
            </div>
            <span className="text-sm">Enter the income received each year, and if the COLA is applied</span>
        </div>
        <div className="grid grid-cols-2">
            <div className="col-span-1 flex">
                <Input
                    type="number"
                    name={`annualIncomes[${props.index}].startYear`}
                      label={"Starting Year"}
                      onInput={(e) => {
                          e.target.value = Math.round(e.target.value);
                      }
                      }
                    containerClassNames="pr-2 py-3"
                    onChange={(e) => props.onUpdate(e.target)}
                    hasValidationError={hasFieldError(`annualIncomes[${props.index}].startYear`) || hasFieldError(`annualIncomes[${props.index}].years`) || props.hasAgeOverlapError}
                    autoFocus={props.autoFocusStartYear}
                />
                <Input
                    type="number"
                    name={`annualIncomes[${props.index}].endYear`}
                      label={"Ending Year"}
                      onInput={(e) => {
                          e.target.value = Math.round(e.target.value);
                      }
                      }
                    containerClassNames="pl-2 py-3"
                    onChange={(e) => props.onUpdate(e.target)}
                    hasValidationError={hasFieldError(`annualIncomes[${props.index}].endYear`) || hasFieldError(`annualIncomes[${props.index}].years`) || props.hasAgeOverlapError}
                />
            </div>
            <div className="col-span-1"></div>
            <div className="col-span-1">
                <Input
                    type="number"
                    name={`annualIncomes[${props.index}].annualIncome`}
                      label={"Annual Income Amount"}
                      symbol="$"
                      onInput={(e) => {
                          e.target.value = Math.round(e.target.value);
                      }
                      }
                    containerClassNames="pr-2 py-3"
                    onChange={(e) => props.onUpdate(e.target)}
                    hasValidationError={hasFieldError(`annualIncomes[${props.index}].annualIncome`)}
                />
            </div>
            <div className="col-span-1">
                <Input
                    type="number"
                    name={`annualIncomes[${props.index}].costOfLivingAdjustment`}
                    label={"Cost of Living Adjustment"}
                    symbol="%"
                    subText="Apply COLA to your annual income"
                      onInput={(e) => {
                          utils.setDecimalPlaces(e);
                      }}
                    optional={true}
                    containerClassNames="pl-2 py-3"
                    onChange={(e) => props.onUpdate(e.target)}
                    hasValidationError={hasFieldError(`annualIncomes[${props.index}].costOfLivingAdjustment`)}
                />
            </div>
        </div>
    </div>
    )
}
