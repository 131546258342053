import React from 'react'
import Button from '../../../../inputs/Button'
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import reportHelper from '../../../../shared/reportHelper';
import staticData from '../../../../shared/staticData';

PreviewButtons.propTypes = {
  reportId: PropTypes.number,
  viewModel: PropTypes.object,
  reportType: PropTypes.number.isRequired,
  showBackToEditButton: PropTypes.bool,
  backToEditMethod: PropTypes.func
}

PreviewButtons.defaultProps = {
  viewModel: {},
  showBackToEditButton: true,
  backToEditMethod: undefined
}

export default function PreviewButtons(props) {

  const navigate = useNavigate();
  const { route: reportRoute } = staticData.getReport(props.reportType); 
  const finalizeMethod = () => {
    return props.reportType === 1 
      ? reportHelper.finalizeLongevity(props.viewModel, toast, navigate, reportRoute, true)
      : reportHelper.finalizeReport(props.reportId, toast, navigate, reportRoute, true);
  }

  return (
    <div className="flex justify-end mt-4 mb-4">
      {props.showBackToEditButton && (
        <Button text="Back to Report Builder" clickHandler={props.backToEditMethod} classNames="bg-core-orange-300 hover:bg-core-orange-400 mx-1" />
      )}
      <Button text="Finalize and View PDF" clickHandler={finalizeMethod} classNames="bg-core-orange-400 mx-1" />
    </div>
  )
}
