import React, { useEffect, useState, useCallback } from "react";
import { useQuery } from 'react-query';
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { useTheme } from '../../../contexts/ThemeContext';
import BackButton from "../../../components/BackButton";
import Skeleton from 'react-loading-skeleton';
import staticData from '../../../shared/staticData';
import dataCalls from "../../../shared/dataCalls";
import remoteCalculations from "../../../shared/remoteCalculations";
import globalSettings from "../../../shared/settings";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { PlusCircleIcon, ExclamationCircleIcon } from "@heroicons/react/outline";
import utils from "../../../shared/utils";
import ProjectionTable from "./Components/ProjectionTable";
import Input from "../../../inputs/Input";
import TextArea from "../../../inputs/TextArea";
import Button from "../../../inputs/Button";
import Select from "../../../inputs/Select";
import "../../../styles/reports/shared/shared.css";
import "../../../styles/reports/sequenceofreturns/preview.css";
import SequenceDiamond from "./Components/SequenceDiamond";
import Distribution from "./Components/Distribution";

export default function Edit() {

    const settings = {
        isEdit: window.location.pathname.includes("edit")
    };

    const presetOptions = [
        ["sixtyForty", "60/40 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (6% / 14%)"],
        ["bonds", "Bonds &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (3% / &nbsp;&nbsp;5%)"],
        ["equities", "Equities       &nbsp;&nbsp; (8% / 20%)"],
        ["custom", "Custom"]
    ];

    const [ calculationResults, setCalculationResults ] = useState({});
    const [ triggerCalculationUpdate, setTriggerCalculationUpdate ] = useState(false);
    const [ formErrors, setFormErrors ] = useState({
        errors: [],
        distributionOverlapErrorIndeces: []
    });

    const formMethods = useForm();

    const params = useParams();
    const navigate = useNavigate();

    const fetchMethod = settings.isEdit
        ? () => dataCalls.fetchSequenceOfReturnsViewModelEdit(params.id)
        : () => dataCalls.fetchSequenceOfReturnsViewModelCreate(params.id, params.spouseid);

    const { data: viewModel, isLoading, refetch } = useQuery('vm', fetchMethod, {
        refetchOnMount: true,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: Infinity
    });

    useEffect(() => {
        if(settings.isEdit) {
            refetch();
        }
    }, [params.id])

    const { setBackgroundClass, setJustifyClass, resetTheme } = useTheme();
    useEffect(() => {
        setBackgroundClass("bg-gray");
        setJustifyClass("");
        return () => resetTheme(); 
    }, []);

    //Set initial form state on viewmodel load
    useEffect(() => {
        if(viewModel && (settings.isEdit && viewModel.report || !settings.isEdit)) {
            formMethods.setValue("reportName", viewModel.report.reportName);
            formMethods.reset(settings.isEdit 
                ? viewModel.report.reportData
                : viewModel.defaultReportData);
            if(isValid()) setTriggerCalculationUpdate(true);
        }
    }, [viewModel])

    const updateCalculations = useCallback(async () => {
        if(triggerCalculationUpdate && viewModel) {
            const data = formMethods.getValues();
            const scenario = {
                clientAge: viewModel.client.age,
                spouseAge: viewModel.spouse?.age ?? 0,
                reportData: JSON.parse(JSON.stringify(data))
            };;
            const results = await remoteCalculations.getSequenceOfReturnsLedger(scenario);
            setCalculationResults(results);
        }
        setTriggerCalculationUpdate(false);
    }, [triggerCalculationUpdate]);

    useEffect(() => {
        updateCalculations();
    }, [updateCalculations]);

    const updateFormDataItem = ({ name, value }, updateCalculations = true, validateBasicInputs = false) => {
        formMethods.setValue(name, value);
        updateFieldsBasedOnPresets(name, value);
        if (isValid(validateBasicInputs) && updateCalculations) setTriggerCalculationUpdate(true);
    };

    const updateFieldsBasedOnPresets = (name, value) => {
        if (name === 'presetType') {
            let presets = {
                sixtyForty: [6, 14],
                bonds: [3, 5],
                equities: [8, 20],
                custom: [0, 0]
            };
            formMethods.setValue("expectedGrowthRate", presets[value][0]);
            formMethods.setValue("expectedVolatility", presets[value][1]);
        }
    };

    const onSubmit = async (data) => {
        await handleEditSubmit(viewModel, data, toast, navigate);
    };

    const handleEditSubmit = async (viewModel, data, toast, navigate) => {
        if(!isValid(true)) {
            toast.error("Correct the form errors before continuing.", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            return false;
        }

        let report = viewModel.report;
        report.reportName = data.reportName;
        delete data.reportName;
        report.reportData = data;

        //Save and Preview
        const toastId = toast.loading("Saving report...", {
            position: toast.POSITION.BOTTOM_CENTER
        });
        const response = await dataCalls.saveDraft(report);
        if(response.success) {
            toast.update(toastId, { render: "Save successful", type: "success", isLoading: false });
            setTimeout(() => navigate(`/v1/reports/sequenceofreturns/preview/${response.data}`), 1000);
        } else {
            toast.update(toastId, { render: "An error occurred...", type: "error", isLoading: false });
        }
        setTimeout(() => toast.dismiss(toastId.current), 1000);
        return response.success;
    }

    const isValid = (includeBasicInputs = false) => {
        const data = formMethods.getValues();
        const validationErrors = buildValidationErrors(data, includeBasicInputs);
        setFormErrors(validationErrors);
        return validationErrors.errors.length === 0 && isScenarioComplete(data);
    }

    const hasFieldError = (fieldName) => {
        return formErrors.errors.filter(e => e.name === fieldName).length > 0;
    }

    const isScenarioComplete = (data) => {
        return data.numberOfYears > 0;
    }

    const buildValidationErrors = (data, includeBasicInputs = false) => {
        let errorObjects = [];

        if(includeBasicInputs) {
            if(data.reportName?.length === 0) {
                errorObjects.push({
                    name: "reportName",
                    message: "Report must be named."
                });
            }

            if(data.notes?.length > 500) {
                errorObjects.push({
                    name: "notes",
                    message: "Notes length must be less than or equal to 500."
                });
            }
        }

        if(data.numberOfYears < 1) {
            errorObjects.push({
                name: "numberOfYears",
                message: "Number of years must be greater than or equal to 1."
            });
        }
        if(data.numberOfYears > 100) {
            errorObjects.push({
                name: "numberOfYears",
                message: "Number of years must be less than or equal to 100."
            });
        }
        if(data.numberOfAccumulationYears < 0) {
            errorObjects.push({
                name: "numberOfAccumulationYears",
                message: "Number of accumulation years must be greater than or equal to 1."
            });
        }
        if(data.numberOfAccumulationYears > 100) {
            errorObjects.push({
                name: "numberOfAccumulationYears",
                message: "Number of accumulation years must be less than or equal to 100."
            });
        }
        if(data.startingBalance > 1000000000) {
            errorObjects.push({
                name: "startingBalance",
                message: "Staring Balance must be less than or equal to $1,000,000,000."
            });
        }
        if(data.startingBalance < -1000000000) {
            errorObjects.push({
                name: "startingBalance",
                message: "Staring Balance must be greater than or equal to -$1,000,000,000."
            });
        }
        if(data.expectedGrowthRate > 100) {
            errorObjects.push({
                name: "expectedGrowthRate",
                message: "Expected Growth Rate must be less than 100%."
            });
        }
        if(data.expectedGrowthRate < -100) {
            errorObjects.push({
                name: "expectedGrowthRate",
                message: "Expected Growth Rate must be greater must than -100%."
            });
        }
        if(data.expectedVolatility > 100) {
            errorObjects.push({
                name: "expectedVolatility",
                message: "Expected Volatility must be less than 100%."
            });
        }
        if(data.expectedVolatility < -100) {
            errorObjects.push({
                name: "expectedVolatility",
                message: "Expected Volatility must be greater must than -100%."
            });
        }

        if(Number(data.numberOfYears) < Number(data.numberOfAccumulationYears)) {
            errorObjects.push({
                name:"numberOfAccumulationYears",
                message: "Number of Accumulation Years must be less than or equal to the scenario Number of Years."
            })
        }

        data.distributions.forEach((distribution, i) => {
            distribution.startYear = Number(distribution.startYear);
            distribution.endYear = Number(distribution.endYear);

            if(distribution.startYear > distribution.endYear) {
                errorObjects.push({
                    name: `distributions[${i}].years`,
                    message: `Distribution (Years ${distribution.startYear} - ${distribution.endYear}): Start Year must be less than or equal to End Year.`
                });
            }
            if(distribution.startYear <= 0) {
                errorObjects.push({
                    name: `distributions[${i}].startYear`,
                    message: `Distribution (Years ${distribution.startYear} - ${distribution.endYear}): Start Year must be positive.`
                });
            }
            if(distribution.endYear <= 0) {
                errorObjects.push({
                    name: `distributions[${i}].endYear`,
                    message: `Distribution (Years ${distribution.startYear} - ${distribution.endYear}): End Year must be positive.`
                });
            }
            if(distribution.costOfLivingAdjustment < -100) {
                errorObjects.push({
                    name: `distributions[${i}].costOfLivingAdjustment`,
                    message: `Distribution (Years ${distribution.startYear} - ${distribution.endYear}): Distribution Amount Growth Rate must be greater than -100%.`
                });
            }
            if(distribution.costOfLivingAdjustment > 100) {
                errorObjects.push({
                    name: `distributions[${i}].costOfLivingAdjustment`,
                    message: `Distribution (Years ${distribution.startYear} - ${distribution.endYear}): Distribution Amount Growth Rate must be less than 100%.`
                });
            }
            if(distribution.startYear > data.numberOfYears) {
                errorObjects.push({
                    name: `distributions[${i}].startYear`,
                    message: `Distribution (Years ${distribution.startYear} - ${distribution.endYear}): Start Year cannot be outside of the report year range.`
                });
            }
            if(distribution.endYear > data.numberOfYears) {
                errorObjects.push({
                    name: `distributions[${i}].endYear`,
                    message: `Distribution (Years ${distribution.startYear} - ${distribution.endYear}): End Year cannot be outside of the report year range.`
                });
            }
            if(distribution.annualIncome > 1000000000) {
                errorObjects.push({
                    name: `distributions[${i}].annualIncome`,
                    message: "Staring Balance must be less than or equal to $1,000,000,000."
                });
            }
            if(distribution.annualIncome < -1000000000) {
                errorObjects.push({
                    name: `distributions[${i}].annualIncome`,
                    message: "Staring Balance must be greater than or equal to -$1,000,000,000."
                });
            }

            if(distribution.startYear < data.numberOfAccumulationYears) {
                errorObjects.push({
                    name: `distributions[${i}].startYear`,
                    message: `Distribution (Years ${distribution.startYear} - ${distribution.endYear}): Distribution cannot start before the accumulation period has ended.` 
                });
            }

            if(distribution.endYear < data.numberOfAccumulationYears) {
                errorObjects.push({
                    name: `distributions[${i}].startYear`,
                    message: `Distribution (Years ${distribution.startYear} - ${distribution.endYear}): Distribution cannot end before the accumulation period has ended.` 
                });
            }
        })

        let distributionOverlapErrorIndeces = checkForOverlap(data.distributions);
        if(distributionOverlapErrorIndeces.length > 0) {
            errorObjects.push({
                name: `distributions`,
                message: "Distribution years cannot overlap."
            });
        }

        return {
            errors: errorObjects,
            distributionOverlapErrorIndeces: distributionOverlapErrorIndeces
        }
    }

    const backToEditMethod = () => {
        if (settings.isEdit && !viewModel) navigate(-1);
        settings.isEdit
            ? navigate(`/v1/consumers/${viewModel.client.id}`)
            : navigate(`/v1/consumers/${params.id}`);
    }

    const addDistribution = () => {
        let formData = formMethods.getValues();
        let formDataClone = JSON.parse(JSON.stringify(formData));
        let lastEntryEndYear = Number(formDataClone.distributions[formDataClone.distributions.length - 1].endYear);
        let startYear = lastEntryEndYear === formDataClone.numberOfYears ? formDataClone.numberOfYears : lastEntryEndYear + 1;
        let endYear = formDataClone.numberOfYears;
        formDataClone.distributions = [...formDataClone.distributions, {
            startYear: startYear,
            endYear: endYear,
            annualIncome: 0,
            costOfLivingAdjustment: 0,
            reduceByAnnualIncome: true
        }];
        formMethods.reset(formDataClone);
        if(isValid()) setTriggerCalculationUpdate(true);

        const distributionsLength = formDataClone.distributions.length;
        setTimeout(() => formMethods.setFocus(`distributions[${distributionsLength - 1}].startYear`), 1); 
    }

    const removeDistribution = (index) => {
        let formData = formMethods.getValues();
        let formDataClone = JSON.parse(JSON.stringify(formData));
        formDataClone.distributions = formDataClone.distributions.filter((item, i) => i !== index);
        formMethods.reset(formDataClone);
        if(isValid()) setTriggerCalculationUpdate(true);
    }

    const getDistributionErrors = () => {
        return formErrors.errors.filter(error => error.name.includes("distribution"));
    }

    const updateDistributionStart = ({value}) => {
        let formData = formMethods.getValues();
        let formDataClone = JSON.parse(JSON.stringify(formData));
        formDataClone.distributions[0].startYear = Number(value) + 1;
        if(formDataClone.distributions[0].startYear > formDataClone.distributions[0].endYear) {
            formDataClone.distributions[0].endYear += 1;
        }
        formMethods.reset(formDataClone);
    }

    const checkForOverlap = (items) => {
        function range(start, end) {
            start = Number(start);
            end = Number(end);
            if(start > end) {
                let temp = start;
                start = end;
                end = temp;
            }
            return Array(end - start + 1).fill().map((_, idx) => start + idx)
        }
          
        function intersection(array1, array2) {
            return array1.filter(value => array2.includes(value));
        }

        let errorIndeces = [];
        items.forEach((item, i) => {
            const itemYearRange = range(item.startYear, item.endYear);
            for (let j = i + 1; j < items.length; j++) {
                const otherItemYearRange = range(items[j].startYear, items[j].endYear);
                if(intersection(itemYearRange, otherItemYearRange).length > 0) {
                    errorIndeces.push(i);
                    errorIndeces.push(j);
                }
            }
        });
        return errorIndeces;
    }

    return (
    <div className="justify-center place-content-center w-3/4 mt-6 min-h-full">
        <div className="grid grid-cols-4">
            <div className="block col-span-3">
                    <BackButton text="Back to Client Profile" onClick={backToEditMethod} />
                {isLoading
                    ? <h3><Skeleton /></h3>
                    : <h3>Sequence Of Returns Report for {viewModel?.names}</h3>
                }
            </div>
            <div className="w-full flex justify-end items-center pr-4">
                <img alt="Sequence of Returns Logo" src={staticData.getReport(6).imgSrc} className="w-16 h-16"></img>
            </div>
        </div>

            <div className="grid grid-cols-2">
                    <div className="mr-2" style={{marginBottom: '5%'}}>
                    <FormProvider {...formMethods}>
                        <form autoComplete="off">
                            <div className="">
                                <Input
                                    type="text"
                                    name="reportName"
                                    label={"Report Name"}
                                    onChange={(e) => updateFormDataItem(e.target, false, true)}
                                    containerClassNames={"py-3"}
                                    hasValidationError={hasFieldError("reportName")}
                                    autoFocus={true}
                                />

                                <div className="bg-slate-200 mt-6 p-4">
                                    <div className="grid">
                                        <div className="flex justify-between">
                                            <span className="font-bold">Scenario</span>
                                        </div>
                                        <span className="text-sm">Enter the basic details of the plan</span>
                                        <Input
                                            type="number"
                                            onInput={(e) => {
                                                e.target.value = Math.round(e.target.value);
                                                }
                                            }
                                            name="numberOfYears"
                                            label={"Number Of Years"}
                                            onChange={(e) => updateFormDataItem(e.target)}
                                            conditionalLabel={`(Maximum of ${globalSettings.sorMaxLedgerLength} years per page)`}
                                            showConditionalLabel={formMethods.getValues("numberOfYears") > globalSettings.sorMaxLedgerLength}
                                            containerClassNames={"py-3"}
                                            hasValidationError={hasFieldError("numberOfYears")}
                                        />
                                        <div className="flex">
                                        <Input
                                            type="number"
                                            name="startingBalance"
                                            label={"Starting Balance"}
                                            symbol="$"
                                                onInput={(e) => {
                                                    e.target.value = Math.round(e.target.value);
                                                }
                                                }
                                            onChange={(e) => updateFormDataItem(e.target)}
                                            containerClassNames={"py-3 mr-1 w-1/2"}
                                            hasValidationError={hasFieldError("startingBalance")}
                                        />
                                        <div className="w-1/2">
                                            <Select
                                                name="presetType"
                                                labelText="Growth Rate / Volatility Presets"
                                                handleOnChange={(e) => updateFormDataItem(e.target)}
                                                options={presetOptions}
                                            />
                                        </div>
                                        </div>
                                        <div className="flex">
                                            <Input
                                                type="number"
                                                name="expectedGrowthRate"
                                                label={"Expected Growth Rate"}
                                                symbol="%"
                                                onInput={(e) => {
                                                    utils.setDecimalPlaces(e);
                                                }}
                                                onChange={(e) => updateFormDataItem(e.target)}
                                                containerClassNames={"py-3 mr-1 w-1/2"}
                                                hasValidationError={hasFieldError("expectedGrowthRate")}
                                                disabled={formMethods.watch("presetType") !== "custom"}
                                            />
                                            <Input
                                                type="number"
                                                name="expectedVolatility"
                                                label={"Expected Volatility"}
                                                symbol="%"
                                                onInput={(e) => {
                                                    utils.setDecimalPlaces(e);
                                                }}
                                                onChange={(e) => updateFormDataItem(e.target)}
                                                containerClassNames={"py-3 ml-1 w-1/2"}
                                                hasValidationError={hasFieldError("expectedVolatility")}
                                                disabled={formMethods.watch("presetType") !== "custom"}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-slate-200 mt-6 p-4">
                                    <div className="grid">
                                        <div className="flex justify-between">
                                            <span className="font-bold">Accumulation</span>
                                        </div>
                                        <span className="text-sm">Enter the number of accumulation years</span>
                                        <div className="flex">
                                            <Input
                                                type="number"
                                                onInput={(e) => {
                                                        e.target.value = Math.round(e.target.value);
                                                        e.target.selectionStart = e.target.selectionEnd = 10000;
                                                    }
                                                }
                                                name="numberOfAccumulationYears"
                                                label={"Number Of Accumulation Years"}
                                                onChange={(e) => {
                                                        updateDistributionStart(e.target);
                                                        updateFormDataItem(e.target);
                                                    }
                                                }
                                                containerClassNames={"py-3 mr-1 w-1/2"}
                                                hasValidationError={hasFieldError("numberOfAccumulationYears")}
                                            />
                                            <Input
                                                type="range"
                                                step="1"
                                                name="numberOfAccumulationYears"
                                                label={"Number Of Accumulation Years"}
                                                onInput={(e) => {
                                                    e.target.value = Math.round(e.target.value);
                                                }
                                                }
                                                onChange={(e) => {
                                                        updateDistributionStart(e.target);
                                                        updateFormDataItem(e.target);
                                                    }
                                                }
                                                containerClassNames={"py-3 ml-1 w-1/2"}
                                                hasValidationError={hasFieldError("numberOfAccumulationYears")}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {(formMethods.getValues("distributions") ?? []).map((_, i) => (
                                    <Distribution 
                                        key={i} 
                                        index={i} 
                                        onUpdate={updateFormDataItem} 
                                        onRemove={removeDistribution} 
                                        hasAgeOverlapError={formErrors.distributionOverlapErrorIndeces.includes(i)}
                                        fieldErrors={getDistributionErrors()}
                                    />
                                ))}
                                
                                <span tabIndex={0} onClick={addDistribution} onKeyUp={(e) => e.key === "Enter" && addDistribution()} className="flex justify-center items-center text-core-orange-400 font-bold hover:underline cursor-pointer mt-2">
                                    <PlusCircleIcon className="h-6 mb-1 mr-1" />
                                    <span>Add Another Distribution</span>
                                </span>

                                <div className="mt-6">
                                    <TextArea
                                        name="notes"
                                        label="Notes"
                                        rows={6}
                                        optional={true}
                                        maxCharacterCount={500}
                                        hasValidationError={hasFieldError("notes")}
                                    />
                                </div>
                            </div>

                            <div className="flex justify-end mt-4">
                                <Button clickHandler={formMethods.handleSubmit((data) => onSubmit(data))} text="Save and Preview" classNames="bg-core-orange-400 ml-1"/>
                            </div>
                        </form>
                    </FormProvider>
                </div>

                <div className="ml-2">
                    <div className="flex justify-center">
                        <span className="font-bold">Report Preview</span>
                    </div>
                    <div className={`${formErrors.errors.length > 0 ? "blur-children" : ""} bg-white min-h-max ml-2 mb-4 relative`}>
                        {formErrors.errors.length > 0 && (
                            <div className="shadow-md rounded-lg preview-error-container unblur">
                                <div className="p-4">
                                    <div className="flex items-center">
                                        <ExclamationCircleIcon className="w-7 h-7 text-red-500"></ExclamationCircleIcon>
                                        <span className="ml-2 font-bold">Form Errors</span>
                                    </div>
                                    <div className="block">
                                        <div>Resolve the errors to generate a preview</div>
                                        <ul className="ml-4">
                                            {formErrors.errors.map((error, i) => (
                                                <li key={i} className="text-sm list-disc">{error.message}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="p-4">
                            <div className="flex justify-between header-container">
                                    <div className='preview-title'>
                                        <span className="font-bold">Sequence of Returns</span>
                                        <br />
                                        <span className="mt-4">Starting Balance: {utils.formatAsCurrency(formMethods.getValues("startingBalance") ?? 0, 0)}</span>
                                    </div>
                                    {calculationResults && (
                                        <div className={`flex justify-between text-sm diamond-edit-container ${ isLoading ? '' : viewModel?.spouse != null ? 'has-spouse' : ''}`}>
                                        <SequenceDiamond sequenceType="Up" growthRates={calculationResults.upSequenceGrowthRates} colorHex="var(--sor-color-one-dark)" />
                                        <SequenceDiamond sequenceType="Average" growthRates={calculationResults.averageSequenceGrowthRates} colorHex="var(--sor-color-two-dark)" />
                                        <SequenceDiamond sequenceType="Down" growthRates={calculationResults.downSequenceGrowthRates} colorHex="var(--sor-color-three-dark)" />
                                    </div>
                                )}
                            </div>
                            {viewModel && calculationResults?.ledger && <ProjectionTable ledger={calculationResults.ledger} viewModel={viewModel} />}
                        </div>
                    </div>
                </div>
            </div>

        <ToastContainer />
    </div>
  )
}
