import React from 'react'
import PropTypes from 'prop-types'
import { ResponsiveContainer, LineChart, Line, CartesianGrid, XAxis, YAxis, Label, Tooltip } from 'recharts';
import utils from '../../../../shared/utils';
import CircleWithWedge from '../../../../components/CircleWithWedge';
import coreColors from '../../../../shared/coreColors';

InternalRateOfReturnChart.propTypes = {
    viewModel: PropTypes.object.isRequired,
    ledger: PropTypes.array.isRequired
}
  
InternalRateOfReturnChart.defaultProps = {
    
};

export default function InternalRateOfReturnChart(props) {

    const getLifeExpectancyData = (ledgerEntry, viewModel) => {
        let data = {
            dotColor: "white",
            pieColor: "white",
            percentile: 0
        };

        if(!viewModel) {
            return data;
        }

        //Use age of the younger client
        let ledgerAge = ledgerEntry?.clientAge ?? 0;
        if(viewModel.spouse?.age < viewModel.client.age) {
            ledgerAge = ledgerEntry?.spouseAge ?? 0;
        }

        if(viewModel.lifeExpectancies[50] === ledgerAge) {
            data.pieColor = coreColors.coreOrange200;
            data.pieColorSecondary = coreColors.coreOrange100;
            data.dotColor = coreColors.coreOrange400;
            data.percentile = 50;
        }
        if(viewModel.lifeExpectancies[25] === ledgerAge) {
            data.pieColor = coreColors.coreBlue200;
            data.pieColorSecondary = coreColors.coreBlue100;
            data.dotColor = coreColors.coreBlue400;
            data.percentile = 25;
        }
        if(viewModel.lifeExpectancies[10] === ledgerAge) {
            data.pieColor = coreColors.corePurple200;
            data.pieColorSecondary = coreColors.corePurple100;
            data.dotColor = coreColors.corePurple400;
            data.percentile = 10;
        }
        return data;
    }

    const chartData = props.ledger.map(p => {
        const isJoint = p.spouseAge > 0;
        const age = isJoint ? `${p.clientAge} | ${p.spouseAge}` : `${p.clientAge}`;
        const { pieColor, pieColorSecondary, dotColor, percentile }  = getLifeExpectancyData(p, props.viewModel);
        const isLifeExpectancyYear = percentile > 0;
        return { 
            x: age,
            y: p.internalRateOfReturn,
            isLifeExpectancyYear: isLifeExpectancyYear,
            dotColorHex: dotColor,
            pieColor: pieColor,
            pieColorSecondary: pieColorSecondary,
            isJoint: isJoint,
            percentile: percentile
         };
    });

    const xAxisTicks = chartData.map(cd => cd.x).filter((_, i) => {
        //Create ticks at each 5 year interval
        const currentYear = i + 1;
        return currentYear % 5 === 0;
    });

    const yAxisTicks = chartData.map(cd => cd.y).filter(y => y < 0).length > 0
            ? [-.10, -.05, 0, .05, .10 ]
            : [0, .05, .10 ];

    const yAxisDomain = chartData.map(cd => cd.y).filter(y => y < 0).length > 0
        ? [-.10, .10]
        : [0, .10];

    const chartSettings = {
        xAxisLabel: "Years",
        xAxisTicks: xAxisTicks,
        lineAndPointColor: "#3e51b5",
        tooltipFormatter: () => {
            return [null, null];
        }, 
        tooltipLabelFormatter: (label, propsArray) => {
            if(propsArray.length === 0) {
                return;
            }
            const payload = propsArray[0].payload;
            let ages = String(label).split("|");
            let clientCurrentAge = ages[0].trimEnd();
            return (
                <div className="grid">
                    <span>Year: {clientCurrentAge - props.viewModel?.client?.age + 1}</span>
                    <span>{props.viewModel.client.firstName}'s Age: {ages[0].trimEnd()}</span>
                    <span>{ages.length === 2 ? `${props.viewModel.spouse.firstName}'s Age: ${ages[1]}` : ``}</span>
                    <span>IRR: {(payload.y * 100).toFixed(2)}%</span>
                    {payload.isLifeExpectancyYear && (
                        <span className="flex">
                            <br/>
                            <CircleWithWedge wedgeStartingAngle={100 - payload.percentile} text={props.viewModel.lifeExpectancies[payload.percentile]} heightStyle={"32px"} widthStyle={"32px"} customPrimaryColor={payload.pieColor} customSecondaryColor={payload.pieColorSecondary}/>
                            <span className="pl-1" style={{color: payload.dotColorHex }}>{payload.isJoint ? "Joint" : props.viewModel.client.name + "'s"} {payload.percentile}th Percentile Life Expectancy</span>
                        </span>
                    )}
                </div>
            );
        }
    }

    const customDot = (props)  => {
        const { cx, cy, payload } = props;
        const xString = String(cx);
        const yString = String(cy);
        const color = payload.dotColorHex;
        const showDot = payload.isLifeExpectancyYear;
        return showDot 
            ? (<circle key={utils.generateUniqueKey()} r={10} type="monotone" stroke="none" strokeWidth="2" name="IRR" fill={color} width="520" height="320" cx={xString} cy={yString} className="recharts-dot recharts-line-dot"></circle>)
            : (<circle key={utils.generateUniqueKey()} r={3} type="monotone" stroke="none" strokeWidth="2" name="IRR" fill={chartSettings.lineAndPointColor} cx={xString} cy={yString}></circle>);
    }

    const CustomXTick = (data) => {
        const { x, y, payload } = data;
        const ages = payload.value.split("|");
        const clientCurrentAge = Number(ages[0].trim());
        return (
            <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={16} fill="#666">
              <tspan textAnchor="middle" x="0" className="x-tick">
                {clientCurrentAge - props.viewModel?.client?.age + 1}
              </tspan>
            </text>
          </g>
        );
    }

  return (
    <ResponsiveContainer>
        <LineChart 
            data={chartData} 
            margin={{ top: 10, right: 20, left: 20, bottom: 20 }}
        >
            <Line 
                type="monotone" 
                dot={customDot} //Allows use of custom svg to represent specific data points
                dataKey="y" 
                stroke={chartSettings.lineAndDotColor} 
                strokeWidth={2} 
                name={"IRR"} 
                isAnimationActive={false} //Skips the line automation so the user doesn't print to pdf before animation completes
            />
            <CartesianGrid 
                stroke="#ccc" 
                vertical={false} //Hides verical lines corresponding with ticks
                horizontal={false} //Hides horizontal lines corresponding with ticks
            />
            <Tooltip 
                formatter={chartSettings.tooltipFormatter} //Custom tooltip to hide the default text shown in tooltip
                labelFormatter={chartSettings.tooltipLabelFormatter} //Custom label formatter to allow display of custom data in the tooltip
            />
            <XAxis 
                dataKey="x" 
                tick={CustomXTick} //Allows use of a custom tick (svg) to display ages vertically
                interval={0} //Interval of 0 shows all ticks (1 will show half the ticks, etc.)
                ticks={xAxisTicks} //Sets the specific ticks shown
            >
                <Label 
                    value={chartSettings.xAxisLabel} 
                    position={"bottom"}
                    dy={5} //Display label vertically depending on how many sets of ages are shown
                />
            </XAxis>
            <YAxis 
                tickFormatter={(y) => y * 100 + "%"} //Display decimal as percentage
                ticks={yAxisTicks} //Set of custom ticks
                interval={0} //Shows all custom ticks
                domain={yAxisDomain} //Restricts the range of the yaxis
                allowDataOverflow={true} //Ensures data points outside of the range (domain) are not shown and the axis doesnt scale to the data
            >
                <Label value="IRR" angle={-90} position={"center"} dx={-30}/>
            </YAxis>
        </LineChart>
    </ResponsiveContainer>
  );
}
