import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types'
import CircleWithWedge from './CircleWithWedge';
import { CalendarIcon } from'@heroicons/react/outline';
import utils from "../../src/shared/utils";

ConsumerCard.propTypes = {
  consumer: PropTypes.object,
  primaryId: PropTypes.number,
  isSpouse: PropTypes.bool
}

ConsumerCard.defaultProps = {
  consumer: { id: 0, name: "loading...", dateOfBirth: "01/01/0001", gender: 1 },
  primaryId: 0,
  isSpouse: false
};

export default function ConsumerCard(props) {

    const navigate = useNavigate();
    const editConsumer = () => {
      let link = props.isSpouse 
          ? `/v1/consumers/edit?clientid=${props.primaryId}&spouseid=${props.consumer.id}`
          : `/v1/consumers/edit?clientid=${props.consumer.id}`
      navigate(link);
    }

    const sexText = props.consumer.isMale 
      ? "Male"
      : props.consumer.isMale === null
        ? "Missing Sex"
        : "Female";

    return (
      <div tabIndex={0} className="grid grid-cols-4 bg-white min-h-[8.5rem] h-fit w-full hover:border-orange-400 hover:border-[3px] hover:rounded-md cursor-pointer" onClick={editConsumer} onKeyUp={(e) => e.key === "Enter" && editConsumer()}>
        <div className="justify-start m-4 col-span-3">
            <div className="block">
                <div className="font-bold">
                    <span>{props.consumer.name}</span>
                    {props.isSpouse && (
                        <span className="bg-slate-100 ml-2 p-1 rounded">Spouse</span>
                    )}
                </div>
                <div className="flex">
                    <CalendarIcon className="w-7" />
                    <span className="ml-2">{utils.getFormattedDate(props.consumer.dateOfBirth)}</span>
                </div>
                <div>{sexText}</div>
            </div>
        </div>
        <div className="flex justify-end items-center m-4 col-span-1">
            <CircleWithWedge 
                wedgeStartingAngle={50} 
                text={`${props.consumer?.lifeExpectancy}`} 
                labelText="50%" 
                useAlternateColors={props.isSpouse} 
                heightStyle="50px"
                widthStyle="50px"
            />
        </div>
      </div>
    );
}