import { useQuery } from 'react-query';
import dataCalls from '../../../shared/dataCalls';
import staticData from '../../../shared/staticData';
import Card from '../../../components/Card';

export default function GeneralAnalytics() {
    
    const { 
        data: generalAnalytics, 
        isLoading: isFetchingGeneralAnalytics,
        remove: resetGeneralAnalytics
    } = useQuery("generalAnalytics", dataCalls.getGeneralAnalytics, {
        refetchOnMount: true,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false
    });

    const activeReports = staticData.getActiveReports();
    
  return (
    <div>
        <h3 className="my-4">General Analytics</h3>
        {!isFetchingGeneralAnalytics && (
            <div>
                <div className="grid grid-cols-3 justify-center mx-[20%]">
                    <Card headerText={String(generalAnalytics.numberOfUsers.toString())} text={"Total Users"} containerClasses={"bg-core-blue-100 text-white mx-2"} />
                      <Card headerText={String(generalAnalytics.numberOfAdvisors.toString())} text={"Advisors"} containerClasses={"bg-core-blue-100 text-white mx-2"} />
                      <Card headerText={String(generalAnalytics.numberOfMarketers.toString())} text={"Marketers"} containerClasses={"bg-core-blue-100 text-white mx-2"} />
                </div>
                <div className="">
                    <h3>Reports</h3>
                      <div className="grid grid-cols-3 justify-center mx-[20%] gap-y-9">
                    {activeReports.map(report => (
                        <Card 
                            headerText={generalAnalytics.reportCounts[report.upperCamelCaseName].toString()} 
                            text={`${report.title} reports`} 
                            containerClasses={"bg-core-blue-100 text-white mx-2"}
                        />
                    ))}
                    </div>
                </div>
            </div>
        )}
    </div>
  )
}
