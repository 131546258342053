import React from 'react'
import PropTypes from 'prop-types';

IconWrapper.propTypes = {
    title: PropTypes.string,
    icon: PropTypes.element.isRequired
}

IconWrapper.defaultProps = {
    title: "",
}

export default function IconWrapper(props) {
  return (
    <span title={props.title}>
        {props.icon}
    </span>
  )
}
