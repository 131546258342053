import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton';
import { useQuery } from 'react-query';
import dataCalls from '../../shared/dataCalls';
import utils from '../../shared/utils';

export default function Example() {

  const [ buttonClicked, setButtonClicked ] = useState(false);

  const { 
    data: advisors, 
      isLoading: isFetchingAdvisors,
      remove: resetAdvisors
  } = useQuery("advisors", dataCalls.fetchAdvisors, {
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    if(buttonClicked) {
      resetAdvisors();
      setButtonClicked(false);
    }
  }, [buttonClicked])

  const shuffle = (array) => {
    let currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex != 0) { 
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
    return array;
  }

  return (
    <div className="flex place-items-center">
      <span className="font-bold">Top 10 Advisors:</span>
      {isFetchingAdvisors 
        ? <span>Loading...</span>
        : (<ul className="block">
          {shuffle(advisors).map(advisor => (
            <li key={utils.generateUniqueKey()}>{advisor.name}</li>
          ))}
      </ul>)}
      <button className="ml-8 p-2 rounded-md text-white text-sm bg-core-orange-400" onClick={() => setButtonClicked(true)}>Click to reload!</button>
    </div>
  )
}
