import { ClockIcon } from "@heroicons/react/outline";
import React from "react";

export default function SessionExpired() {
    return (
        <div className="min-h-full justify-center grid grid-cols-3 place-content-center">
          <main className="max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8 col-start-2">
            <div className="py-16">
              <div className="text-center">
                <h2 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Your session has expired due to inactivity.</h2>
                <div className="mt-6">
                  <a href="https://coreincome.com/actuary-lab" className="text-xl font-bold text-core-orange-600 hover:text-core-orange-500">
                    Go back home<span aria-hidden="true">&rarr;</span>
                  </a>
                </div>
              </div>
            </div>
          </main>
        </div>
    )
  }