import { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton';
import ReportActionDropdown from '../../../components/ReportActionDropdown';
import StatusPill from '../../../components/StatusPill';
import utils from '../../../shared/utils';
import Button from '../../../inputs/Button';
import { useNavigate } from 'react-router-dom';
import staticData from '../../../shared/staticData';
import { DropdownOption } from '../../../shared/models/inputClasses';
import FormlessInput from '../../../inputs/FormlessInput';
import SortByDropdown from '../../../inputs/SortByDropdown';


PastReportsTable.propTypes = {
  reports: PropTypes.array,
  isLoading: PropTypes.bool,
  refetchReports: PropTypes.func
}

PastReportsTable.defaultProps = {
  reports: [],
  isLoading: false,
  refetchReports: () => true,
};

export default function PastReportsTable(props) {

    const navigate = useNavigate();

    const [reports, setReports] = useState([]);
    const [reportUpdateCounter, setReportUpdateCounter ] = useState(0);
    const [reportChunks, setReportChunks] = useState([]);
    const [currentSort, setCurrentSort] = useState({ value: 0, direction: "" });
    
    const [pageLength, setPageLength] = useState(10);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const [pageNumbers, setPageNumbers] = useState([1]);
    
    useEffect(() => {
        setReports(props.reports);
    }, [props.reports]);

    useEffect(() => {
        if(reports) {
            updateReportChunks();
            updatePageNumbers();
        }
    }, [reports, pageLength, reportUpdateCounter]);

    const updateReportChunks = () => {
        let tempReportChunks = utils.chunkArray(reports, pageLength);
        if(tempReportChunks.length > 0) {
            while(tempReportChunks[tempReportChunks.length - 1].length < 10) {
                tempReportChunks[tempReportChunks.length - 1].push(null);
            }
        }

        setReportChunks(tempReportChunks);
    }
    
    const updatePageNumbers = () => {
        const numberOfPages = Math.ceil(reports.length / pageLength);
        const pageArray = Array.from(Array(numberOfPages), (x, i) => i + 1);
        setPageNumbers(pageArray);
    }


    const handleReportClick = async (report) => {
        const reportRoute = staticData.getReport(report.reportType).route;
        const reportId = report.id;
        const action = report.isDraft ? "edit" : "report";
        const link = `/v1/reports/${reportRoute}/${action}/${reportId}`;
        navigate(link)
    }

    const searchHandler = (e) => {
        const value = e.target.value.toLowerCase();
        let filteredReports = props.reports.filter(r => r.reportName.toLowerCase().includes(value) || r.reportTypeName.toLowerCase().includes(value)
            || utils.getFormattedDate(r.updatedAt).includes(value) || r.createdPersonName.toLowerCase().includes(value));
        setReports(filteredReports);
        setReportUpdateCounter(reportUpdateCounter + 1);
    }

    const sortHandler = (e, option, direction = "") => {
        //Default sort
        if(direction === "") {
            reports.sort((a, b) => a.updatedAt < b.updatedAt ? 1 : -1);
        } else {
            //Sort ascending
            if(option.value === 1) {
                reports.sort((a, b) => a.reportName.localeCompare(b.reportName));
            } else if(option.value === 2) {
                reports.sort((a, b) => a.reportTypeName.localeCompare(b.reportTypeName));
            } else if(option.value === 3) {
                reports.sort((a, b) => a.updatedAt < b.updatedAt ? -1 : 1);
            } else if(option.value === 4) {
                reports.sort((a, b) => a.createdPersonName.localeCompare(b.createdPersonName));
            }
            //Handle descending
            if(direction === "desc") {
                reports.reverse();
            }
        }

        setReportUpdateCounter(reportUpdateCounter + 1);
        setCurrentSort({
            value: option.value,
            direction: direction
        });
    };

    const sortOptions = [
        new DropdownOption("Report Name", 1, sortHandler),
        new DropdownOption("Report Type", 2, sortHandler),
        new DropdownOption("Last Modified", 3, sortHandler),
        new DropdownOption("Ran By", 4, sortHandler)
    ];

    const getCurrentItemsNumbersShown = () => {
        if (!reports) {
            return `1-1`
        }
        let firstItemNumber = (currentPageNumber - 1) * 10 + 1;
        let lastItemNumber = currentPageNumber * 10;
        if(reports.length === 0) {
            firstItemNumber = 0;
        }
        if(reports.length < lastItemNumber) {
            lastItemNumber = reports.length 
        }
        return `${firstItemNumber}-${lastItemNumber}`
    }

  return (
    <div className="mb-10">
        <div className="flex justify-between align-bottom mt-10">
            <FormlessInput type="text" label="Search Past Reports" onKeyUp={(e) => searchHandler(e)} containerClassNames="min-w-[300px] w-1/4" />
            <SortByDropdown text={"Sort by"} options={sortOptions} sortSettings={currentSort} containerClassNames="mt-7"/>
        </div>
        <div className="justify-start mt-4">
            <h4 className="font-bold">Past Reports</h4>
        </div>
        <div className={`grid grid-cols-1 ${reports?.length > 0 ? "h-[800px] min-h-[800px]" : ""}`}>
            <table className="profile-report-table border-slate-200">
                <thead className="bg-slate-300">
                    <tr className="text-left">
                        <th>Report Name</th>
                        <th className="text-left">Report Type</th>
                        <th className="text-left">Last Modified</th>
                        <th className="text-left">Ran By</th>
                        <th className="text-center">Status</th>
                        <th className="text-center">Actions</th>
                    </tr>
                </thead>
                <tbody className="bg-white h-[670px]">
                {props.isLoading ? (
                    <tr>
                        <td colSpan={7}><Skeleton count={10} colSpan={6} height={50} /></td>
                    </tr>
                )
                : reports?.length > 0
                  ? reportChunks[currentPageNumber - 1]?.map((report, i) => (
                    report != null 
                          ? <tr key={report.id} className={`cursor-pointer hover:bg-selection-color-200 h-16 ${i % 2 === 0 ? "bg-white" : "bg-slate-100"}`}>
                        <td onClick={() => handleReportClick(report)} className="w-[40%]">{report.reportName}</td>
                        <td onClick={() => handleReportClick(report)} className="text-left">{report.reportTypeName}</td>
                        <td onClick={() => handleReportClick(report)} className="text-left">{utils.getFormattedDate(report.updatedAt)}</td>
                        <td onClick={() => handleReportClick(report)} className="text-left">{report.createdPersonName}</td>
                        <td onClick={() => handleReportClick(report)} className="w-[12.0%] min-w-[150px]">
                            <StatusPill 
                                backgroundColorClass={report.isDraft ? "bg-core-blue-100" : "bg-green-100"} 
                                bulletColorClass={report.isDraft ? "text-core-blue-400" : "text-green-600"}
                                textColorClass={report.isDraft ? "text-core-blue-600" : "text-green-800"}
                                text={report.status}
                            />
                        </td>
                        <td className="text-center cursor-pointer">
                            <ReportActionDropdown report={report} refetchReports={props.refetchReports} key={utils.generateUniqueKey()}/>
                        </td>
                      </tr>
                    : <tr className="h-16">
                        <td colSpan={7}></td>
                      </tr>
                )) : (
                    <tr className="text-center">
                        <td colSpan={7}>No Reports Found</td>
                    </tr>
                )}
                </tbody>
                <tfoot>
                    <tr className="h-16">
                        <td colSpan={7}>
                            {pageNumbers.map((num) => (
                                <Button text={String(num)} clickHandler={() => setCurrentPageNumber(num)} classNames={`mr-1 text-black hover:bg-white focus:ring-core-orange-300 ${num === currentPageNumber ? 'bg-core-orange-200' : 'bg-slate-100'}`}></Button>
                            ))}
                            <span className="font-bold ml-2">Showing items {getCurrentItemsNumbersShown()} of {reports?.length}</span>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
  )
}
