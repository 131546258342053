import React, { useState } from 'react'
import Skeleton from 'react-loading-skeleton';

export default function CopyPasteHelp() {
  const [ isGifLoaded, setIsGifLoaded ] = useState(false);
  const gifStyle = isGifLoaded ? {} : {display: 'none'};

  return (
    <div>
        <h3>Quickly Copy &#38; Paste Data from Spreadsheets</h3>
        <div className="my-2 text-sm">
          You can copy and paste the Annual Income, Remaining Balance, or both columns from a spreadsheet. Simply align the data with the desired year. 
        </div>
        {!isGifLoaded && <div><Skeleton className="h-96"/></div>}
        {<img style={gifStyle} src="/images/reports/internalrateofreturn/copyfromexcel.gif" onLoad={() => setIsGifLoaded(true)}/>}
    </div>
  )
}
