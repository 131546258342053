import React, { useContext, createContext, useState } from "react";

const ThemeContext = createContext(null);

export const ThemeProvider = ({ children }) => {

    const [ backgroundClass, setBackgroundClass ] = useState("bg-core-image");
    const [ justifyClass, setJustifyClass ] = useState("justify-center");
    
    const resetTheme = () => {
        setBackgroundClass("bg-core-image");
        setJustifyClass("justify-center");
    }

    return <ThemeContext.Provider value={{backgroundClass, justifyClass, setBackgroundClass, setJustifyClass, resetTheme}}>{children}</ThemeContext.Provider>
}

export const useTheme = () => useContext(ThemeContext);