import dataCalls from "../../../shared/dataCalls";
import reportHelper from "../../../shared/reportHelper";

class shared {
    
    static getLedgerRowBackgroundClass = (ledgerEntry, viewModel, defaultColor = "bg-white") => {
        if(!viewModel) {
            return defaultColor;
        }

        //Use age of the younger client
        let ledgerAge = ledgerEntry?.clientAge ?? 0;
        if(viewModel.spouse?.age < viewModel.client.age) {
            ledgerAge = ledgerEntry?.spouseAge ?? 0;
        }

        if(viewModel.lifeExpectancies[50] === ledgerAge) {
            return "!bg-life-expectancy-50";
        }
        if(viewModel.lifeExpectancies[25] === ledgerAge) {
            return "!bg-life-expectancy-25";
        }
        if(viewModel.lifeExpectancies[10] === ledgerAge) {
            return "!bg-life-expectancy-10";
        }
        return defaultColor;
    }

    static finalizeReport = async (report, toast, navigate) => {
        const toastId = toast.loading("Creating report...", {
            position: toast.POSITION.BOTTOM_CENTER
        });

        const data = JSON.parse(JSON.stringify(report));
        data.htmlContent = reportHelper.extractHtml();
        const saveResponse = await dataCalls.finalizeReport(data);

        if(saveResponse.success) {
            toast.update(toastId, { render: "Save successful", type: "success", isLoading: false });
        } else {
            toast.update(toastId, { render: "An error occurred...", type: "error", isLoading: false });
            return false;
        }
        setTimeout(() => toast.dismiss(toastId.current), 1000);
        
        if(saveResponse.success) {
            let reportLink = `/v1/reports/internalrateofreturn/report/${saveResponse.data}`
            navigate(reportLink)
        } else {
            return false;
        }
    }
}

export default shared;