class coreColors {
    static coreOrange100 = '#FCDECC';
    static coreOrange200 = '#F79C64';
    static coreOrange300 = '#f47f37';
    static coreOrange400 = '#f97000';
    static coreOrange500 = '#f37820';
    static coreOrange600 = '#d55f0b';

    static coreBlue100 = '#DEECF4';
    static coreBlue200 = '#87B8D6';
    static coreBlue300 = '#2e5e79';
    static coreBlue400 = '#004f68';
    static coreBlue500 = '#014554';
    static coreBlue600 = '';

    static corePurple100 = 'rgb(227,224,239)';
    static corePurple200 = 'rgb(194,176,226)';
    static corePurple300 = 'rgb(144,112,202)';
    static corePurple400 = '#8FADC0';
    static corePurple500 = '';
    static corePurple600 = '';
}

export default coreColors;