import { default as ReactModal } from 'react-modal';
import Button from '../inputs/Button';
import PropTypes from 'prop-types'

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.element
}

Modal.defaultProps = {
  children: "<div>Missing Content</div>"
};


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: "50%"
  }
};

export default function Modal(props) {

  ReactModal.setAppElement('.App');

  return (
    <div>
      <ReactModal
        isOpen={props.isOpen}
        style={customStyles}
        className=""
      >
        <div>
          {props.children}
        </div>
        <div className="flex justify-end mt-3 mx-1">
            <Button clickHandler={props.onClose} text="Close" classNames="bg-core-orange-400 mx-1"/>
        </div>
      </ReactModal>
    </div>
  );
}
