import { useQuery } from 'react-query';
import dataCalls from '../../../shared/dataCalls';
import ReactApexChart from 'react-apexcharts';
import { useEffect, useState } from 'react';
import Card from '../../../components/Card';

export default function InternalRateOfReturnAnalytics() {

    const { 
        data: internalRateOfReturnAnalytics, 
        isLoading: isFetchingAnalytics,
        remove: resetAnalytics
    } = useQuery("irrAnalytics", dataCalls.getInternalRateOfReturnAnalytics, {
        refetchOnMount: true,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false
    });

    const [ startingBalanceBoxPlotData, setStartingBalanceBoxPlotData ] = useState([]);
    const [ numberOfYearsBoxPlotData, setNumberOfYearsBoxPlotData ] = useState([]);

    useEffect(() => {
        if(internalRateOfReturnAnalytics) {
            setStartingBalanceBoxPlotData(calculateQuartileData(internalRateOfReturnAnalytics.startingBalanceList));
            setNumberOfYearsBoxPlotData(calculateQuartileData(internalRateOfReturnAnalytics.numberOfYearsList));
        }
    }, [isFetchingAnalytics]);

    const calculateQuartileData = (array) => {
        const min = Math.min(...array);
        const max = Math.max(...array);
        const firstQuartile = findQuartileData(array, 0.25);
        const median = findQuartileData(array, 0.5);
        const thirdQuartile = findQuartileData(array, 0.75);
        return [ min, firstQuartile, median, thirdQuartile, max ];
    }

    const findQuartileData = (array, quartileDecimal = 0.5) => {
        const sorted = Array.from(array).sort((a, b) => a - b);
        const index = Math.floor(sorted.length * quartileDecimal);
        if (sorted.length % 2 === 0) {
            return (sorted[index - 1] + sorted[index]) / 2;
        }
        return sorted[index];
    }

    const startingBalanceData = {
        options: {
            chart: {
                type: 'boxPlot',
                height: 350
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    barHeight: '50%'
                },
                boxPlot: {
                    colors: {
                        upper: '#e9ecef',
                        lower: '#f8f9fa'
                    }
                }
            },
            stroke: {
                colors: ['#6c757d']
            }
        },
        series: [{
            data: [
                {
                    x: 'Starting Balance',
                    y: startingBalanceBoxPlotData
                }
            ]
        }]
    };

    const numberOfYearsData = {
        options: {
            chart: {
                type: 'boxPlot',
                height: 350
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                    barHeight: '50%'
                },
                boxPlot: {
                    colors: {
                        upper: '#e9ecef',
                        lower: '#f8f9fa'
                    }
                }
            },
            stroke: {
                colors: ['#6c757d']
            }
        },
        series: [{
            data: [
                {
                    x: 'Number of Report Years',
                    y: numberOfYearsBoxPlotData
                }
            ]
        }]
    };

    return (
        <div>
            {!isFetchingAnalytics && (
                <div className="grid grid-cols-2 mx-[20%]">
                    <Card headerText={String(internalRateOfReturnAnalytics.numberOfCompletedReports.toString())} text={"Completed Reports"} containerClasses={"bg-core-blue-100 text-white mx-2 my-2"} />
                    <Card headerText={String(internalRateOfReturnAnalytics.numberOfDrafts.toString())} text={"Drafts"} containerClasses={"bg-core-blue-100 text-white mx-2 my-2"} />
                    <Card headerText={String(internalRateOfReturnAnalytics.guidedInputPercentage.toString())} text={"Guided Input Percentage"} containerClasses={"bg-core-blue-100 text-white mx-2 my-2"} />
                    <Card headerText={String(internalRateOfReturnAnalytics.customInputPercentage.toString())} text={"Custom Input Percentage"} containerClasses={"bg-core-blue-100 text-white mx-2 my-2"} />
                </div>
            )}
            <ReactApexChart options={startingBalanceData.options} series={startingBalanceData.series} type="boxPlot" height={350} />
            <ReactApexChart options={numberOfYearsData.options} series={numberOfYearsData.series} type="boxPlot" height={350} />
        </div>
    )
}
