import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useQuery } from "react-query";
import dataCalls from "../../shared/dataCalls";
import { AdvisorContainer, TimerContainer, Container, Advisor } from "./Components/ActivityLogs";
import Button from "@mui/material/Button";
import { Autocomplete, TextField } from "@mui/material";
import { useTheme } from "../../contexts/ThemeContext";
import Skeleton from "react-loading-skeleton";
import utils from "../../shared/utils";

export default function Index() {
  const [page, setPage] = React.useState(1);
  const [search, setSearch] = React.useState(null);

  const { data: activityLogs, isFetching: isFetchingActivities } = useQuery(
    ["activityLogs", page, search],
    () => dataCalls.fetchActivityLogs(page, 10, search),
    {
      keepPreviousData: true,
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

  const { setJustifyClass, resetTheme } = useTheme();
  React.useEffect(() => {
    setJustifyClass("");
    return () => {
      resetTheme();
    };
  }, []);

  const onNextButtonClick = () => {
    setPage(page + 1);
    setSearch(search);
  };

  const onPreviousButtonClick = () => {
    setPage(page - 1);
    setSearch(search);
  };

  return (
    <div className="justify-center place-content-center mt-24">
      <div className="flex justify-between align-middle mb-4">
        <h1 className="font-bold text-2xl">
          Recent Activities
        </h1>
        <div className="w-1/3">
          <Autocomplete
            autoComplete={false}
            freeSolo
            options={["Employee", "Advisor"]}
            renderInput={(params) => <TextField {...params} label="Search" />}
            sx={{ flex: 1 }}
            value={search}
            onChange={(event, newValue) => setSearch(newValue)}
          />
        </div>
      </div>
      {isFetchingActivities ? (
        <Skeleton count={10} height={50} width={900}/>
      ) : (
        <TableContainer
          component={Paper}
          sx={{
            width: "max-content",
          }}
        >
          <Table sx={{ minWidth: 800 }} aria-label="custom pagination table">
            <TableBody>
              {activityLogs.length > 0 ? (
                activityLogs.map((a) => (
                  <TableRow key={a.fullName} hover>
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ maxWidth: 400, minWidth: 400 }}
                      align="right"
                    >
                      <AdvisorContainer>
                        <TimerContainer>
                          <span>
                            {utils.timeSince(new Date(a.lastViewed))} ago
                          </span>
                        </TimerContainer>
                        <Container>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <Advisor>
                            <p style={{ fontSize: 16, fontWeight: 700 }}>
                              {a.fullName}
                            </p>
                          </Advisor>
                        </Container>
                      </AdvisorContainer>
                    </TableCell>

                    <TableCell sx={{ maxWidth: 200, minWidth: 200 }}>
                      <AdvisorContainer>
                        <Container>
                          {a.status === "Employee" ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth={2}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z"
                                clipRule="evenodd"
                              />
                              <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z" />
                            </svg>
                          )}
                          <Advisor>{a.status}</Advisor>
                        </Container>
                      </AdvisorContainer>
                    </TableCell>
                    <TableCell sx={{ width: 300 }} align="right">
                      <AdvisorContainer>
                        <Container>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                          </svg>
                          <p>Viewing:</p>
                          <Advisor><a style={{ color: "blue", textDecoration: "underline" }} href={a.route}>{a.route}</a></Advisor>
                        </Container>
                      </AdvisorContainer>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow align="center">
                  <h3 style={{ fontWeight: 700, fontSize: 18 }}>
                    OOPSS :{`(`} No Results Found...{" "}
                  </h3>
                </TableRow>
              )}
            </TableBody>
            {activityLogs.length > 0 && (
              <TableFooter>
                <TableRow>
                  <Button
                    size="medium"
                    onClick={onPreviousButtonClick}
                    disabled={page === 1}
                  >
                    Previous
                  </Button>
                  <Button
                    size="medium"
                    onClick={onNextButtonClick}
                    disabled={page === activityLogs.length}
                  >
                    Next
                  </Button>
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      )}
    </div>
  );
}
