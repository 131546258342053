export class DropdownButtonOption {
    constructor(text, link, handler = undefined) {
        this.text = text;
        this.link = link;
        this.handler = handler;
    }
}

export class DropdownOption {
    constructor(text, value, onClick = undefined) {
        this.text = text;
        this.value = value;
        this.onClick = onClick;
    }
}