import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton';
import { useQuery } from 'react-query';
import dataCalls from '../../../shared/dataCalls';
import staticData from '../../../shared/staticData';
import utils from '../../../shared/utils';

export default function AdvisorUsageTable() {
  const { 
    data: advisorUsageAnalytics, 
    isLoading: isFetchingUsageAnalytics,
    remove: resetAdvisorUsageAnalytics
  } = useQuery("advisorUsage", dataCalls.getAdvisorUsageAnalytics, {
    refetchOnMount: true,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false
  });

  const activeReports = staticData.getActiveReports();
  const colSpan = 6 + activeReports.length * 2;

  return (
    <table className="border-slate-200 w-full">
        <thead className="bg-slate-300">
            <tr>
                <th className="text-left">Advisor Name</th>
                <th className="text-left">Login Count</th>
                <th className="text-left">Last Login</th>
                <th className="text-left">Consumers</th>
                {activeReports.map(report => (
                  <th key={utils.generateUniqueKey()} className="text-left">{report.title}</th>
                ))}
                <th className="w-[1%] bg-slate-300"></th>
                <th className="text-left">Marketer Created Consumers</th>
                {activeReports.map(report => (
                  <th key={utils.generateUniqueKey()} className="text-left">{report.title}</th>
                ))}
            </tr>
        </thead>
        <tbody className="bg-white">
          {isFetchingUsageAnalytics? (
              <tr>
                  <td colSpan={colSpan}><Skeleton count={5} colSpan={6} /></td>
              </tr>
          )
          : advisorUsageAnalytics.map((item, i) => (
              <tr key={utils.generateUniqueKey()} className={`hover:bg-core-orange-200 h-16 ${i % 2 === 0 ? "bg-core-orange-50" : "bg-white"}`}>
                <td className="text-left px-2">{item.name}</td>
                <td className="text-left px-2">{item.numberOfLogins}</td>
                <td className="text-left px-2">{item.lastLogin ? utils.getFormattedDate(item.lastLogin) : "Never"}</td>
                <td className="text-left px-2">{item.numberOfConsumersCreated}</td>
                {activeReports.map(report => (
                  <td key={utils.generateUniqueKey()} className="text-left px-2">{item.completedReportCounts[report.upperCamelCaseName] ?? 0} Reports</td>
                ))}
                <td className="w-[1%] bg-slate-300"></td>
                <td className="text-left px-2">{item.numberOfConsumersCreatedOnBehalf}</td>
                {activeReports.map(report => (
                  <td key={utils.generateUniqueKey()} className="text-left px-2">{item.marketerCompletedReportCounts[report.upperCamelCaseName] ?? 0} Reports</td>
                ))}
              </tr> 
          ))}
        </tbody>
      </table>
  )
}
