import { XIcon } from '@heroicons/react/outline';
import TooltipCustom from '../../../../components/TooltipCustom'
import PropTypes from 'prop-types';
import Input from '../../../../inputs/Input';
import { QuestionMarkCircleIcon } from '@heroicons/react/outline';
import utils from "../../../../shared/utils";

InflationPeriod.propTypes = {
    onUpdate: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    hasAgeOverlapError: PropTypes.bool.isRequired,
    fieldErrors: PropTypes.array.isRequired,
    autoFocusStartYear: PropTypes.bool
}
  
InflationPeriod.defaultProps = {

};

var tooltipMessage = `<b>Historical Inflation </b><br>6.5% Annually Since 2020<br>2.5% Annually Since 2000<br>4.0% Annually Since 1970`

export default function InflationPeriod(props) {

    const hasFieldError = (name) => {
        return props.fieldErrors.filter(error => error.name === name).length > 0;
    }

  return (
    <div className="bg-slate-200 mt-6 p-4">
        <div className="grid">
            <div className="flex justify-between">
                <span className="font-bold">Inflation Period</span>
                {props.index > 0 && (
                    <XIcon onClick={() => props.onRemove(props.index)} className="h-6 cursor-pointer" />
                )}
            </div>
              <span className="text-sm">Enter the assumed inflation for each year in the specified range
                  {<TooltipCustom
                      direction="down"
                      message={tooltipMessage}
                      symbol=<QuestionMarkCircleIcon/>
                      width='11vw'
                  />}
              </span>
        </div>
        <div className="grid grid-cols-2">
            <div className="col-span-1 flex">
                <Input
                    type="number"
                    name={`inflationPeriods[${props.index}].startYear`}
                      label={"Starting Year"}
                      onInput={(e) => {
                          e.target.value = Math.round(e.target.value);
                      }
                      }
                    containerClassNames="pr-2 py-3"
                    onChange={(e) => props.onUpdate(e.target)}
                    hasValidationError={hasFieldError(`annualIncomes[${props.index}].startYear`) || hasFieldError(`annualIncomes[${props.index}].years`) || props.hasAgeOverlapError}
                    autoFocus={props.autoFocusStartYear}
                />
                <Input
                    type="number"
                    name={`inflationPeriods[${props.index}].endYear`}
                      label={"Ending Year"}
                      onInput={(e) => {
                          e.target.value = Math.round(e.target.value);
                      }
                      }
                    containerClassNames="pl-2 py-3"
                    onChange={(e) => props.onUpdate(e.target)}
                    hasValidationError={hasFieldError(`annualIncomes[${props.index}].endYear`) || hasFieldError(`annualIncomes[${props.index}].years`) || props.hasAgeOverlapError}
                />
            </div>
            <div className="col-span-1"></div>
            <div className="col-span-1">
                <Input
                    type="number"
                    step=".1"
                    name={`inflationPeriods[${props.index}].assumedInflation`}
                      onInput={(e) => {
                          utils.setDecimalPlaces(e);
                      }}
                    label={"Assumed Inflation"}
                    symbol="%"
                    containerClassNames="py-3"
                    onChange={(e) => props.onUpdate(e.target)}
                    hasValidationError={hasFieldError(`inflationPeriods[${props.index}].assumedInflation`)}
                />
            </div>
        </div>
    </div>
    )
}
