import styled from "styled-components"

export const AdvisorContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`
export const TimerContainer = styled.div`
    max-width: 30%;
    margin-right: 2px;
`

export const Advisor = styled.div`
  margin-left:2px;
`
    

export const Container = styled.div`
    display: flex;
    justify-content: left;
`
export const Main = styled.div`

`

