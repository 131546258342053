import React from 'react';
import { useUser } from '../../contexts/UserContext';

export default function NotFound() {

    const { userModel } = useUser();
    const getHomeRef = (roleType) => {
        if(roleType === 2) {
            return "/v1/advisors";
        }
        if(roleType === 3) {
            return "/v1/consumers";
        }
    }
  

  return (
    <div className="min-h-full pt-16 pb-12 flex flex-col">
        <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
            <div className="py-16">
            <div className="text-center">
                <p className="text-sm font-semibold text-core-orange-600 uppercase tracking-wide">404 error</p>
                <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Not Found</h1>
                <div className="mt-6">
                <a href={getHomeRef(userModel?.roleType)} className="text-xl font-bold text-core-orange-600 hover:text-core-orange-500">
                    Go back home<span aria-hidden="true"> &rarr;</span>
                </a>
                </div>
            </div>
            </div>
        </main>
    </div>
  )
}
