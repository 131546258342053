import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useFormContext } from "react-hook-form";
import utils from '../shared/utils';

Switch.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    defaultChecked: PropTypes.bool,
    id: PropTypes.string,
    autoFocus: PropTypes.bool,
    onChange: PropTypes.func,
}
  
Switch.defaultProps = {
    id: undefined,
    defaultChecked: false,
    autoFocus: false,
    onChange: () => true
};

export default function Switch(props) {

    const { register, formState: { errors }, watch, getValues } = useFormContext();
  
    //react-hook-from overrides the default onChange handler, so add additional functionality in the register
    const fieldRegister = register(props.name, props.validationObject);
    const defaultOnChange = fieldRegister.onChange;
    fieldRegister.onChange = (e) => {
        const res = defaultOnChange(e);
        props.onChange(e);
        return res;
    }

    const onKeyUp = (e) => {
        if(e.key === "Enter") {
            e.target.checked = !e.target.checked;
            props.onChange(e);
        }
    }

  return (
    <div>       
        <label htmlFor={props.id} className="inline-flex relative items-center cursor-pointer">
            <input 
                id={props.id ?? utils.generateUniqueKey()}
                name={props.name}
                className="sr-only peer" 
                type="checkbox" 
                role="switch" 
                autoFocus={props.autoFocus}
                onKeyUp={(e) => onKeyUp(e)}
                defaultChecked={props.defaultChecked}
                {...fieldRegister}
            />
            <div className="w-11 h-6 bg-slate-300 rounded-full peer peer-focus:ring-2 peer-focus:ring-core-orange-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-core-orange-400"></div>
            <span className="ml-3 text-sm font-bold text-gray-900 dark:text-gray-300">{props.label}</span>
        </label>
    </div>
  )
}
