import PropTypes from 'prop-types'

Button.propTypes = {
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  clickHandler: PropTypes.func,
  classNames: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string
}

Button.defaultProps = {
  disabled: false,
  clickHandler: undefined,
  classNames: "",
  type: "button",
  value: ""
};


export default function Button(props) {
    return (
      <button
        onClick={props.clickHandler}
        disabled={props.disabled}
        type={props.type}
        value={props.value}
        className={`${props.classNames} inline-flex items-center px-4 py-2 border border-transparent text-base font-bold rounded-md  text-white bg-core-orange-400 hover:bg-core-orange-500 focus:ring-core-orange-400 focus:outline-none focus:ring-2 focus:ring-offset-2`}
      >
        {props.text}
      </button>

    );
}