import { useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from "react-router-dom"
import { SearchIcon } from '@heroicons/react/solid'
import { Combobox } from '@headlessui/react'
import PropTypes from 'prop-types'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

SearchBar.propTypes = {
  searchDataCall: PropTypes.func.isRequired,
  skipId: PropTypes.number,
  placeholder: PropTypes.string,
  minimumQueryLength: PropTypes.number,
  useLocalSearch: PropTypes.bool,
  redirectRoute: PropTypes.string,
  updateClaimFunction: PropTypes.func,
  asyncSelectionFunction: PropTypes.func
}

SearchBar.defaultProps = {
  skipId: 0,
  placeholder: "Search by Name",
  minimumQueryLength: 3,
  useLocalSearch: false,
  redirectRoute: "",
  asyncSelectionFunction: undefined
};

export default function SearchBar(props) {
  const [selection, setSelection] = useState({id: 0, name: ""});
  const [searchResults, setSearchResults] = useState([]);
  const [queryLength, setQueryLength] = useState(0);

  const fetchData = async () => {
    if(props.useLocalSearch) {
      return await props.searchDataCall();
    } 
    return { data: [], isLoading: false };
  }

  const { data: localData } = useQuery('dataItems', fetchData);

  const navigate = useNavigate();

  const handleSelection = async (selection) => {
    if(!selection) {
      return false;
    }

    setSelection(selection);

    if(props.asyncSelectionFunction) {
      await props.asyncSelectionFunction(selection.id);
    }
    
    //update server usersession claim
    if(props.updateClaimFunction) {
      props.updateClaimFunction(selection.id);
    }

    if(props.redirectRoute.length > 0) {
      navigate(props.redirectRoute);
    }
  }

  const handleSearch = async (event) => {
    let searchQuery = event.target.value;

    if(searchQuery.length < props.minimumQueryLength) {
      setSearchResults([]);
      setQueryLength(searchQuery.length);
      return false;
    }

    let filteredResults = [];
    if(props.useLocalSearch) {
      filteredResults = localData.filter((item) => {
        return item.name.toLowerCase().includes(searchQuery.toLowerCase())
      });
    } else {
      filteredResults = await serverSideSearch(searchQuery);
    }
    setSearchResults(filteredResults);
    setQueryLength(searchQuery.length);
  }

  const serverSideSearch = async (searchTerm) => {
    return await props.searchDataCall(searchTerm, props.skipId);
  }



  return (
    <Combobox as="div" value={selection} onChange={handleSelection}>
      <Combobox.Label className="block text-sm font-medium text-gray-700"></Combobox.Label>
      <div className="relative mt-1">
        <form autoComplete='off'>
          <Combobox.Input
            placeholder={props.placeholder}
            className="w-full h-12 rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-core-orange-400 focus:outline-none focus:ring-1 focus:ring-core-orange-400 sm:text-sm"
            onChange={handleSearch}
            on
            displayValue={(item) => item.name}
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </Combobox.Button>
        </form>
        {searchResults?.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {searchResults.map((result) => (
              <Combobox.Option
                key={result.id}
                value={result}
                className={({ active }) =>
                  classNames(
                    'relative cursor-pointer select-none py-2 pl-3 pr-9',
                      active ? 'bg-selection-color-200' : 'text-gray-900'
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span className={classNames('block truncate cursor-pointer', selected && 'font-semibold')}>{result.name}</span>
                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-indigo-600'
                        )}
                      >
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
        {searchResults?.length === 0 && queryLength >= props.minimumQueryLength && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              <Combobox.Option
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9'
                  )
                }
              >
              <span className={classNames('block truncate')}>No Results</span>
              </Combobox.Option>
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  )
}