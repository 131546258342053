import React, { useContext, createContext, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query'
import dataCalls from "../../src/shared/dataCalls";

const UserContext = createContext(null);

export const UserProvider = ({ children }) => {

    const location = useLocation();
    useEffect(() => { refetch() }, [location])
    
    const {
        isFetching,
        isLoading: isLoadingUser,
        error,
        data: userModel,
        refetch
    } = useQuery("currentuser", {
        queryFn: async () => {
            const data = await dataCalls.fetchCurrentUser();
            return data;
        },
        staleTime: Infinity,
        refetchIntervalInBackground: false,
        refretchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        onError: (error) => {
            console.log(error);
            // if (isUnauthorizedError(error)) {
            //     router.push(createLoginUrl(location.pathname));
            // }
        },
        retry: false,
    });

    return <UserContext.Provider value={{userModel, error, isLoadingUser}}>{children}</UserContext.Provider>
}

export const useUser = () => useContext(UserContext);