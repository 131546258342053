import { useEffect, useState } from 'react';
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom';
import { useTheme } from '../../../contexts/ThemeContext'
import dataCalls from '../../../shared/dataCalls';
import staticData from '../../../shared/staticData';
import BackButton from '../../../components/BackButton';
import utils from '../../../shared/utils';
import Skeleton from 'react-loading-skeleton';
import ReportButtons from '../Shared/Buttons/ReportButtons';

export default function Report() {

    const [ isLoadingIframe, setIsLoadingIframe ] = useState(true);
    const iFrameStyle = !isLoadingIframe ? {} : {visibility: 'hidden'};
    const onIFrameLoad = (e) => {
        setIsLoadingIframe(false);
        utils.onIframeLoad(e, "IRRPreviewContent", 1.01);
    }

    const params = useParams();

    const queryParams = new URLSearchParams(window.location.search)
    const initPrint = queryParams.get("print") === 'true';

    const { data, isLoading } = useQuery('vm', () => dataCalls.fetchReportViewModel(params.id), {
        refetchOnMount: true,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false
    });

    const { setBackgroundClass, setJustifyClass, resetTheme } = useTheme();
    useEffect(() => {
        setBackgroundClass("bg-gray");
        setJustifyClass("");
        return () => resetTheme(); 
    }, []);

    return (
        <div className="justify-center place-content-center w-3/4 mt-6 min-h-full">
            <div className="grid grid-cols-4">
                <div className="block col-span-3">
                    <BackButton text="Back to Client Profile" redirectUrl={`/v1/consumers/${data?.consumerId}`}/>
                    {isLoading
                        ? <h2><Skeleton /></h2>
                        : <h2>Internal Rate Of Return Report for {data?.names}</h2>
                    }
                </div>
                <div className="w-full flex justify-end items-center pr-4">
                    <img alt="Internal Rate of Return Logo" src={staticData.getReport(2).imgSrc} className="w-24 h-16"></img>
                </div>
            </div>

            {isLoadingIframe && <Skeleton height={utils.getWindowDimensions().height} />} 
            <div className="flex w-full flex-col mt-4">
                <iframe 
                    title="Internal Rate of Return Report"
                    style={iFrameStyle}
                    srcDoc={data?.htmlContent}
                    onLoad={onIFrameLoad}
                />
            </div>

            <ReportButtons reportType={2} includeCopy={true} initPrint={initPrint} disableInitPrint={() => utils.removeUrlParameter("print")} />
        </div>
    )
}