import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from 'react-query'
import { ThemeProvider } from "./contexts/ThemeContext";
import { UserProvider } from "./contexts/UserContext";
import { SkeletonTheme } from 'react-loading-skeleton'
import CacheBuster from 'react-cache-buster';
import packageInfo from '../package.json';
import Navbar from "./NavBar";
import SubApp from "./SubApp";


function App() {

  const queryClient = new QueryClient();
  const isProduction = process.env.NODE_ENV === 'production';

  return (
    <CacheBuster
      currentVersion={packageInfo.version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
    >
    <QueryClientProvider client={queryClient}>
      <SkeletonTheme baseColor="rgb(242,233,220)" highlightColor="rgb(250,214,153)">
        <Router>
          <UserProvider>
            <div>
              <Navbar />
                <ThemeProvider>
                  <div className="App">
                    <SubApp />
                  </div>
                </ThemeProvider>
            </div>
          </UserProvider>
        </Router>
      </SkeletonTheme>
    </QueryClientProvider>
    </CacheBuster>
  );
}

export default App;
