import React from "react";

export default function Forbidden() {
    return (
        <div className="min-h-full pt-16 pb-12 flex flex-col">
          <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
            <div className="py-16">
              <div className="text-center">
                <p className="text-sm font-semibold text-core-orange-600 uppercase tracking-wide">403 error</p>
                <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Forbidden</h1>
                <p className="mt-2 text-base text-gray-500">You do not have the necessary permissions to access this data.</p>
                <div className="mt-6">
                  <a href="https://coreincome.com/actuary-lab" className="text-xl font-bold text-core-orange-600 hover:text-core-orange-500">
                    Go back home<span aria-hidden="true"> &rarr;</span>
                  </a>
                </div>
              </div>
            </div>
          </main>
        </div>
    )
  }