import React from 'react'
import PropTypes from 'prop-types';
import utils from '../../../../shared/utils';
import "../../../../styles/reports/sequenceofreturns/table.css";

ProjectionTable.propTypes = {
  ledger: PropTypes.array.isRequired,
  viewModel: PropTypes.object.isRequired,
  isPreview: PropTypes.bool,
}

ProjectionTable.defaultProps = {
    isPreview: false
};

export default function ProjectionTable(props) {

    const getLedgerRowBackgroundClass = (ledgerEntry, viewModel, isColumnAfterAge = false, defaultColor = "bg-white") => {
        if (!viewModel) {
            return defaultColor;
        }

        //Use age of the younger client
        let ledgerAge = ledgerEntry?.clientAge ?? 0;
        if (viewModel.spouse?.age < viewModel.client.age) {
            ledgerAge = ledgerEntry?.spouseAge ?? 0;
        }

        if (viewModel.lifeExpectancies[50] === ledgerAge) {
            return "!bg-life-expectancy-50";
        }
        if (viewModel.lifeExpectancies[25] === ledgerAge) {
            return "!bg-life-expectancy-25";
        }
        if (viewModel.lifeExpectancies[10] === ledgerAge) {
            return "!bg-life-expectancy-10";
        }
        if (isColumnAfterAge) {
            if (ledgerEntry.isAccumulationYear) {
                return "bg-sor-color-cream-100";
            } else {
                return "bg-sor-color-grey-100";
            }
        }
        return defaultColor;
    }

    const getBackgroundClass = (entry) => {
        if (entry.isAccumulationYear) {
            return "bg-core-blue-100";
        } else {
            return "bg-core-orange-100";
        }
    }


  return (
    <div>
        <table className="w-full sequence-of-returns-table mr-2">
            <thead className="bg-slate-200">
                <tr className="text-left">
                    <th className="text-center">Year</th>
                    <th className="text-center">{props.viewModel.client.firstName}'s Age</th>
                    {props.viewModel?.spouse && (<th className="text-center">{props.viewModel.spouse.firstName}'s Age</th>)}
                    <th className="text-right">Distribution Amount</th>
                    {!props.isPreview && <th className="text-right">Up Sequence Balance</th>}
                    {!props.isPreview && <th className="text-right">Average Sequence Balance</th>}
                    {!props.isPreview && <th className="text-right">Down Sequence Balance</th>}
                </tr>
            </thead>
            <tbody className="bg-white">
                {props.ledger?.length > 0 && (
                    props.ledger?.map((entry) => (
                        <tr key={entry.year} className={getLedgerRowBackgroundClass(entry, props.viewModel)}>
                            <td className="text-center">{entry.year}</td>
                            <td className="text-center">{entry.clientAge}</td>
                            {props.viewModel.spouse && (<td className="text-center">{entry.spouseAge}</td>)}
                            <td className={`${getLedgerRowBackgroundClass(entry, props.viewModel, true)} text-right`}>{utils.formatAsCurrency(entry.distributionAmount, 0)}</td>
                            {!props.isPreview && <td className={`${getLedgerRowBackgroundClass(entry, props.viewModel, true)} text-right`}>{utils.formatAsCurrency(entry.upSequenceBalance, 0)}</td>}
                            {!props.isPreview && <td className={`${getLedgerRowBackgroundClass(entry, props.viewModel, true)} text-right`}>{utils.formatAsCurrency(entry.averageSequenceBalance, 0)}</td>}
                            {!props.isPreview && <td className={`${getLedgerRowBackgroundClass(entry, props.viewModel, true)} text-right`}>{utils.formatAsCurrency(entry.downSequenceBalance, 0)}</td>}
                        </tr>
                )))}
                {(!props.ledger || props.ledger.length === 0) && (
                    <tr className="text-center">
                        <td colSpan={8} className="pt-1">Not yet calculated</td>
                    </tr>
                )}
            </tbody>
        </table>
    </div>
  )
}
