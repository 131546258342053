import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Menu, Transition } from '@headlessui/react';
import { ArrowDownIcon, ArrowUpIcon, ChevronDownIcon } from '@heroicons/react/solid';
import { DropdownOption } from '../shared/models/inputClasses';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

SortByDropdown.propTypes = {
  text: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.instanceOf(DropdownOption)),
  sortSettings: PropTypes.object,
  containerClassNames: PropTypes.string
}

SortByDropdown.defaultProps = {
  text: "",
  options: [],
  sortSettings: {
    value: 0,
    direction: ""
  },
  containerClassNames: ""
};


export default function SortByDropdown(props) {

  const getSortType = () => {
    if(props.sortSettings.direction === "asc") {
      return "desc"
    }
    if(props.sortSettings.direction === "desc") {
      return "";
    }
    if(props.sortSettings.direction === "") {
      return "asc";
    }
  }

  const getIcon = (option) => {
    if(props.sortSettings.value === option.value) {
      return props.sortSettings.direction === "asc" 
        ? <ArrowUpIcon className="w-3 h-3 mt-1" />
        : props.sortSettings.direction === "desc"
          ? <ArrowDownIcon className="w-3 h-3 mt-1" />
          : <span></span>;
    }
    return <span></span>;
  }

  return (
    <Menu as="div" className={`relative inline-block text-left ${props.containerClassNames}`}>
      <div>
        <Menu.Button className="bg-gray hover:bg-white focus:ring-core-orange-300 inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 text-sm font-bold focus:outline-none">
          {props.text}
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="cursor-pointer origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10">
          <div className="py-1">
            {props.options?.map((option, i) => (
                <Menu.Item key={i}>
                    {({ active }) => (
                    <span 
                      onClick={(e) => option.onClick(e, option, getSortType())}
                      className={classNames(
                      active ? 'bg-selection-color-200 text-gray-900' : 'text-gray-700',
                      'px-4 py-2 text-sm flex align-middle'
                      )}
                    >
                      <a className="mr-2">{option.text}</a>
                      {getIcon(option)}
                    </span>
                    )}
                </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
