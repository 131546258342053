import React from 'react'
import PropTypes from 'prop-types'
import DropdownButton from '../inputs/DropdownButton'
import { DropdownButtonOption } from "../shared/models/inputClasses.js"

ReportCard.propTypes = {
    title: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired,
    client: PropTypes.object.isRequired,
    description: PropTypes.string,
    imageSrc: PropTypes.string,
    spouse: PropTypes.object
}
  
ReportCard.defaultProps = {
    description: "",
    imageSrc: "",
    client: {},
    spouse: {}
};

export default function ReportCard(props) {

    const joint = new DropdownButtonOption("Joint", `/v1/reports/${props.route}/create/${props.client?.id}/${props.spouse?.id}`);
    const client = new DropdownButtonOption(`${props.client?.name}`, `/v1/reports/${props.route}/create/${props.client?.id}`);
    const spouse = new DropdownButtonOption(`${props.spouse?.name}`, `/v1/reports/${props.route}/create/${props.spouse?.id}`);
    const buttonOptions = props.spouse
        ? [ joint, client, spouse ]
        : [ client ];

    return (
        <div className="grid grid-cols-3 bg-white w-full min-h-[8.5rem] h-fit">
            <div className="justify-start m-4 col-span-2">
                <div className="block">
                    <div className="font-bold">
                        <span>{props.title}</span>
                    </div>
                    <div className="text-sm">
                        <span>{props.description}</span>
                    </div>
                    <div className="mt-3">
                        <DropdownButton text={"Run Report"} options={buttonOptions} />
                    </div>
                </div>
            </div>
            <div className="flex justify-center items-center m-4 col-span-1 bg-gray-100">
                <div className="w-2/5">
                    <img alt="Report Logo" src={props.imageSrc} asp-append-version="true"></img>
                </div>
            </div>
    </div>
  )
}
