import { useEffect, useState } from 'react';
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom';
import { useTheme } from '../../../contexts/ThemeContext'
import { ToastContainer } from 'react-toastify';
import dataCalls from '../../../shared/dataCalls';
import staticData from '../../../shared/staticData';
import BackButton from '../../../components/BackButton';
import utils from '../../../shared/utils';
import Skeleton from 'react-loading-skeleton';
import PreviewButtons from '../Shared/Buttons/PreviewButtons';

export default function Preview() {

    const [ isLoadingIframe, setIsLoadingIframe ] = useState(true);
    const iFrameStyle = !isLoadingIframe ? {} : {visibility: 'hidden'};
    const onIFrameLoad = (e) => {
        setIsLoadingIframe(false);
        utils.onIframeLoad(e);
    }

    const params = useParams();

    const { data, isLoading } = useQuery('longevityPreviewHtml', () => dataCalls.fetchLongevityViewModelPreview(params.id, params.spouseid), {
        refetchOnMount: true,
        refetchOnReconnect: false,
        refetchOnWindowFocus: false
    });

    const { setBackgroundClass, setJustifyClass, resetTheme } = useTheme();
    useEffect(() => {
        setBackgroundClass("bg-gray");
        setJustifyClass("");
        return () => resetTheme();
    }, []);

    const iFrameSource = params.spouseid > 0
        ? `/api/reports/longevity/preview?id=${params.id}&spouseId=${params.spouseid}` 
        : `/api/reports/longevity/preview?id=${params.id}`;

    return (
        <div className="justify-center place-content-center w-3/4 mt-6 min-h-full h-full">
            <div className="grid grid-cols-2">
                <div className="block">
                    <BackButton text="Back to Client Profile" redirectUrl={`/v1/consumers/${data?.report?.consumerId}`}/>
                    {isLoading
                        ? <h2><Skeleton /></h2>
                        : <h2>Longevity Report for {data?.names}</h2>
                    }
                </div>
                <div className="w-full flex justify-end items-center pr-4">
                    <img alt="Longevity Logo" src={staticData.getReport(1).imgSrc} className="w-12 h-16"></img>
                </div>
            </div>
            <div className="flex justify-start mt-6 mb-2">
                <h3>Report Preview</h3>
            </div>
            
            {isLoadingIframe && <Skeleton height={utils.getWindowDimensions().height * 0.7} />} 
            <div className="flex w-full flex-col">
                <iframe
                    title="Longevity Report Preview" 
                    style={iFrameStyle}
                    src={iFrameSource}
                    onLoad={(onIFrameLoad)}
                />
            </div>

            <PreviewButtons viewModel={data} reportType={1} showBackToEditButton={false} />
            <ToastContainer />
        </div>
    )
}
