import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import { useForm, FormProvider } from "react-hook-form";
import { useTheme } from '../../../contexts/ThemeContext';
import { PlusCircleIcon, ExclamationCircleIcon } from '@heroicons/react/outline';
import { ToastContainer, toast } from 'react-toastify';
import Input from '../../../inputs/Input';
import TextArea from "../../../inputs/TextArea";
import Button from "../../../inputs/Button";
import AnnualIncome from "./Components/AnnualIncome";
import RemainingBalance from "./Components/RemainingBalance";
import remoteCalculations from "../../../shared/remoteCalculations";
import utils from "../../../shared/utils";
import edit from "../../../scripts/reports/internalrateofreturn/edit";
import PropTypes from 'prop-types';
import ProjectionTable from "./Components/ProjectionTable";
import settings from "../../../shared/settings";
import "../../../styles/reports/shared/shared.css";

GuidedEdit.propTypes = {
    viewModel: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isEdit: PropTypes.bool.isRequired,
}
  
GuidedEdit.defaultProps = {

};

export default function GuidedEdit(props) {

    const [ ledger, setLedger ] = useState([]);
    const [ triggerCalculationUpdate, setTriggerCalculationUpdate ] = useState(false);
    const [ numberOfYears, setNumberOfYears ] = useState(0);

    const guidedFormMethods = useForm();
    const navigate = useNavigate();

    const { setBackgroundClass, setJustifyClass, resetTheme } = useTheme();
    useEffect(() => {
        setBackgroundClass("bg-gray");
        setJustifyClass("");
        return () => resetTheme(); 
    }, []);

    //Set initial form state on viewmodel load
    useEffect(() => {
        if(props.viewModel) {
            guidedFormMethods.setValue("reportName", props.viewModel.report.reportName);
            guidedFormMethods.reset(props.isEdit 
                ? props.viewModel.report.reportData
                : props.viewModel.defaultGuidedReportData);

            setNumberOfYears(props.isEdit 
                ? props.viewModel.report.reportData.numberOfYears
                : props.viewModel.defaultGuidedReportData.numberOfYears);
        }
        if(isValid()) setTriggerCalculationUpdate(true);
    }, [props.viewModel])

    const updateCalculations = useCallback(async () => {
        if(triggerCalculationUpdate && props.viewModel) {
            const data = guidedFormMethods.getValues();
            const scenario = {
                clientAge: props.viewModel.client.age,
                spouseAge: props.viewModel.spouse?.age ?? null,
                reportData: JSON.parse(JSON.stringify(data))
            };
            const ledgerResults = await remoteCalculations.getInternalRateOfReturnLedger(scenario);
            setLedger(ledgerResults);
            setTriggerCalculationUpdate(false);
        }
    }, [triggerCalculationUpdate]);

    useEffect(() => {
        updateCalculations();
    }, [updateCalculations]);

    const [ guidedFormErrors, setGuidedFormErrors ] = useState({ 
        errors: [],
        incomeOverlapErrorIndeces: [],
        balanceOverlapErrorIndeces: []
    });

    const onSubmit = async (data) => {
        await edit.handleEditSubmit(props.viewModel, data, toast, navigate, () => isValid(true));
    }

    const isValid = (includeBasicInputs = false) => {
        const data = guidedFormMethods.getValues();
        const validationErrors = edit.buildValidationErrors(data, true, includeBasicInputs); 
        setGuidedFormErrors(validationErrors);
        return validationErrors.errors.length === 0;
    }

    const hasFieldError = (fieldName) => {
        return guidedFormErrors.errors.filter(e => e.name === fieldName).length > 0;
    }

    const getAnnualIncomeErrors = () => {
        return guidedFormErrors.errors.filter(error => error.name.includes("annualIncomes"));
    }

    const getRemainingBalanceErrors = () => {
        return guidedFormErrors.errors.filter(error => error.name.includes("remainingBalances"));
    }

    const addIncome = () => {
        let formData = guidedFormMethods.getValues();
        let formDataClone = JSON.parse(JSON.stringify(formData));
        let lastEntryEndYear = Number(formDataClone.annualIncomes[formDataClone.annualIncomes.length - 1].endYear);
        let startYear = lastEntryEndYear === formDataClone.numberOfYears ? formDataClone.numberOfYears : lastEntryEndYear + 1;
        let endYear = formDataClone.numberOfYears;
        formDataClone.annualIncomes = [...formDataClone.annualIncomes, {
            startYear: startYear,
            endYear: endYear,
            annualIncome: 0,
            costOfLivingAdjustment: 0
        }];
        guidedFormMethods.reset(formDataClone);
        if(isValid()) setTriggerCalculationUpdate(true);

        const annualIncomesLength = formDataClone.annualIncomes.length;
        setTimeout(() => guidedFormMethods.setFocus(`annualIncomes[${annualIncomesLength - 1}].startYear`), 1);        
    }

    const removeIncome = (index) => {
        let formData = guidedFormMethods.getValues();
        let formDataClone = JSON.parse(JSON.stringify(formData));
        formDataClone.annualIncomes = formDataClone.annualIncomes.filter((item, i) => i !== index);
        guidedFormMethods.reset(formDataClone);
        if(isValid()) setTriggerCalculationUpdate(true);
    }

    const addBalance = () => {
        let formData = guidedFormMethods.getValues();
        let formDataClone = JSON.parse(JSON.stringify(formData));
        let lastEntryEndYear = Number(formDataClone.remainingBalances[formDataClone.remainingBalances.length - 1].endYear);
        let startYear = lastEntryEndYear === formDataClone.numberOfYears ? formDataClone.numberOfYears : lastEntryEndYear + 1;
        let endYear = formDataClone.numberOfYears;
        formDataClone.remainingBalances = [...formDataClone.remainingBalances, {
            startYear: startYear,
            endYear: endYear,
            annualIncome: 0,
            costOfLivingAdjustment: 0,
            reduceByAnnualIncome: true
        }];
        guidedFormMethods.reset(formDataClone);
        if(isValid()) setTriggerCalculationUpdate(true);

        const remainingBalancesLength = formDataClone.remainingBalances.length;
        setTimeout(() => guidedFormMethods.setFocus(`remainingBalances[${remainingBalancesLength - 1}].startYear`), 1); 
    }

    const removeBalance = (index) => {
        let formData = guidedFormMethods.getValues();
        let formDataClone = JSON.parse(JSON.stringify(formData));
        formDataClone.remainingBalances = formDataClone.remainingBalances.filter((item, i) => i !== index);
        guidedFormMethods.reset(formDataClone);
        if(isValid()) setTriggerCalculationUpdate(true);
    }

    const updateFormDataItem = ({name, value}, updateCalculations = true, validateBasicInputs = false) => {
        if(value === "" && name !== "notes" && name !== "reportName") {
            return;
        }
        guidedFormMethods.setValue(name, value);
        if(isValid(validateBasicInputs) && updateCalculations) setTriggerCalculationUpdate(true);
    }

    const handleUpdateNumberOfYears = ({value}, updateCalculations = true) => {
        const newNumberOfYears = Number(value);
        const currentNumberOfYears = numberOfYears;
        let formData = guidedFormMethods.getValues();
        let formDataClone = JSON.parse(JSON.stringify(formData));
        formDataClone.annualIncomes.forEach(ai => {
            if(ai.endYear === currentNumberOfYears || ai.endYear > newNumberOfYears) {
                ai.endYear = newNumberOfYears;
            }
        });
        formDataClone.remainingBalances.forEach(rb => {
            if(rb.endYear === currentNumberOfYears || rb.endYear > newNumberOfYears) {
                rb.endYear = newNumberOfYears;
            }
        });
        guidedFormMethods.reset(formDataClone);
        setNumberOfYears(value);
        if(isValid() && updateCalculations) setTriggerCalculationUpdate(true);
    }

  return (
    <div>
        <div className="grid grid-cols-2">
            <div className="mr-2">
                <FormProvider {...guidedFormMethods}>
                    <form autoComplete="off">
                        <Input 
                            type="hidden" 
                            name="isGuidedEntry" 
                            value={true} 
                        />
                        <Input
                            type="text"
                            name="reportName"
                            label={"Report Name"}
                            onChange={(e) => updateFormDataItem(e.target, false, true)}
                            containerClassNames={"py-3"}
                            hasValidationError={hasFieldError("reportName")}
                            autoFocus={true}
                        />
                        <Input
                            type="number"
                            name="numberOfYears"
                            label={"Number Of Years"}
                            conditionalLabel={`(Maximum of ${settings.irrMaxLedgerLength} years per page)`}
                            showConditionalLabel={numberOfYears > settings.irrMaxLedgerLength}
                              onInput={(e) => {
                                  e.target.value = Math.round(e.target.value);
                              }
                              }
                              onChange={(e) => updateFormDataItem(e.target)}
                            onBlur={(e) => handleUpdateNumberOfYears(e.target)}
                            containerClassNames={"py-3"}
                            hasValidationError={hasFieldError("numberOfYears")}
                        />
                        <Input
                            type="number"
                            name="startingBalance"
                            label={"Starting Balance"}
                            symbol="$"
                              onInput={(e) => {
                                  e.target.value = Math.round(e.target.value);
                              }
                              }
                            onChange={(e) => updateFormDataItem(e.target)}
                            containerClassNames={"py-3"}
                            hasValidationError={hasFieldError("startingBalance")}
                        />
                        {(guidedFormMethods.getValues("annualIncomes") ?? []).map((_, i) => (
                            <AnnualIncome 
                                key={i} 
                                index={i} 
                                onUpdate={updateFormDataItem} 
                                onRemove={removeIncome} 
                                hasAgeOverlapError={guidedFormErrors.incomeOverlapErrorIndeces.includes(i)}
                                fieldErrors={getAnnualIncomeErrors()}
                            />
                        ))}
                                         
                        <span tabIndex={0} onClick={addIncome} onKeyUp={(e) => e.key === "Enter" && addIncome()} className="flex justify-center items-center text-core-orange-400 font-bold hover:underline cursor-pointer mt-2">
                            <PlusCircleIcon className="h-6 mb-1 mr-1" />
                            <span>Add Another Annual Income Period</span>
                        </span>

                        {(guidedFormMethods.getValues("remainingBalances") ?? []).map((_, i) => (
                            <RemainingBalance 
                                key={i}
                                index={i}
                                onUpdate={updateFormDataItem}
                                onRemove={removeBalance}
                                hasAgeOverlapError={guidedFormErrors.balanceOverlapErrorIndeces.includes(i)}
                                fieldErrors={getRemainingBalanceErrors()}
                            />
                        ))}
                        
                        <span tabIndex={0} onClick={addBalance} onKeyUp={(e) => e.key === "Enter" && addBalance()} className="flex justify-center items-center text-core-orange-400 font-bold hover:underline cursor-pointer mt-2">
                            <PlusCircleIcon className="h-6 mb-1 mr-1" />
                            <span>Add Another Remaining Balance Period</span>
                        </span>

                        <div className="mt-6">
                            <TextArea
                                name="notes"
                                label="Notes"
                                rows={6}
                                optional={true}
                                maxCharacterCount={500}
                                onChange={(e) => updateFormDataItem(e.target, false)}
                                hasValidationError={hasFieldError("notes")}
                            />
                        </div>
                        <div className="flex justify-end mt-4 mb-4">
                            <Button clickHandler={guidedFormMethods.handleSubmit((data) => onSubmit(data))} text="Save and Preview" classNames="bg-core-orange-400 ml-1"/>
                        </div>
                    </form>
                </FormProvider>
            </div>
            <div className="ml-2 mt-2">
                <div className="flex justify-center">
                    <span className="font-bold">Report Preview</span>
                </div>
                <div className={`${guidedFormErrors.errors.length > 0 ? "blur-children" : ""} bg-white min-h-max ml-2 relative`}>
                    {guidedFormErrors.errors.length > 0 && (
                        <div className="shadow-md rounded-lg preview-error-container unblur">
                            <div className="p-4">
                                <div className="flex items-center">
                                    <ExclamationCircleIcon className="w-7 h-7 text-red-500"></ExclamationCircleIcon>
                                    <span className="ml-2 font-bold">Form Errors</span>
                                </div>
                                <div className="block">
                                    <div>Resolve the errors to generate a preview</div>
                                    <ul className="ml-4">
                                        {guidedFormErrors.errors.map((error, i) => (
                                            <li key={i} className="text-sm list-disc">{error.message}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="p-4">
                        <div className="grid pt-4">
                            <span className="font-bold">Internal Rate of Return</span>
                            <span className="mt-2">Starting Balance: {utils.formatAsCurrency(guidedFormMethods.getValues("startingBalance") ?? 0, 0)}</span>
                        </div>
                        <ProjectionTable ledger={ledger} viewModel={props.viewModel} />
                        <div className="grid pt-4">
                            <span className="font-bold">Additional Notes</span>
                            <span className="mt-2 text-sm">{guidedFormMethods.getValues("notes")}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ToastContainer />
    </div>
  )
}
