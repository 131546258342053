import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import CircleWithWedge from '../../../../components/CircleWithWedge';
import utils from '../../../../shared/utils';
import InternalRateOfReturnChart from './InternalRateOfReturnChart';
import PreviewHeader from  '../../Shared/PreviewHeader';
import ProjectionTable from './ProjectionTable';


PreviewPage.propTypes = {
    viewModel: PropTypes.object.isRequired,
    fullLedger: PropTypes.array.isRequired,
    ledger: PropTypes.array.isRequired,
    lifeExpectancyLedgerEntries: PropTypes.object.isRequired,
    isFirstPage: PropTypes.bool.isRequired
}
  
PreviewPage.defaultProps = {
    
};
  
export default function PreviewPage(props) {

    const [keyItemStyle, setKeyItemStyle] = useState({});
    useEffect(() => {
        setKeyItemStyle({"margin": "auto"});
    }, []);

  return (
    <div>       
        {!props.isFirstPage && (<div className="page-break" style={{'pageBreakBefore': 'always'}}></div>)} 
        <PreviewHeader
            reportName={props.viewModel.report.reportName}
            clientNames={props.viewModel.names}
            reportDate={props.viewModel.report.updatedAt}
            advisorName={props.viewModel.advisorName}
            reportType={2}
        />
        <div className="grid grid-cols-12 px-6 pb-6">
            <div className="mr-3 col-span-5">
                <div className="bg-white min-h-max ml-2">
                    <div className="pt-2 pb-2 flex justify-between align-middle">
                        <h4 className="mt-2">Starting Balance: {utils.formatAsCurrency(props.viewModel.report.reportData.startingBalance ?? 0, 0)}</h4>
                        {!props.isFirstPage && (<h4 className="mt-2">(Continued)</h4>)}
                    </div>
                    <ProjectionTable ledger={props.ledger} viewModel={props.viewModel} />
                </div>
            </div>
            <div className="ml-3 col-span-7">
                <div className="mt-10 chart-container h-[45vh] w-[95%] flex justify-center">
                    {props.viewModel && <InternalRateOfReturnChart viewModel={props.viewModel} ledger={props.fullLedger} />}
                </div>
                <div className="irr-key-grid flex justify-between text-justify mt-5">
                    {props.lifeExpectancyLedgerEntries.fiftieth && (
                        <div className="bg-slate-100 rounded-sm core-orange-500 mx-1 irr-key-item w-fit p-2" style={keyItemStyle}>
                            <div className="flex justify-center ml-3 mt-1">
                                <span className={`ml-2`}>
                                    <b>{utils.formatDecimalAsPercentage(props.lifeExpectancyLedgerEntries.fiftieth.internalRateOfReturn, 1)} IRR</b> at Age
                                </span>
                                <CircleWithWedge containerClassName={"mx-2"} wedgeStartingAngle={50} text={props.viewModel.lifeExpectancies[50]} heightStyle={"32px"} widthStyle={"32px"}/>
                            </div>
                            <div className="flex justify-center mx-2">
                                <div className="flex items-center">
                                    <span className={`font-bold ml-2 text-default ${props.lifeExpectancyLedgerEntries.fiftieth.totalValue > 99999999 ? "irr-key-item-text-sm" : ""}`}>
                                        {utils.formatAsCurrency(props.lifeExpectancyLedgerEntries.fiftieth.totalValue, 0)} Total Value
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                    {props.lifeExpectancyLedgerEntries.twentyfifth && (
                        <div className="bg-slate-100 rounded-sm core-blue-500 mx-1 irr-key-item w-fit p-2" style={keyItemStyle}>
                            <div className="flex justify-center ml-3 mt-1">
                                <span className={`ml-2`}>
                                    <b>{utils.formatDecimalAsPercentage(props.lifeExpectancyLedgerEntries.twentyfifth.internalRateOfReturn, 1)} IRR</b> at Age
                                </span>
                                <CircleWithWedge containerClassName={"mx-2"} wedgeStartingAngle={75} text={props.viewModel.lifeExpectancies[25]} heightStyle={"32px"} widthStyle={"32px"} useAlternateColors={true}/>
                            </div>
                            <div className="flex justify-center mx-2">
                                <div className="flex items-center">
                                    <span className={`font-bold ml-2 text-default ${props.lifeExpectancyLedgerEntries.twentyfifth.totalValue > 99999999 ? "irr-key-item-text-sm" : ""}`}>
                                        {utils.formatAsCurrency(props.lifeExpectancyLedgerEntries.twentyfifth.totalValue, 0)} Total Value
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                    {props.lifeExpectancyLedgerEntries.tenth && (
                        <div className="bg-slate-100 rounded-sm core-pink-500 mx-1 irr-key-item w-fit p-2" style={keyItemStyle}>
                            <div className="flex justify-center ml-3 mt-1">
                                <span className={`ml-2`}>
                                    <b>{utils.formatDecimalAsPercentage(props.lifeExpectancyLedgerEntries.tenth.internalRateOfReturn, 1)} IRR</b> at Age
                                </span>
                                <CircleWithWedge containerClassName={"mx-2"} wedgeStartingAngle={90} text={props.viewModel.lifeExpectancies[10]} heightStyle={"32px"} widthStyle={"32px"} useTertiaryColors={true}/>
                            </div>
                            <div className="flex justify-center mx-2">
                                <div className="flex items-center">
                                    <span className={`font-bold ml-2 text-default ${props.lifeExpectancyLedgerEntries.tenth.totalValue > 99999999 ? "irr-key-item-text-sm" : ""}`}>
                                        {utils.formatAsCurrency(props.lifeExpectancyLedgerEntries.tenth.totalValue, 0)} Total Value
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="grid pt-2">
                    <span className="font-bold">About Internal Rate of Return (IRR)</span>
                    <span className="mt-1 text-sm">
                        Internal Rate of Return is an actuarial metric to calculate the profitability and 
                        attractiveness of an income stream. This time value of money calculation relies on the same concept as Net Present
                        Value and allows you to analyze and compare varying cash flow streams over time.
                    </span>
                    {props.viewModel.report.reportData.notes?.length > 0 &&      
                        <span className="grid">
                            <span className="font-bold mt-1">Additional Notes</span>
                            <span className={props.viewModel.report.reportData.notes.length > 300 ? "text-xs" : "text-sm"}>{props.viewModel.report.reportData.notes}</span>
                        </span>
                    }
                    <span className="mt-1 text-xs italic">
                        Disclosure: Annuities are long-term insurance products primarily designed for retirement income. Early withdrawals 
                        from an annuity may result in loss of principal and credited interest due to surrender charges. Withdrawals from 
                        an annuity are taxed as ordinary income and, if taken prior to age 59 ½, an additional 10% federal tax may apply. 
                        The information contained herein is hypothetical and not based on any specific annuity product or carrier. Life insurance 
                        and long term care insurance may provide tax free treatment of benefits along with the transfer of risk. See insurance 
                        carrier materials for criteria and consult with a tax and legal professional.
                    </span>
                </div>
            </div>
        </div>
    </div>
  )
}
