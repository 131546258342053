
import { useEffect } from 'react';
import { useTheme } from '../../contexts/ThemeContext';
import AdvisorUsageTable from './Components/AdvisorUsageTable';
import GeneralAnalytics from './Components/GeneralAnalytics';
import InternalRateOfReturnAnalytics from './Components/InternalRateOfReturnAnalytics';

export default function Analytics() {

  const { setBackgroundClass, setJustifyClass, resetTheme } = useTheme();
  useEffect(() => {
      setBackgroundClass("bg-gray");
      setJustifyClass("");
      return () => {
          resetTheme();
      }
  }, []);

  return (
      <div className="justify-center place-content-center w-3/4 p-8 bg-white">
        <GeneralAnalytics />

        <h3 className="my-4">Advisor App Usage Analytics</h3>
        <AdvisorUsageTable />

        <h3 className="my-4">Internal Rate of Return Analytics</h3>
        <InternalRateOfReturnAnalytics />
      </div>
  )
}
