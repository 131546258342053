import React from 'react'
import PropTypes from 'prop-types';
import utils from '../../../../shared/utils';
import shared from '../../../../scripts/reports/internalrateofreturn/shared';
import "../../../../styles/reports/internalrateofreturn/table.css";


ProjectionTable.propTypes = {
    ledger: PropTypes.array.isRequired,
    viewModel: PropTypes.object.isRequired
}
  
ProjectionTable.defaultProps = {
    
};

export default function ProjectionTable(props) {
  return (
    <div>
        <table className="internal-rate-of-return-table w-full">
            <thead className="bg-slate-200">
                <tr className="text-left">
                    <th className="text-center w-[0%]">Year</th>
                    <th className="text-center w-[0%]">{props.viewModel.client.firstName}'s Age</th>
                    {props.viewModel?.spouse && (<th className="text-center w-[0%]">{props.viewModel.spouse.firstName}'s Age</th>)}
                    <th className="text-right">Annual Income</th>
                    <th className="text-right">Remaining Balance</th>
                    <th className="text-right">Total Income</th>
                    <th className="text-right">Total Value</th>
                    <th className="text-center min-w-[5%]">IRR by Year</th>
                </tr>
            </thead>
            <tbody className="bg-white">
                {props.ledger?.length > 0 && (
                    props.ledger?.map((entry) => (
                        <tr key={entry.year} className={shared.getLedgerRowBackgroundClass(entry, props.viewModel)}>
                            <td className="text-center">{entry.year}</td>
                            <td className="text-center">{entry.clientAge}</td>
                            {props.viewModel.spouse && (<td className="text-center">{entry.spouseAge}</td>)}
                            <td className="text-right">{utils.formatAsCurrency(entry.annualIncome, 0)}</td>
                            <td className="text-right">{utils.formatAsCurrency(entry.remainingBalance, 0)}</td>
                            <td className="text-right">{utils.formatAsCurrency(entry.totalIncome, 0)}</td>
                            <td className="text-right">{utils.formatAsCurrency(entry.totalValue, 0)}</td>
                            <td className="text-center">{utils.formatDecimalAsPercentage(entry.internalRateOfReturn, 1)}</td>
                        </tr>
                )))}
                {(!props.ledger || props.ledger.length === 0) && (
                    <tr className="text-center">
                        <td colSpan={8} className="pt-1">Not yet calculated</td>
                    </tr>
                )}
            </tbody>
        </table>
    </div>
  )
}
