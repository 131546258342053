import settings from "./settings";
import axios from "axios";
import staticData from "./staticData";

const postConfig = {
    headers: {
        'Content-Type': 'application/json'
    },
};

const putConfig = {
    headers: {
        'Content-Type': 'application/json'
    },
};

const getConfig = {
    signal: (new AbortController()).signal
}

class dataCalls {

    //Searches
    static consumerSearch = async (searchTerm) => {
        const response = await axios.get("/api/consumers/search?searchTerm=" + searchTerm);
        return response.data;
    }

    static consumerSingleSearch = async (searchTerm, skipId = null) => {
        let url = `/api/consumers/single/search?searchTerm=${searchTerm}`;
        if(skipId) url += `&skipId=${skipId}`;

        const response = await axios.get(url);
        return response.data;
    }

    static advisorSearch = async (searchTerm) => {
        const response = await axios.get("/api/advisors/search?searchTerm=" + searchTerm);
        return response.data;
    }

    static fetchAdvisor = async (id) => {
        const response = await axios.get("/api/advisors?id=" + id);
        return response.data;
    }

    static fetchRecentlyViewedAdvisors = async () => {
        const count = settings.recentlyViewedCount;
        const response = await axios.get("/api/advisors/recentlyviewed?count=" + count, getConfig);
        return response.data;
    }

    static fetchCurrentAdvisor = async () => {
        const response = await axios.get("/api/users/currentadvisor");
        return response.data;
    }

    static fetchCurrentUser = async () => {
        const response = await axios.get("/api/users/currentuser");
        return response.data;
    }
    
    static forceReauthentication = async () => {
        const response = await axios.get("/auth/templogin");
        return response.data;
    }

    static logActivity = async (path) => {
        const response = await axios.post("/api/users/activity", path, postConfig);
        return response.data;
    }

    static fetchConsumer = async (id) => {
        const response = await axios.get("/api/consumers?id=" + id);
        return response.data;
    }

    static fetchAdvisors = async () => {
        const response = await axios.get("/api/advisors/examplequery");
        return response.data;
    }

    static fetchRecentlyViewedConsumers = async () => {
        const count = settings.recentlyViewedCount;
        const response = await axios.get("/api/consumers/recentlyviewed?count=" + count);
        return response.data;
    }

    static createConsumer = async (consumer) => {
        const response = await axios.post("/api/consumers", consumer, postConfig);
        return response;
    }

    static updateConsumer = async (consumer) => {
        const response = await axios.put("/api/consumers", consumer, putConfig);
        return response;
    }

    static createRecentlyViewedConsumer = async (consumerId) => {
        const response = await axios.post("/api/consumers/recentlyviewed", consumerId, postConfig);
        return response;
    }

    static createRecentlyViewedAdvisor = async (advisorId) => {
        const response = await axios.post("/api/advisors/recentlyviewed", advisorId, postConfig);
        return response;
    }

    //Authorization Calls
    static updateAdvisorIdClaim = async (selectedId) => {
        const response = await axios.get(`/auth/claims/advisor?advisorId=${selectedId}`);
        return response.data;
    }

    static updateRelationship = async(consumerId, spouseId, deleteRelationship = false) => {
        const data = { consumer1Id: consumerId, consumer2Id: spouseId, deleteRelationship: deleteRelationship };
        const response = await axios.put("/api/consumers/relationship", JSON.stringify(data), putConfig);
        return response;
    }

    //Analytics
    static getGeneralAnalytics = async () => {
        const response = await axios.get("/api/analytics/general");
        return response.data;
    }

    static getAdvisorUsageAnalytics = async () => {
        const response = await axios.get("/api/analytics/advisorusage");
        return response.data;
    }

    static getInternalRateOfReturnAnalytics = async () => {
        const response = await axios.get("/api/analytics/internalrateofreturn");
        return response.data;
    }

    //Reports
    static getConsumerReports = async (consumerId, spouseId = null) => {
        let link = `/api/consumers/reports?consumerId=${consumerId}`;
        if(spouseId) {
            link += `&spouseId=${spouseId}`;
        }
        const response = await axios.get(link);
        return response.data;
    }

    static getReportPdf = async(id) => {
        const response = await axios.get(`/api/reports/pdf?id=${id}`, {
            headers: {
                responseType: 'blob'
            }
        });
        return await response.data;
    }

    static saveDraft = async (report) => {
        const response = await axios.post("/api/reports/draft", { json: JSON.stringify(report) }, postConfig);
        return response;
    }

    static finalizeLongevity = async (viewModel) => {
        const report = staticData.getReport(1);
        const link = `/api/reports/${report.route}/finalize/`;
        const response = await axios.post(link, viewModel, postConfig);
        return response;
    }

    static finalizeReport = async (id, reportRoute) => {
        const link = `/api/reports/${reportRoute}/finalize`;
        const response = await axios.post(link, id, postConfig);
        return response;
    }

    static copyReport = async (id) => {
        const response = await axios.post(`/api/reports/copy`, id, postConfig);
        return response;
    }

    static deleteReport = async (id) => {
        const response = await axios.patch(`/api/reports/delete`, id, postConfig);
        return response;
    }

    //Longevity Calls
    static fetchLongevityViewModelPreview = async (id, spouseId) => {
        let link = `/api/reports/longevity/create?id=${id}`;
        if(spouseId) {
            link += `&spouseId=${spouseId}`
        }
        const response = await axios.get(link);
        return await response.data;
    }

    //Internal Rate Of Return Calls
    static fetchInternalRateOfReturnViewModelCreate = async (id, spouseId) => {
        let link = `/api/reports/internalrateofreturn/create?id=${id}`;
        if(spouseId) {
            link += `&spouseId=${spouseId}`
        }
        const response = await axios.get(link);
        return await response.data;
    }

    static fetchInternalRateOfReturnViewModelEdit = async (id) => {
        let link = `/api/reports/internalrateofreturn/edit?id=${id}`;
        const response = await axios.get(link);
        return await response.data;
    }

    //Sequence of Returns Calls
    static fetchSequenceOfReturnsViewModelCreate = async (id, spouseId) => {
        let link = `/api/reports/sequenceofreturns/create?id=${id}`;
        if(spouseId) {
            link += `&spouseId=${spouseId}`
        }
        const response = await axios.get(link);
        return await response.data;
    }

    static fetchSequenceOfReturnsViewModelEdit = async (id) => {
        let link = `/api/reports/sequenceofreturns/edit?id=${id}`;
        const response = await axios.get(link);
        return await response.data;
    }

    //Inflation Calls
    static fetchInflationViewModelCreate = async (id, spouseId) => {
        let link = `/api/reports/inflation/create?id=${id}`;
        if (spouseId) {
            link += `&spouseId=${spouseId}`
        }
        const response = await axios.get(link);
        return await response.data;
    }

    static fetchInflationViewModelEdit = async (id) => {
        let link = `/api/reports/inflation/edit?id=${id}`;
        const response = await axios.get(link);
        return await response.data;
    }

    //General Report Calls
    static fetchReportViewModel = async (id) => {
        let link = `/api/reports/report?id=${id}`;
        const response = await axios.get(link);
        return await response.data;
    }


    static fetchActivityLogs = async (currentPageNumber, numberPerPage, searchQuery) => {
        let link = `/api/activities?currentPage=${currentPageNumber}&numberPerPage=${numberPerPage}&searchQuery=${searchQuery}`;
        const response = await axios.get(link);
        return await response.data;
    }
}

export default dataCalls;