import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ArrowLeftIcon } from'@heroicons/react/outline';

BackButton.propTypes = {
  redirectUrl: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func
}

BackButton.defaultProps = {
  redirectUrl: undefined,
  text: "Back",
  onClick: undefined
};

export default function BackButton(props) {

    const navigate = useNavigate();

    const handleNavigation = () => {
      const redirect = props.redirectUrl ?? -1;
      navigate(redirect);
    }

    return (
      <button className="flex justify-start align-middle" onClick={props.onClick ?? handleNavigation}>
        <ArrowLeftIcon className="w-7 m-auto" />
        <span className="pl-2">{props.text}</span>
      </button>
    );
}