import React, { useEffect, useState, useCallback, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { useForm, FormProvider } from "react-hook-form";
import { useTheme } from '../../../contexts/ThemeContext';
import { ExclamationCircleIcon, DuplicateIcon } from'@heroicons/react/solid';
import { ToastContainer, toast } from 'react-toastify';
import Input from '../../../inputs/Input';
import TextArea from "../../../inputs/TextArea";
import Button from "../../../inputs/Button";
import remoteCalculations from "../../../shared/remoteCalculations";
import utils from "../../../shared/utils";
import Preview from "./PreviewOld";
import shared from "../../../scripts/reports/internalrateofreturn/shared";
import edit from "../../../scripts/reports/internalrateofreturn/edit";
import PropTypes from 'prop-types';
import CustomEditSlideOver from "./Components/CustomEditSlideOver";
import Modal from "../../../components/Modal";
import CopyPasteHelp from "./Components/CopyPasteHelp";
import settings from "../../../shared/settings";

CustomEdit.propTypes = {
    viewModel: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isEdit: PropTypes.bool.isRequired
}
  
CustomEdit.defaultProps = {

};

export default function CustomEdit(props) {

    const [ customLedger, setCustomLedger ] = useState([]);
    const [ customLedgerYears, setCustomLedgerYears ] = useState([...Array(30).keys()]);
    const [ triggerCalculationUpdate, setTriggerCalculationUpdate ] = useState(false);
    const [ isHelpModalOpen, setIsHelpModalOpen ] = useState(false);
    const [ numberOfYears, setNumberOfYears ] = useState(30);

    const [ editIncomes, setEditIncomes ] = useState(false);
    const [ editBalances, setEditBalances ] = useState(false);

    const customFormMethods = useForm();
    const navigate = useNavigate();

    const { setBackgroundClass, setJustifyClass, resetTheme } = useTheme();
    useEffect(() => {
        setBackgroundClass("bg-gray");
        setJustifyClass("");
        return () => resetTheme(); 
    }, []);

    useEffect(() => {
        if(props.viewModel) {
            setCustomLedger(props.viewModel.customLedger);
            customFormMethods.setValue("reportName", props.viewModel.report.reportName);
            if(!props.isEdit) {
                setCustomLedgerYears([...Array(props.viewModel.defaultCustomReportData.numberOfYears).keys()]);
                customFormMethods.reset(props.viewModel.defaultCustomReportData); 
                setNumberOfYears(props.viewModel.defaultCustomReportData.numberOfYears);
            } else {
                setCustomLedgerYears([...Array(props.viewModel.report.reportData.numberOfYears).keys()]);
                customFormMethods.reset(props.viewModel.report.reportData);
                setNumberOfYears(props.viewModel.report.reportData.numberOfYears);  
            }
            if(isValid()) setTriggerCalculationUpdate(true);   
        }

    }, [props.viewModel])

    const tableRef = useRef(null);
    useEffect(() => {
        if(tableRef && tableRef.current) {
            tableRef.current.addEventListener('paste', handlePaste);
        }
    }, []);

    const updateCustomCalculations = useCallback(async () => {
        if(triggerCalculationUpdate && props.viewModel) {
            const formData = customFormMethods.getValues();
            const scenario = {
                clientAge: props.viewModel.client.age,
                spouseAge: props.viewModel.spouse?.age ?? null,
                reportData: JSON.parse(JSON.stringify(formData))
            };
            const ledgerResults = await remoteCalculations.getInternalRateOfReturnLedger(scenario);
            setCustomLedger(ledgerResults);
            setTriggerCalculationUpdate(false);
        }
    }, [triggerCalculationUpdate]);

    useEffect(() => {
        updateCustomCalculations();
    }, [updateCustomCalculations]);

    const getFormData = () => {
        return customFormMethods.getValues();
    }

    const handlePaste = (e) => {
        // Stop data actually being pasted into div
        e.stopPropagation();
        e.preventDefault();

        // Get pasted data via clipboard API
        const clipboardData = e.clipboardData || window.clipboardData;
        const pastedData = clipboardData.getData('Text');

        try {
            const startingIndex = e.target.dataset.index ? Number(e.target.dataset.index) : 0;
            const resultArray = parsePastedData(pastedData);

            if(resultArray.length >= 2) {
                updateFormOnPaste(resultArray[0], resultArray[1], startingIndex);
            } else if(resultArray.length === 1) {
                const isBalanceArray = e.target.name && e.target.name.includes("remainingBalances");
                if(isBalanceArray) {
                    updateFormOnPaste(null, resultArray[0], startingIndex);
                } else {
                    updateFormOnPaste(resultArray[0], null, startingIndex);
                }
            }
        } catch (ex) {
            toast("The pasted data did not match the required format.", { duration: 2000, position: 'bottom-center' });
        }
    }

    const parsePastedData = (pastedData) => {
        var rows = pastedData.split("\n");
        rows.pop(); 

        let numberOfCells = rows[0].split("\t").length;   
        let resultArray = [];
        for (let i = 0; i < numberOfCells; i++) {
            resultArray.push([]);
        }

        rows.forEach((row) => {
            var cells = row.replaceAll("$", "").replaceAll(",", "").replace("\r", "").split("\t");
            cells.forEach((cell, j) => resultArray[j].push(Number(cell))); 
        })
        return resultArray;
    }

    const updateFormOnPaste = (incomeArray, balanceArray, startingIndex) => {
        //Update form length
        let updatedFormLength = 0;
        const currentFormLength = customLedgerYears.length;
        const maxArrayLength = Math.max(incomeArray?.length ?? 0, balanceArray?.length ?? 0);
        if(maxArrayLength + startingIndex > currentFormLength) {
            updatedFormLength = maxArrayLength + startingIndex;
        }
        if(updatedFormLength > currentFormLength) {
            updateNumberOfYears({name: "numberOfYears", value: updatedFormLength});
        }

        //Update form incomes
        if(incomeArray) {
            incomeArray.forEach((amount, i) => {
                customFormMethods.setValue(`annualIncomes[${i + startingIndex}].annualIncome`, amount);
            });
        }

        //Update form balances
        if(balanceArray) {
            balanceArray.forEach((amount, i) => {
                customFormMethods.setValue(`remainingBalances[${i + startingIndex}].remainingBalance`, amount);
            });
        }
        if(isValid()) setTriggerCalculationUpdate(true);
    }

    const updateFormDataItem = ({name, value}, updateCalculations = true) => {
        if(value === "" && name !== "notes" && name !== "reportName") {
            return;
        }
        customFormMethods.setValue(name, value);
        if(isValid() && updateCalculations) setTriggerCalculationUpdate(true);
    }

    const updateNumberOfYears = ({name, value}) => {
        if(value === "") {
            return;
        }

        const currentValue = customLedgerYears.length;
        
        value = Number(value);
        updateFormDataItem({name: name, value: value});

        if(value > 0 && value <= 100) {
            if(value > currentValue) {
                addIncomes(value - currentValue);
                addBalances(value - currentValue);
            } else if(value < currentValue) {
                const formData = customFormMethods.getValues();
                let formDataClone = JSON.parse(JSON.stringify(formData));
                formDataClone.annualIncomes = formDataClone.annualIncomes.filter((_, i) => i < value);
                formDataClone.remainingBalances = formDataClone.remainingBalances.filter((_, i) => i < value);
                customFormMethods.reset(formDataClone);
            }
            setCustomLedgerYears([...Array(value).keys()]);
            setNumberOfYears(value);
        }

        if(isValid()) setTriggerCalculationUpdate(true);
    }

    const addIncomes = (length) => {
        const formData = customFormMethods.getValues();
        let formDataClone = JSON.parse(JSON.stringify(formData));
        const lastEntryEndYear = Number(formDataClone.annualIncomes[formDataClone.annualIncomes.length - 1].endYear);
        const newIncomes = [...Array(length).keys()].map(i => {
            return {
                startYear: lastEntryEndYear + i + 1,
                endYear: lastEntryEndYear + i + 1,
                annualIncome: 0,
                costOfLivingAdjustment: 0
            }
        });
        formDataClone.annualIncomes = [...formDataClone.annualIncomes, ...newIncomes];
        customFormMethods.reset(formDataClone);
    }

    const addBalances = (length) => {
        const formData = customFormMethods.getValues();
        let formDataClone = JSON.parse(JSON.stringify(formData));
        const lastEntryEndYear = Number(formDataClone.remainingBalances[formDataClone.remainingBalances.length - 1].endYear);
        const newBalances = [...Array(length).keys()].map(i => {
            return {
                startYear: lastEntryEndYear + i + 1,
                endYear: lastEntryEndYear + i + 1,
                remainingBalance: 0,
                growthRate: 0
            }
        });
        formDataClone.remainingBalances = [...formDataClone.remainingBalances, ...newBalances];
        customFormMethods.reset(formDataClone);
    }

    const onSubmit = async (data) => {
        await edit.handleEditSubmit(props.viewModel, data, toast, navigate, () => isValid(true));
    }

    const [ customFormErrors, setCustomFormErrors ] = useState({ 
        errors: []
    });

    const isValid = (includeBasicInputs = false) => {
        const validationErrors = edit.buildValidationErrors(getFormData(), false, includeBasicInputs);
        setCustomFormErrors(validationErrors);
        return validationErrors.errors.length === 0;
    }

    const hasFieldError = (fieldName) => {
        const errorObject = customFormErrors;
        return errorObject.errors.filter(e => e.name === fieldName).length > 0;
    }

    const editAnnualIncomes = () => {
        setEditIncomes(true);
    }

    const editRemainingBalances = () => {
        setEditBalances(true);
    }

    const applyIncomes = (income) => {
        const startIndex = Number(income.startYear) - 1;
        const endIndex = Number(income.endYear) - 1;

        let incomeAmounts = [];
        for (let i = startIndex; i <= endIndex; i++) {
            let incomeAmount = Number(income.annualIncome) * (1 + Number(income.costOfLivingAdjustment) / 100) ** (i - startIndex);
            incomeAmount = Math.round(incomeAmount);
            incomeAmounts.push(incomeAmount);
        }
        const formData = customFormMethods.getValues();
        let formDataClone = JSON.parse(JSON.stringify(formData));
        incomeAmounts.forEach((amount, i) => formDataClone.annualIncomes[i + startIndex].annualIncome = amount);
        customFormMethods.reset(formDataClone);

        if(isValid()) setTriggerCalculationUpdate(true);
    }

    const applyBalances = (balance) => {
        const startIndex = Number(balance.startYear) - 1;
        const endIndex = Number(balance.endYear) - 1;

        const formData = customFormMethods.getValues();
        let formDataClone = JSON.parse(JSON.stringify(formData));

        //Calculate balance amounts 
        let balanceAmounts = [];
        if(balance.reduceByAnnualIncome) {
            let previousBalanceAmount = startIndex === 0 
                ? formDataClone.startingBalance 
                : formDataClone.remainingBalances[startIndex - 1]?.remainingBalance ?? 0;
            for (let i = startIndex; i <= endIndex; i++) {
                let annualIncomeAmount = formDataClone.annualIncomes[i]?.annualIncome ?? 0;
                let balanceAmount = Math.max(previousBalanceAmount - annualIncomeAmount, 0);
                balanceAmount *= (1 + Number(balance.growthRate) / 100);
                balanceAmount = Math.round(balanceAmount);
                previousBalanceAmount = balanceAmount;
                balanceAmounts.push(balanceAmount);
            }
        } else {
            for (let i = startIndex; i <= endIndex; i++) {
                let balanceAmount = Number(balance.remainingBalance) * (1 + Number(balance.growthRate) / 100) ** (i - startIndex);
                balanceAmount = Math.round(balanceAmount);
                balanceAmounts.push(balanceAmount);
            }
        }

        //Update form
        balanceAmounts.forEach((amount, i) => formDataClone.remainingBalances[i + startIndex].remainingBalance = amount);
        customFormMethods.reset(formDataClone);

        if(isValid()) setTriggerCalculationUpdate(true);
    }

    const showHelpWindow = () => {
        setIsHelpModalOpen(true);
    }

  return (
    <div>
        <div>
            <div className="flex justify-between mt-3">
                <span className="font-bold">Fully customize each of the values</span> 
                <span tabIndex={0} className="flex text-core-orange-400 font-bold hover:underline cursor-pointer ml-4" onClick={showHelpWindow} onKeyUp={(e) => e.key === "Enter" && showHelpWindow()}>Quick paste
                    <DuplicateIcon className="w-7 h-7 ml-2"/>
                </span>
            </div>
            <div className="bg-white">
                <div className="p-4">
                    {customFormErrors.errors.length > 0 && (
                        <div className="rounded-lg border border-red-500 bg-red-50 px-4 pt-3 pb-4 w-1/2 mb-2">
                            <div className="flex items-center">
                                <ExclamationCircleIcon className="w-6 h-6 text-red-500"></ExclamationCircleIcon>
                                <span className="ml-2 font-bold">Form Errors</span>
                            </div>
                            <div className="block ml-6">
                                <ul className="">
                                    {customFormErrors.errors.map((error, i) => (
                                        <li key={i} className="text-sm list-disc">{error.message}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    )}
                    <FormProvider {...customFormMethods}>
                        <form autoComplete="off">
                            <div className="flex">
                                <Input 
                                    type="hidden" 
                                    name="isGuidedEntry" 
                                    value={false} 
                                />
                                <Input
                                    type="text"
                                    name="reportName"
                                    label={"Report Name"}
                                    onChange={(e) => updateFormDataItem(e.target, false)}
                                    containerClassNames={"py-3 w-1/2"}
                                    hasValidationError={hasFieldError("reportName")}
                                    autoFocus={true}
                                />
                            </div>
                            <div className="flex">
                                <Input
                                    type="number"
                                    name="numberOfYears"
                                      label={"Number of Years"}

                                      onInput={(e) => {
                                          e.target.value = Math.round(e.target.value);
                                      }
                                      }
                                    conditionalLabel={`(Maximum of ${settings.irrMaxLedgerLength} years per page)`}
                                    showConditionalLabel={numberOfYears > settings.irrMaxLedgerLength}
                                    onBlur={(e) => updateNumberOfYears(e.target)}
                                    containerClassNames={"py-3 pr-2 w-1/4"}
                                    hasValidationError={hasFieldError("numberOfYears", false)}
                                />
                                <Input
                                    type="number"
                                    name="startingBalance"
                                    label={"Starting Balance"}
                                    symbol="$"

                                      onInput={(e) => {
                                          e.target.value = Math.round(e.target.value);
                                      }
                                      }
                                    onChange={(e) => updateFormDataItem(e.target)}
                                    containerClassNames={"py-3 pl-2 w-1/4"}
                                    hasValidationError={hasFieldError("startingBalance", false)}
                                />
                            </div>
                            <table className="w-full mt-2" ref={tableRef}>
                                <thead className="bg-slate-200">
                                    <tr className="text-left">
                                        <th className="text-center">Year</th>
                                        <th className="text-center">{props.viewModel?.client?.firstName}'s Age</th>
                                        {props.viewModel?.spouse && (<th className="text-center">{props.viewModel?.spouse?.firstName}'s Age</th>)}
                                        <th className="text-center">
                                            <div className="flex align-middle justify-center">
                                                <span tabIndex={0} className="text-core-orange-400 font-bold hover:underline cursor-pointer" onClick={editAnnualIncomes} onKeyUp={(e) => e.key === "Enter" && editAnnualIncomes()}>Edit</span>
                                                <span className="ml-4">Annual Income</span>
                                            </div>
                                        </th>
                                        <th className="text-center">   
                                            <div className="flex align-middle justify-center">                                   
                                                <span tabIndex={0} className="text-core-orange-400 font-bold hover:underline cursor-pointer" onClick={editRemainingBalances} onKeyUp={(e) => e.key === "Enter" && editRemainingBalances()}>Edit</span>
                                                <span className="ml-4">Remaining Balance</span>
                                            </div>  
                                        </th>
                                        <th className="text-center">Total Income</th>
                                        <th className="text-center">Total Value</th>
                                        <th className="text-center">IRR by Year</th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white">
                                    {customLedgerYears?.length > 0 && customLedgerYears.length === customLedger?.length && (
                                        customLedgerYears.map((year, index) => (
                                            <tr key={year + 1} className={shared.getLedgerRowBackgroundClass(customLedger[index], props.viewModel) + " text-center"}>
                                                <td className="text-center">{year + 1}</td>
                                                <td className="text-center">{customLedger[index].clientAge}</td>
                                                {props.viewModel.spouse && (<td className="text-center">{customLedger[index].spouseAge}</td>)}
                                                <td>
                                                    <Input
                                                        type="number"
                                                        index={index}
                                                        name={`annualIncomes[${index}].annualIncome`}
                                                        symbol="$"

                                                        onInput={(e) => {
                                                            e.target.value = Math.round(e.target.value);
                                                        }
                                                        }
                                                        containerClassNames="w-5/6"
                                                        onChange={(e) => updateFormDataItem(e.target)}
                                                        hasValidationError={hasFieldError(`annualIncomes[${index}].annualIncome`, false)}
                                                    />
                                                </td>
                                                <td>
                                                    <Input
                                                        type="number"
                                                        index={index}
                                                        name={`remainingBalances[${index}].remainingBalance`}
                                                        symbol="$"
                                                        onInput={(e) => {
                                                            e.target.value = Math.round(e.target.value);
                                                        }
                                                        }
                                                        containerClassNames="w-5/6"
                                                        onChange={(e) => updateFormDataItem(e.target)}
                                                        hasValidationError={hasFieldError(`remainingBalances[${index}].remainingBalance`, false)}
                                                    />
                                                </td>
                                                <td className="text-center">{utils.formatAsCurrency(customLedger[index].totalIncome, 0)}</td>
                                                <td className="text-center">{utils.formatAsCurrency(customLedger[index].totalValue, 0)}</td>
                                                <td className="text-center">{customLedger[index].internalRateOfReturn != null ? utils.formatDecimalAsPercentage(customLedger[index].internalRateOfReturn, 1) : "N/A"}</td>
                                            </tr>
                                    )))}
                                </tbody>
                            </table>
                            <div className="pt-3">
                                <TextArea
                                    name="notes"
                                    label="Notes"
                                    rows={6}
                                    optional={true}
                                    maxCharacterCount={500}
                                    onChange={(e) => updateFormDataItem(e.target, false)}
                                />
                            </div>
                            <div className="flex justify-end mt-3 mx-1 mb-4">
                                <Button clickHandler={customFormMethods.handleSubmit((data) => onSubmit(data))} text="Save and Preview" classNames="bg-core-orange-400 mx-1"/>
                            </div>
                        </form>
                    </FormProvider>

                    <Modal
                     isOpen={isHelpModalOpen}
                     onClose={() => setIsHelpModalOpen(false)}
                     children={<CopyPasteHelp/>}
                    />
                    <CustomEditSlideOver isIncomeForm={true} open={editIncomes} onClose={() => setEditIncomes(false)} applyIncomes={applyIncomes} numberOfYears={Number(customFormMethods.getValues("numberOfYears"))} />
                    <CustomEditSlideOver isIncomeForm={false} open={editBalances} onClose={() => setEditBalances(false)} applyBalances={applyBalances} numberOfYears={Number(customFormMethods.getValues("numberOfYears"))} />
                </div>
            </div>
        </div>
        <ToastContainer />
    </div>
)
}
