import React from 'react'
import PropTypes from 'prop-types'

Card.propTypes = {
    headerText: PropTypes.string,
    text: PropTypes.string,
    containerClasses: PropTypes.string,
    children: PropTypes.element,
}
  
Card.defaultProps = {
    headerText: "",
    text: "",
    containerClasses: "",
    children: null
};
  

export default function Card(props) {
  return (
    <div className={`${props.containerClasses} flex flex-col items-center px-6 py-8 text-center rounded-md sm:px-14`}>
          <div className="text-4xl font-semibold">{props.headerText}</div>
          <div className="text-sm text-[#008193]">{props.text}</div>
    </div>
  )
}
