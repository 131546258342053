import React, { useEffect } from "react";
import { useQuery } from "react-query"
import { useNavigate } from "react-router-dom"
import SearchBar from "../../inputs/SearchBar"
import dataCalls from "../../shared/dataCalls";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function Index() {

  const navigate = useNavigate();

  const handleAdvisorSelect = async (selectedId) => {
    await dataCalls.updateAdvisorIdClaim(selectedId);
    await dataCalls.createRecentlyViewedAdvisor(selectedId);
  }

    const handleRecentAdvisorClick = async ({target}) => {
    let element = target.tagName === "LI"
      ? target
      : target.parentNode;
    const id = element.dataset.id;
    await dataCalls.updateAdvisorIdClaim(id);
    dataCalls.createRecentlyViewedAdvisor(id);
      navigate(`/v1/consumers`);
  }

  const { 
    data: recentlyViewedData, 
    isLoading: isFetchingRecentlyViewed,
    remove: resetRecentlyViewed
  } = useQuery("recentlyviewedadvisors", dataCalls.fetchRecentlyViewedAdvisors);

  useEffect(() => {
    return () => {
      resetRecentlyViewed();
    }
  }, [])

    const listItemClasses = "relative cursor-pointer select-none py-2 pl-3 pr-9 text-gray-900 hover:bg-selection-color-200 grid focus:bg-selection-color-200 focus:outline-none";
  const recentlyViewedResults = isFetchingRecentlyViewed 
  ? (<Skeleton className="h-8" count={4} />)
  : (<ul 
      className="mt-1 max-h-72 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 sm:text-sm"
    >
      {recentlyViewedData?.length > 0 ? (
          recentlyViewedData.map((advisor) => (
            <li 
              key={advisor.id} 
              data-id={advisor.id} 
              tabIndex={0} 
              onKeyUp={(e) => e.key === "Enter" && handleRecentAdvisorClick(e)} 
              onClick={(e) => handleRecentAdvisorClick(e)} 
              className={listItemClasses}
            >
              <span className="font-medium">{advisor.name}</span>
            </li>
          ))
      ) : (
        <li className={listItemClasses}>No Results</li>
      )}
    </ul>);


  return (
    <div className="justify-center grid grid-cols-3 place-content-center w-screen -mt-48">
          <div className="col-start-2">
            <img className="mt-40 mb-5" src="/images/CoreActuaryLabLogo.svg" alt="core actuary lab logo" />
        <h2 className="pb-6 core-h2 text-center">
          Search for an existing agent
        </h2>
        <div className="w-3/4 m-auto">
                  <SearchBar searchDataCall={dataCalls.advisorSearch} redirectRoute="/v1/consumers" asyncSelectionFunction={handleAdvisorSelect} />
          <div className="my-5">
            <b>Recently Viewed</b>
            {recentlyViewedResults}
          </div>
        </div>
      </div>
    </div>
  );
}
  
export default Index;