import PropTypes from 'prop-types'

CurrentAge.propTypes = {
    age: PropTypes.string.isRequired,
}

export default function CurrentAge(props) {
    return (
      <div className="ml-4 h-8 text-sm font-medium inline-flex items-center">
        <span>Current Age</span>
        <span className="circle ml-2 h-8 w-8 bg-slate-200">{props.age}</span>
      </div>
    );
}