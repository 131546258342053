import PropTypes from 'prop-types'
import { useFormContext } from "react-hook-form";
import utils from '../shared/utils';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import TooltipCustom from '../components/TooltipCustom';

Input.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  conditionalLabel: PropTypes.string,
  showConditionalLabel: PropTypes.bool,
  placeHolder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onInput: PropTypes.func,
  value: PropTypes.any,
  containerClassNames: PropTypes.string,
  inputClassNames: PropTypes.string,
  slot1: PropTypes.element,
  validationObject: PropTypes.object,
  symbol: PropTypes.string,
  subText: PropTypes.string,
  optional: PropTypes.bool,
  hasValidationError: PropTypes.bool,
  index: PropTypes.number,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  step: PropTypes.string,
  pattern: PropTypes.string
}

Input.defaultProps = {
  id: "",
  label: "",
  conditionalLabel: "",
  showConditionalLabel: false,
  placeHolder: "",
  onChange: () => true,
  onBlur: () => true,
  value: undefined,
  containerClassNames: "",
  inputClassNames: "",
  slot1: <div></div>,
  validationObject: {},
  symbol: undefined,
  subText: "",
  optional: false,
  hasValidationError: false,
  index: -1,
  autoFocus: false,
  disabled: false,
  step: "",
  pattern:""
};


export default function Input(props) {
    const { register, formState: { errors } } = useFormContext();

    const validationMessages = {
        required: `${props.label} is required.`,
        minLength: `${props.label} has a minimum length of ${props.validationObject['minLength']}`,
        maxLength: `${props.label} has a maximum length of ${props.validationObject['maxLength']}`,
        min: `${props.label} must be at least ${props.validationObject['min']}`,
        max: `${props.label} must be at most ${props.validationObject['max']}`,
        maxDate: errors[`${props.name}`]?.message,
        minDate: errors[`${props.name}`]?.message,
        validate: errors[`${props.name}`]?.message,
    }

    //react-hook-from overrides the default onChange handler, so add additional functionality in the register
    const fieldRegister = register(props.name, props.validationObject);
    const defaultOnBlur = fieldRegister.onBlur;
    fieldRegister.onBlur = (e) => {
        const res = defaultOnBlur(e);
        //Additional functionality
        props.onBlur(e);
        return res;
    }

    const defaultOnChange = fieldRegister.onChange;
    fieldRegister.onChange = (e) => {
        const res = defaultOnChange(e);
        //Additional functionality
        props.onChange(e);
        return res;
    }

  return (
    <div className={`${props.containerClassNames}`}>
        <div className="flex justify-between">
            <div className='flex'>
            <label htmlFor={props.name} className="font-bold text-sm text-gray-700 justify-start">
                {props.label}
                {props.showConditionalLabel && (
                    <span className="font-normal ml-1">{props.conditionalLabel}</span>
                )}
            </label>
            {props.tooltipMessage && (<TooltipCustom 
                                        message={props.tooltipMessage}
                                        width={props.tooltipWidth}
                                        symbol={props.tooltipSymbol}
                                        />)}
            </div>
            {props.optional && (<span className="text-sm">Optional</span>)}
        </div>
        <div className="mb-1 relative rounded-md shadow-sm">
            <div className="flex items-center">
                <div className="absolute left-0 pl-3 flex pointer-events-none">
                    <span className="text-gray-500 sm:text-sm" style={{paddingTop: "5px"}}>{props.symbol}</span>
                </div>
                  <input
                    type={props.type}
                    name={props.name}
                    id={props.id}
                    step={props.step}
                    pattern={props.pattern}
                    className={`${props.inputClassNames} ${props.symbol ? "pl-6" : "pl-2"} ${props.hasValidationError ? "border-red-500 border-[3px]" : "border-slate-500 border"} disabled:opacity-50 disabled:bg-[lightgray] disabled:cursor-not-allowed h-10 mt-1 w-full rounded-md shadow-sm focus:border-core-orange-400 focus:outline-none focus:ring-1 focus:ring-core-orange-400`}
                    placeholder={props.placeHolder}
                    value={props.value}
                    disabled={props.disabled}
                    data-index={props.index}
                    autoFocus={props.autoFocus}
                    onFocus={(e) => e.target.value == 0 && e.target.select()}
                    onWheel={e => { e.target.blur(); }}
                    onInput={props.onInput}
                    {...fieldRegister}
                />
                {props.hasValidationError && (
                    <div className="absolute right-0 pr-3 flex pointer-events-none">
                        <ExclamationCircleIcon className="w-6 h-6 text-red-500 pt-1"></ExclamationCircleIcon>
                    </div>
                )}
            </div>
            {!utils.isEmpty(errors) && (
                <ul className="list-disc ml-6 mt-2">
                {validationMessages[errors[props.name]?.type] && <li className="text-core-orange-500">{validationMessages[errors[props.name]?.type]}</li>}
                </ul>
            )}
        </div>
        {props.subText && (
            <div className="text-sm text-gray-500 pt-2">
                {props.subText}
            </div>
        )}
    </div>
  )
}
