import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { MenuIcon, XIcon, ChevronDownIcon, UserCircleIcon } from '@heroicons/react/outline'
import { useUser } from './contexts/UserContext'
import logo from "./content/images/CoreActuaryLabLogoWhite.svg"
import utils from './shared/utils'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function NavBar() {

  const { userModel } = useUser();
  const getHomeRef = (roleType) => {
    if(roleType === 2) {
        return "/v1/advisors";
    }
    if(roleType === 3) {
        return "/v1/consumers";
    }
  }

  const getAdminLinks = () => {

    let linkObjects = [];
    if(userModel.permissions.includes(1)) {
      linkObjects.push({
          link: "/v1/analytics",
        text: "Analytics"
      });
    }
    if(userModel.permissions.includes(2)) {
      linkObjects.push({
          link: "/v1/activities",
        text: "User Activity"
      })
    }
    return linkObjects;
  }


  return (
    <Disclosure as="nav" className="core-header-container w-screen h-navbar fixed-top">
      {({ open }) => (
        <>
          <div className="max-w-12xl mx-auto px-4 sm:px-6 lg:px-5">
            <div className="flex items-center justify-between h-navbar">
              <div className="flex items-center">
                              <div className="flex-shrink-0">
                                  <a href="/v1/advisors">
                  <img
                    className="block lg:hidden h-8 w-auto"
                    src={logo}
                    alt="Core"
                  />
                    <img
                        className="hidden lg:block h-8 w-auto"
                        src={logo}
                    alt="Core"
                                      />
                </a>
                </div>
                <div className="hidden sm:block sm:ml-6">
                  <div className="flex space-x-4 cursor-pointer">
                      <a
                      href={getHomeRef(userModel?.roleType)}
                      className="nav-item hover:text-white px-3 py-2 rounded-md text-sm font-medium hover:underline"
                      >
                      Home
                      </a>  
                      {userModel && getAdminLinks().map(item => (
                        <a
                        key={utils.generateUniqueKey()}
                        href={item.link}
                        className="nav-item hover:text-white px-3 py-2 rounded-md text-sm font-medium hover:underline"
                        >
                        {item.text}
                        </a> 
                      ))}  
                  </div>
                </div>
              </div>
              <div className="flex justify-end">
                {/* {userModel?.roleType == 2 && userModel?.vieweeName && (
                  <div className="flex text-sm text-gray-300 pr-1">
                    <span className="block px-2 py-2 rounded-md text-sm font-medium">Viewing: {userModel?.vieweeName}</span>
                  </div>
                )} */}
              </div>
              <div className="hidden sm:ml-6 sm:block">
                <div className="flex items-center">
                  {/* Profile dropdown */}
                  <Menu as="div" className="ml-3 relative">
                    <div>
                      <Menu.Button className="flex text-sm rounded-full">
                        <span className="sr-only">Open user menu</span>
                        <div className="flex align-middle rounded-md text-sm nav-item hover:text-white pr-1">
                          <UserCircleIcon className="h-5 w-5 mt-[10px]" />
                          <span className="block pl-1 pr-2 py-2 rounded-md text-base">{userModel?.name}</span>
                          {/* <span className="caret mt-3">&#9660;</span> */}
                          <ChevronDownIcon className="float-right align-middle h-4 w-4 mt-3" />
                        </div>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="https://coreincome.com/dashboard"
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700'
                              )}
                            >
                              Back To Core Dashboard
                            </a>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
              <div className="-mr-2 flex sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 cursor-pointer">
                <Disclosure.Button
                  as="a"
                  href={getHomeRef(userModel?.roleType)}
                  className="nav-item hover:text-white block px-3 py-2 rounded-md text-base font-medium hover:underline"
                >
                Home
                </Disclosure.Button>
            </div>
            <div className="pt-4 pb-3 border-t border-gray-700">
              <div className="flex items-center px-5">
                <div className="">
                    <div className="text-base font-medium text-white">{userModel?.name}</div>
                    <div className="text-sm font-medium text-gray-400">{userModel?.email}</div>
                </div>
                <button
                  type="button"
                  className="ml-auto flex-shrink-0 bg-gray-800 p-1 rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                >
                </button>
              </div>
              <div className="mt-3 px-2 space-y-1">
                <Disclosure.Button
                  as="a"
                  href="https://coreincome.com/dashboard"
                  className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                >
                  Back To Core Dashboard
                </Disclosure.Button>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}