import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import Input from '../../../../inputs/Input';
import { XIcon } from'@heroicons/react/outline';
import Switch from '../../../../inputs/Switch';
import { useFormContext } from "react-hook-form";
import utils from "../../../../shared/utils";

RemainingBalance.propTypes = {
    onUpdate: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    hasAgeOverlapError: PropTypes.bool.isRequired,
    fieldErrors: PropTypes.array.isRequired
}
  
RemainingBalance.defaultProps = {
};

export default function RemainingBalance(props) {

    const [hideOptionalInputs, setHideOptionalInputs] = useState(false);

    const hasFieldError = (name) => {
        return props.fieldErrors.filter(error => error.name === name).length > 0;
    }

    const { watch, getValues, setValue } = useFormContext();
    const watchCheckbox = watch(`remainingBalances[${props.index}].reduceByAnnualIncome`);
    useEffect(() => {
        const value = getValues(`remainingBalances[${props.index}].reduceByAnnualIncome`);
        setHideOptionalInputs(value);
    }, [watchCheckbox]);

    //Set values of remaining balance and growth rate if not initially set on "addBalance"
    useEffect(() => {
        const remainingBalance = getValues(`remainingBalances[${props.index}].remainingBalance`);
        const growthRate = getValues(`remainingBalances[${props.index}].growthRate`);

        if(remainingBalance === "") setValue(`remainingBalances[${props.index}].remainingBalance`, 0);
        if(growthRate === "") setValue(`remainingBalances[${props.index}].growthRate`, 0);
    }, [hideOptionalInputs])

    const updateReduceByAnnualIncome = ({name, checked}) => {
        setHideOptionalInputs(checked);
        props.onUpdate({name: name, value: checked});
    }

  return (
    <div className="bg-slate-200 mt-6 p-4">    
        <div className="grid">
            <div className="flex justify-between">
                <span className="font-bold">Remaining Balance</span>
                {props.index > 0 && (
                    <XIcon onClick={() => props.onRemove(props.index)} className="h-6 cursor-pointer" />
                )}
            </div>
            <span className="text-sm">Enter the remaining balance for each year, and if the balance is growing</span>
        </div>
        <div className="grid grid-cols-2">
            <div className="col-span-2 flex mt-4">
                <Switch 
                    name={`remainingBalances[${props.index}].reduceByAnnualIncome`} 
                    label="Reduce by Annual Income" 
                    onChange={(e) => updateReduceByAnnualIncome(e.target)}
                />
            </div>
            <div className="col-span-1 flex">
                <Input
                    type="number"
                    name={`remainingBalances[${props.index}].startYear`}
                      label={"Starting Year"}
                      onInput={(e) => {
                          e.target.value = Math.round(e.target.value);
                      }
                      }
                    containerClassNames="pr-2 pt-0 pb-3"
                    onChange={(e) => props.onUpdate(e.target)}
                    hasValidationError={hasFieldError(`remainingBalances[${props.index}].startYear`) || hasFieldError(`annualIncomes[${props.index}].years`) || props.hasAgeOverlapError}
                />
                <Input
                    type="number"
                    name={`remainingBalances[${props.index}].endYear`}
                      label={"Ending Year"}
                      onInput={(e) => {
                          e.target.value = Math.round(e.target.value);
                      }
                      }
                    containerClassNames="pl-2 pt-0 pb-3"
                    onChange={(e) => props.onUpdate(e.target)}
                    hasValidationError={hasFieldError(`remainingBalances[${props.index}].endYear`) || hasFieldError(`annualIncomes[${props.index}].years`) || props.hasAgeOverlapError}
                />
            </div>
            <div className="col-span-1"></div>
            <div className={`col-span-1 ${hideOptionalInputs ? "hidden" : "block"}`}>
                <Input
                    type="number"
                    name={`remainingBalances[${props.index}].remainingBalance`}
                    label={"Remaining Balance"}
                      onInput={(e) => {
                          e.target.value = Math.round(e.target.value);
                      }
                      }
                    symbol="$"
                    containerClassNames="pr-2 py-3"
                    onChange={(e) => props.onUpdate(e.target)}
                    hasValidationError={hasFieldError(`remainingBalances[${props.index}].remainingBalance`)}
                />
            </div>
            <div className="col-span-1">
                <Input
                    type="number"
                    name={`remainingBalances[${props.index}].growthRate`}
                    label={"Growth Rate"}
                      onInput={(e) => {
                          utils.setDecimalPlaces(e);
                      }}
                    symbol="%"
                    subText="Apply a percentage of annual growth in your remaining balance"
                    optional={true}
                    containerClassNames={`${hideOptionalInputs ? "" : "pl-2"} py-3`}
                    onChange={(e) => props.onUpdate(e.target)}
                    hasValidationError={hasFieldError(`remainingBalances[${props.index}].growthRate`)}
                />
            </div>
        </div>
    </div>
  )
}
