import { ExclamationCircleIcon } from '@heroicons/react/outline';

TooltipCustom.defaultProps = {
    direction: "right",
    message: null,
    symbol: <ExclamationCircleIcon />,
    width: "10vw"
  };
  
export default function TooltipCustom (props) {
    let top = '0vh';
    let left = '0vh';
    if (props.direction === 'right') { left = '120%'; }
    if (props.direction === 'down')  { top  = '116%'; }
    
    return (
        <div
            className="tooltip ml-1 h-5 w-5 hover:text-blue-500 relative self-center inline-block"
            style= {{ color: '#f97000' }}
        >
                {props.symbol}
                <div 
                    className="tooltip-text absolute z-10 px-5 py-2 text-sm font-medium bg-slate-100 text-black rounded-lg shadow-sm border-slate-600 border-solid border"
                    style={{width: props.width, top: top, left: left}}
                    dangerouslySetInnerHTML={{__html:  props.message}}
                />
            </div>
    )
};