import { useEffect, useState, useCallback } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom"
import { useForm, FormProvider } from "react-hook-form";
import InlineInput from "../../inputs/InputInline";
import InlineRadio from "../../inputs/RadioInline";
import Button from "../../inputs/Button";
import { ToastContainer, toast } from 'react-toastify';
import dataCalls from "../../shared/dataCalls";
import utils from "../../shared/utils";
import BackButton from "../../components/BackButton";
import CurrentAge from "../../components/CurrentAge";
import CircleWithWedge from "../../components/CircleWithWedge";
import remoteCalculations from "../../shared/remoteCalculations";


const sexOptions = [{id: 1, title: "Male", value: "true" }, {id: 2, title: "Female", value: "false" } ]

function ConsumerEdit() {

  const [person, setPerson] = useState({ firstName: "", lastName: "", dateOfBirth: null, isMale: "true" });
  const [age, setAge] = useState("");
  const [saveButtonEnabled, setSaveButtonEnabled] = useState(true);
  const [lifeExpectancy, setLifeExpectancy] = useState({ percentile50: "", percentile25: "", percentile10: "" });
  const methods = useForm();

  const minDate = new Date(1900, 0, 1);
  const maxDate = new Date();
  
  const navigate = useNavigate();
  const { data: advisor } = useQuery("currentAdvisor", dataCalls.fetchCurrentAdvisor, {
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false
  });

  const queryParams = new URLSearchParams(window.location.search)

  let clientId = queryParams.get("clientid");
  let spouseId = queryParams.get("spouseid");
  
  const settings = {
    clientId: clientId,
    spouseId: spouseId,
    isEditClient: window.location.pathname.includes("edit") && !(spouseId > 0),
    isEditSpouse: window.location.pathname.includes("edit") && spouseId > 0,
    isCreateClient: window.location.pathname.includes("create") && !(clientId > 0),
    isCreateSpouse: window.location.pathname.includes("create") && clientId > 0,
  }

  settings.isEdit = settings.isEditClient || settings.isEditSpouse;
  settings.editingId = settings.isEditClient ? settings.clientId : settings.spouseId;

  const fetchPerson = useCallback(async () => {
    if(settings.isEdit) {
      const data = await dataCalls.fetchConsumer(settings.editingId);
      
      const consumer = data.client;
      consumer.dateOfBirth = new Date(consumer.dateOfBirth).toLocaleDateString('en-CA');

      const formPerson = {
        id: consumer.id,
        firstName: consumer.firstName,
        lastName: consumer.lastName,
        dateOfBirth: consumer.dateOfBirth,
        isMale: String(consumer.isMale)
      };
      methods.reset(formPerson);
      setPerson(formPerson);
    } else {
      methods.reset({
        id: 0,
        firstName: "",
        lastName: "",
        dateOfBirth: null,
        isMale: "true"
      });
    }
  }, [])

  //Set intial form state
  useEffect(() => {
    fetchPerson();
  }, [fetchPerson])
  
  const handleUpdate = (propertyName, { target: {value}}) => {
    const updatedValue = {};
    updatedValue[propertyName] = value;
    setPerson(person => ({
        ...person,
        ...updatedValue
      }));
  }

  const fetchLifeExpectancies = useCallback(async () => {
    if(person.dateOfBirth) {
      const clientAge = utils.calculateAge(person.dateOfBirth);
      setAge(clientAge);
      
      if(person.isMale && clientAge >= 0 && clientAge <= 120) {
        let lifeExpectancies = await remoteCalculations.getLifeExpectancies(clientAge, person.isMale, [50, 25, 10]);
        if(!lifeExpectancies) {
          lifeExpectancies = [0,0,0];
        }
        setLifeExpectancy({ percentile10: String(lifeExpectancies[2]), percentile25: String(lifeExpectancies[1]), percentile50: String(lifeExpectancies[0])})
      }
    }
  }, [person.dateOfBirth, person.isMale])

  useEffect(() => {
    fetchLifeExpectancies();
  }, [fetchLifeExpectancies])

  const onSubmit = async (data) => {
    setSaveButtonEnabled(false);

    const isEdit = settings.isEdit;
    const toastId = toast.loading("Saving consumer...", {
      position: toast.POSITION.BOTTOM_CENTER
    });

    //Create/Update 
    data.isMale = JSON.parse(data.isMale);
    const saveResponse = isEdit 
      ? await dataCalls.updateConsumer(data)
      : await dataCalls.createConsumer(data);

    if(saveResponse.success) {
      toast.update(toastId, { render: "Save successful", type: "success", isLoading: false });
      //Create recently viewed record
      const createdId = isEdit ? settings.editingId : saveResponse.data ?? 0;
      dataCalls.createRecentlyViewedConsumer(createdId);
      
      //If this is a spouse, link to consumer and then navigate back to consumer's page
      if(saveResponse.success && settings.isCreateSpouse) {
        const linkResponse = await dataCalls.updateRelationship(settings.clientId, createdId, false);
          if (linkResponse.success) setTimeout(() => navigate(`/v1/consumers/${settings.clientId}`), 2000);
        return true;
      }

      //Navigate back to consumers
      var redirectId = !isEdit ? createdId : settings.clientId;
        setTimeout(() => navigate(`/v1/consumers/${redirectId}`), 2000);
    } else {
      toast.update(toastId, { render: "An error occurred...", type: "error", isLoading: false });
      setSaveButtonEnabled(true);
    }
  }

    const backButtonUrl = settings.isEdit ? `/v1/consumers/${settings.clientId}` : '/v1/consumers';
  const backButtonText = settings.isEdit ? "Back to Client Profile" : "Back to Client Search";

  return (
    <div className="w-3/4 m-auto">
        <div className="">
            <BackButton redirectUrl={backButtonUrl} text={backButtonText} />
            {settings.isCreateClient && (
              <h3>Add Client for {advisor?.name}</h3>
            )}
            {settings.isCreateSpouse && (
              <h3>Add Spouse</h3>
            )}
            {settings.isEditClient && (
              <h3>Edit {person?.firstName} {person?.lastName}</h3>
            )}
            {settings.isEditSpouse && (
              <h3>Edit {person?.firstName} {person?.lastName}</h3>
            )}        
            <div className="text-core-orange-400 font-bold">All fields are required</div>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
                <div className="grid grid-cols-1 divide-y divide-gray-300">
                    <div></div>
                    <input type="hidden" value="id" {...methods.register("id")}></input>              
                    <InlineInput 
                        type="text" 
                        name="firstName" 
                        label="First Name" 
                        onChange={(e) => handleUpdate("firstName", e)}
                        validationObject={{ required: true, maxLength: 50 }}
                    />
                    <InlineInput 
                        type="text" 
                        name="lastName" 
                        label="Last Name" 
                        onChange={(e) => handleUpdate("lastName", e)}
                        validationObject={{ required: true, maxLength: 100 }}
                    />
                    <InlineRadio 
                        options={sexOptions} 
                        name="isMale" 
                        label="Sex" 
                        onChange={(e) => handleUpdate("isMale", e)}
                        isRequired={true}
                    />
                    <InlineInput 
                        type="date" 
                        name="dateOfBirth" 
                        label="Birthdate" 
                        onChange={(e) => handleUpdate("dateOfBirth", e)}
                        validationObject={{ 
                            required: true,
                            validate: {
                                minDate: (value) => new Date(value) > minDate ? true : `Minimum Birthdate is ${utils.getFormattedDate(minDate)}.`,
                                maxDate: (value) => new Date(value) <= maxDate ? true : 'Birthdate cannot be in the future.'
                            }
                        }}
                        slot1={<CurrentAge age={`${age}`} />} 
                    />
                    <div className="flex items-center py-3">
                      <div className="grid w-1/4">
                          <span className="text-sm font-bold">Life Expectancy</span>
                          <span className="text-sm w-3/4 font-light">Estimate age longevity based on the client's age and sex</span>
                      </div>
                      <div className="w-1/2 flex children-mx-2">
                          <CircleWithWedge wedgeStartingAngle={50} text={`${lifeExpectancy.percentile50}`} labelText="50%" useAlternateColors={settings.isEditSpouse || settings.isCreateSpouse} />
                          <CircleWithWedge wedgeStartingAngle={75} text={`${lifeExpectancy.percentile25}`} labelText="25%" useAlternateColors={settings.isEditSpouse || settings.isCreateSpouse} />
                          <CircleWithWedge wedgeStartingAngle={90} text={`${lifeExpectancy.percentile10}`} labelText="10%" useAlternateColors={settings.isEditSpouse || settings.isCreateSpouse} />
                      </div>
                    </div>
                    <div></div>
                </div>
                <div className="flex justify-end mt-3">
                    <Button type="submit" value="Submit" text="Save" disabled={!saveButtonEnabled} classNames="bg-core-orange-400"/>
                </div>
                </form>
            </FormProvider>
        </div>
        <ToastContainer />
    </div>
  );
}

export default ConsumerEdit;