import dataCalls from "../../../shared/dataCalls";

class edit {
    static buildValidationErrors(form, isGuidedForm = true, includeBasicInputs = false) {
        let errorObjects = [];

        if(includeBasicInputs) {
            if(form.reportName?.length === 0) {
                errorObjects.push({
                    name: "reportName",
                    message: "Report must be named."
                });
            }
    
            if(form.reportName?.length > 150) {
                errorObjects.push({
                    name: "reportName",
                    message: "Report Name character count must be less than or equal to 150."
                });
            }

            if(form.notes?.length > 500) {
                errorObjects.push({
                    name: "notes",
                    message: "Notes character count must be less than or equal to 500."
                });
            }
        }

        if(form.numberOfYears > 100) {
            errorObjects.push({
                name: "numberOfYears",
                message: "Number of years must be less than or equal to 100."
            });
        }
        if(form.numberOfYears < 1) {
            errorObjects.push({
                name: "numberOfYears",
                message: "Number of years must be greater than or equal to 1."
            });
        }
        if(form.startingBalance > 1000000000) {
            errorObjects.push({
                name: "startingBalance",
                message: "Staring Balance must be less than or equal to $1,000,000,000."
            });
        }
        if(form.startingBalance < -1000000000) {
            errorObjects.push({
                name: "startingBalance",
                message: "Staring Balance must be greater than or equal to -$1,000,000,000."
            });
        }

        
        let incomeOverlapErrorIndeces = [];
        if(isGuidedForm) {
            incomeOverlapErrorIndeces = this.checkForOverlap(form.annualIncomes);
            if(incomeOverlapErrorIndeces.length > 0) {
                errorObjects.push({
                    name: `annualIncomes`,
                    message: "Annual Income years cannot overlap."
                });
            }
        }
        

        let balanceOverlapErrorIndeces = [];
        if(isGuidedForm) {
            balanceOverlapErrorIndeces = this.checkForOverlap(form.remainingBalances);
            if(balanceOverlapErrorIndeces.length > 0) {
                errorObjects.push({
                    name: `remainingBalances`,
                    message: "Remaining Balance years cannot overlap."
                });
            }
        }

        form.annualIncomes.forEach((income, i) => {
            income.startYear = Number(income.startYear);
            income.endYear = Number(income.endYear);

            if(isGuidedForm) {
                if(income.startYear > income.endYear) {
                    errorObjects.push({
                        name: `annualIncomes[${i}].years`,
                        message: `Annual Income (Years ${income.startYear} - ${income.endYear}): Start Year must be less than or equal to End Year.`
                    });
                }
                if(income.startYear <= 0) {
                    errorObjects.push({
                        name: `annualIncomes[${i}].startYear`,
                        message: `Annual Income (Years ${income.startYear} - ${income.endYear}): Start Year must be positive.`
                    });
                }
                if(income.endYear <= 0) {
                    errorObjects.push({
                        name: `annualIncomes[${i}].endYear`,
                        message: `Annual Income (Years ${income.startYear} - ${income.endYear}): End Year must be positive.`
                    });
                }
                if(income.costOfLivingAdjustment < -100) {
                    errorObjects.push({
                        name: `annualIncomes[${i}].costOfLivingAdjustment`,
                        message: `Annual Income (Years ${income.startYear} - ${income.endYear}): Cost of Living Adjustment must be greater than -100%.`
                    });
                }
                if(income.costOfLivingAdjustment > 100) {
                    errorObjects.push({
                        name: `annualIncomes[${i}].costOfLivingAdjustment`,
                        message: `Annual Income (Years ${income.startYear} - ${income.endYear}): Cost of Living Adjustment must be less than 100%.`
                    });
                }
                if(income.startYear > form.numberOfYears) {
                    errorObjects.push({
                        name: `annualIncomes[${i}].startYear`,
                        message: `Annual Income (Years ${income.startYear} - ${income.endYear}): Start Year cannot be outside of the report year range.`
                    });
                }
                if(income.endYear > form.numberOfYears) {
                    errorObjects.push({
                        name: `annualIncomes[${i}].endYear`,
                        message: `Annual Income (Years ${income.startYear} - ${income.endYear}): End Year cannot be outside of the report year range.`
                    });
                }
            }
        })

        form.remainingBalances.forEach((balance, i) => {
            balance.startYear = Number(balance.startYear);
            balance.endYear = Number(balance.endYear);
            
            if(isGuidedForm) {
                if(balance.startYear > balance.endYear) {
                    errorObjects.push({
                        name: `remainingBalances[${i}].years`,
                        message: `Remaining Balance (Years ${balance.startYear} - ${balance.endYear}): Start Year must be less than or equal to End Year.`
                    });
                }
                if(balance.startYear <= 0) {
                    errorObjects.push({
                        name: `remainingBalances[${i}].startYear`,
                        message: `Remaining Balance (Years ${balance.startYear} - ${balance.endYear}): Start Year must be positive.`
                    });
                }
                if(balance.endYear <= 0) {
                    errorObjects.push({
                        name: `remainingBalances[${i}].endYear`,
                        message: `Remaining Balance (Years ${balance.startYear} - ${balance.endYear}): End Year must be positive.`
                    });
                }
    
                if(balance.growthRate < -100) {
                    errorObjects.push({
                        name: `remainingBalances[${i}].growthRate`,
                        message: `Remaining Balance (Years ${balance.startYear} - ${balance.endYear}): Growth Rate must be greater than -100%.`
                    });
                }
                if(balance.growthRate > 100) {
                    errorObjects.push({
                        name: `remainingBalances[${i}].growthRate`,
                        message: `Remaining Balance (Years ${balance.startYear} - ${balance.endYear}): Growth Rate must be less than 100%.`
                    });
                }
                if(balance.startYear > form.numberOfYears) {
                    errorObjects.push({
                        name: `remainingBalances[${i}].startYear`,
                        message: `Remaining Balance (Years ${balance.startYear} - ${balance.endYear}): Start Year cannot be outside of the report year range.`
                    });
                }
                if(balance.endYear > form.numberOfYears) {
                    errorObjects.push({
                        name: `remainingBalances[${i}].endYear`,
                        message: `Remaining Balance (Years ${balance.startYear} - ${balance.endYear}): End Year cannot be outside of the report year range.`
                    });
                }
            }
        })

        var errorObject = {
            errors: errorObjects
        };
        if(isGuidedForm) {
            errorObject.incomeOverlapErrorIndeces = incomeOverlapErrorIndeces;
            errorObject.balanceOverlapErrorIndeces = balanceOverlapErrorIndeces;
        }
        return errorObject;
    }

    static checkForOverlap(items) {
        function range(start, end) {
            start = Number(start);
            end = Number(end);
            if(start > end) {
                let temp = start;
                start = end;
                end = temp;
            }
            return Array(end - start + 1).fill().map((_, idx) => start + idx)
        }
          
        function intersection(array1, array2) {
            return array1.filter(value => array2.includes(value));
        }

        let errorIndeces = [];
        items.forEach((item, i) => {
            const itemYearRange = range(item.startYear, item.endYear);
            for (let j = i + 1; j < items.length; j++) {
                const otherItemYearRange = range(items[j].startYear, items[j].endYear);
                if(intersection(itemYearRange, otherItemYearRange).length > 0) {
                    errorIndeces.push(i);
                    errorIndeces.push(j);
                }
            }
        });
        return errorIndeces;
    }

    static handleEditSubmit = async (viewModel, form, toast, navigate, checkValidity) => {
        if(!checkValidity()) {
            toast.error("Correct the form errors before continuing.", {
                position: toast.POSITION.BOTTOM_CENTER
            });
            return false;
        }

        let report = viewModel.report;
        report.reportName = form.reportName;
        delete form.reportName;
        report.reportData = form;
        
        //Save and Preview
        const toastId = toast.loading("Saving report...", {
            position: toast.POSITION.BOTTOM_CENTER
        });
        const response = await dataCalls.saveDraft(report);
        if(response.success) {
            toast.update(toastId, { render: "Save successful", type: "success", isLoading: false });
            setTimeout(() => navigate(`/v1/reports/internalrateofreturn/preview/${response.data}`), 1000);
        } else {
            toast.update(toastId, { render: "An error occurred...", type: "error", isLoading: false });
        }
        setTimeout(() => toast.dismiss(toastId.current), 1000);
        return response.success;
    }
}

export default edit;