import React from 'react'
import PropTypes from 'prop-types'

StatusPill.propTypes = {
    text: PropTypes.string,
    backgroundColorClass: PropTypes.string,
    bulletColorClass: PropTypes.string,
    textColorClass: PropTypes.string
}
  
StatusPill.defaultProps = {
    text: "",
    backgroundColorClass: "bg-green-100",
    bulletColorClass: "text-green-600",
    textColorClass: "text-green-800"
};

export default function StatusPill(props) {

  return (
    <div className={`${props.backgroundColorClass} border-0 rounded-2xl flex justify-center items-center h-9 w-5/6 m-auto p-1 max-w-[150px]`}>
        <span className={`${props.bulletColorClass} text-5xl mr-1`}>&#8226;</span>
        <span className={`${props.textColorClass} font-semibold pr-1`}>{props.text}</span>
    </div>
  )
}
