import PropTypes from 'prop-types'
import { useFormContext } from "react-hook-form";
import utils from '../shared/utils';

Radio.propTypes = {
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  onChange: PropTypes.func
}

Radio.defaultProps = {
  id: "",
  label: "",
  isRequired: false,
  onChange: undefined
};

export default function Radio(props) {
    const { register, formState: { errors } } = useFormContext();

    //react-hook-from overrides the default onChange handler, so add additional functionality in the register
    const fieldRegister = register(props.name, { required: props.isRequired });
    const defaultOnChange = fieldRegister.onChange;
    fieldRegister.onChange = (e) => {
      const res = defaultOnChange(e);
      //Additional functionality
      props.onChange(e);
      return res;
    }
    
    return (
      <div className="flex py-3">
        <label className="text-sm font-bold text-slate-700 w-1/4">{props.label}</label>
        <fieldset className="w-1/2">
          <div className="space-y-4 sm:text-sm">
            {props.options.map((option) => (
              <div key={option.id} className="flex items-center">
                <input
                  name={props.name}
                  type="radio"
                  value={option.value}
                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                  {...fieldRegister}
                />
                <label htmlFor={option.id} className="ml-3 block text-sm font-medium text-gray-700">
                  {option.title}
                </label>
              </div>
            ))}
            {!utils.isEmpty(errors) && (
              <ul className="list-disc ml-6 mt-2">
                {errors[props.name]?.type === 'required' && <li className="text-core-orange-500">{`${props.label} is required.`}</li>}
              </ul>
            )}
          </div>
        </fieldset>
      </div>
    )
  }