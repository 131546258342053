import React from 'react'
import utils from '../../../../shared/utils'
import PropTypes from 'prop-types';

SequenceDiamond.propTypes = {
  growthRates: PropTypes.array.isRequired,
  sequenceType: PropTypes.string.isRequired,
  colorHex: PropTypes.string,
  containerClassName: PropTypes.string,
}

SequenceDiamond.defaultProps = {
    colorHex: "black",
    growthRates: [0,0,0,0],
    containerClassName: ""
};

export default function SequenceDiamond(props) {
  return (
    <div className={`diamond shadow-lg ${props.containerClassName}`}>
        <span className="arrow-down-right" style={{"color": props.colorHex}}>&#10230;</span>
        <span className="arrow-down-left" style={{"color": props.colorHex}}>&#10229;</span>
        <span className="arrow-up-left" style={{"color": props.colorHex}}>&#10229;</span>
        <span className="diamond-text-top">{utils.formatDecimalAsPercentage(props.growthRates[0], 0)}</span>
        <span className="diamond-text-right">{utils.formatDecimalAsPercentage(props.growthRates[1], 0)}</span>
        <span className="diamond-text-bottom">{utils.formatDecimalAsPercentage(props.growthRates[2], 0)}</span>
        <span className="diamond-text-left">{utils.formatDecimalAsPercentage(props.growthRates[3], 0)}</span>
        <span className="diamond-text" style={{"color": props.colorHex}}><b>{props.sequenceType}</b> Sequence</span>
    </div>
  )
}
