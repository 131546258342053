import { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import { useFormContext } from "react-hook-form";

TextArea.propTypes = {
    name: PropTypes.string.isRequired,
    id: PropTypes.string,
    label: PropTypes.string,
    placeHolder: PropTypes.string,
    onChange: PropTypes.func,
    rows: PropTypes.number,
    optional: PropTypes.bool,
    inputClassNames: PropTypes.string,
    validationObject: PropTypes.object,
    showCharacterCount: PropTypes.bool,
    maxCharacterCount: PropTypes.number
}
  
TextArea.defaultProps = {
    id: "",
    label: "",
    placeHolder: "",
    onChange: undefined,
    rows: 3,
    optional: false,
    inputClassNames: "",
    validationObject: {},
    showCharacterCount: true,
    maxCharacterCount: 0
};

export default function TextArea(props) {
    const [ characterCount, setCharacterCount ] = useState(0);

    const { register, formState: { errors }, watch, getValues } = useFormContext();
    
    const watchNotes = watch(props.name);
    useEffect(() => {
        const text = getValues(props.name);
        setCharacterCount(text?.length ?? 0);
    }, [watchNotes]);
  
    //react-hook-from overrides the default onChange handler, so add additional functionality in the register
    const fieldRegister = register(props.name, props.validationObject);
    const defaultOnChange = fieldRegister.onChange;
    fieldRegister.onChange = (e) => {
        const res = defaultOnChange(e);
        //Additional functionality

        setCharacterCount(e.target.value?.length ?? 0);

        props.onChange(e);
        return res;
    }

    return (
        <div className="flex justify-center w-full">
            <div className="w-full">
                <div className="flex justify-between">
                    <label htmlFor={props.name} className="font-bold text-sm text-gray-700 justify-start">
                        {props.label}
                    </label>
                    {props.optional && (<span className="text-sm">Optional</span>)}
                </div>
                <textarea
                id={props.id}
                name={props.name}
                className={`${props.inputClassNames} ${props.hasValidationError ? "border-red-500 border-[3px]" : "border-slate-500 border"} pl-2 mt-1 w-full rounded-md shadow-sm focus:border-core-orange-400 focus:outline-none focus:ring-1 focus:ring-core-orange-400`}
                rows={props.rows}
                placeholder="Your message"
                {...fieldRegister}
                ></textarea>
                {props.showCharacterCount && (
                    <div className="flex justify-end mt-[-10px]">
                        {props.maxCharacterCount 
                            ? (<span className="text-sm">Character Count: {characterCount}/{props.maxCharacterCount}</span>) 
                            : (<span className="text-sm">Character Count: {characterCount}</span>)
                        }
                    </div>
                )}
            </div>
        </div>
    )
}
